export class SeriousDeseasesModel {
    id: string;
    policyId: any;
    clientId: any;
    state: string;
    gender: string;
    occupation: string;
    height: string;
    weight: string;
    insuredValue: string;
    validSince: string;
    validUntil: string;
    classOfInsuranceId: any;
    creatorId: any;
    lastUpdateId: any;
    createdDate: string;
    lastUpdateDate: string;


    public constructor() {
        this.id = null;
        this.policyId = '';
        this.clientId = '';
        this.state = '';
        this.gender = '';
        this.occupation = '';
        this.height = '';
        this.weight = '';
        this.insuredValue = '';
        this.validSince = '';
        this.validUntil = '';
        this.classOfInsuranceId = '';
        this.creatorId = '';
        this.lastUpdateId = '';
        this.createdDate = '';
        this.lastUpdateDate = '';
       
    }
}
