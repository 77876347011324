import { Component, OnInit } from '@angular/core';
import {TokenStorageService} from "../../shared/storage-services/token-storage.service";
import {CrudServiceService} from "../../shared/backend/cruds/crud-service.service";
import {GenericResponseModel} from "../../../models/utilities/generic.response.model";
import {InfoMessagesService} from "../../shared/messages/info-messages.service";
import * as moment from "moment";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ActivitiesIndicatorReportModalComponent} from "../activities-indicator-report-modal/activities-indicator-report-modal.component";

@Component({
  selector: 'app-activities-indicator',
  templateUrl: './activities-indicator.component.html',
  styleUrls: ['./activities-indicator.component.scss']
})
export class ActivitiesIndicatorComponent implements OnInit {

  roles = {
    'ROLE_ADMIN': 'Perfil Administrador',
    'ROLE_USER': 'Perfil Gestor',
    'ROLE_SELLER': 'Perfil Vendedor'
  }
  types = {};
  users = {};

  startDate = null;
  finalDate = null;
  profile = '';
  user = '';
  type = '';

  companyId = '';
  searching = false;

  assigned = {};
  delayed = {};
  pending = {};
  onTime = {};
  endedInADayAvg = {};
  newInADayAvg = {};

  endedTicketsPerType = {};
  newTicketsPerType = {};
  avgTime = {};

  usersInReport = [];
  typesInReport = {};
  activities = [];
  showPie = {};
  showLineChart1 = {};
  showLineChart2 = {};
  showAvgTimeTable = {};

  // Data for Pie Chart
  pieChartDataDefault: number[] = [];
  pieChartData = {};
  pieChartType = 'pie';
  pieChartColors: any[] = [{ backgroundColor: ["rgba(0, 157, 160, 0.8)", "rgba(28, 188, 216, 0.8)", "rgba(255, 141, 96, 0.8)"] }];
  pieChartOptions: any = {
    animation: true,
    responsive: true,
    maintainAspectRatio: false
  };

  // Data for Line Chart 1
  lineChartColors: Array<any> = [
    {
      fill: false,
      borderDash: [5, 5],
      borderColor: "#9C27B0",
      pointBorderColor: "#9C27B0",
      pointBackgroundColor: "#FFF",
      pointBorderWidth: 2,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
    },
    {
      fill: false,
      borderDash: [5, 5],
      borderColor: "#00A5A8",
      pointBorderColor: "#00A5A8",
      pointBackgroundColor: "#FFF",
      pointBorderWidth: 2,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
    }
  ];
  lineChartOptions: any = {
    animation: {
      duration: 1000, // general animation time
      easing: 'easeOutBack'
    },
    hover: {
      animationDuration: 1000, // duration of animations when hovering an item
      mode: 'label'
    },
    responsiveAnimationDuration: 1000, // animation duration after a resize
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      position: 'bottom',
    },
    scales: {
      xAxes: [{
        display: true,
        gridLines: {
          color: "#f3f3f3",
          drawTicks: false,
        },
        scaleLabel: {
          display: true,
          labelString: 'Month'
        }
      }],
      yAxes: [{
        display: true,
        gridLines: {
          color: "#f3f3f3",
          drawTicks: false,
        },
        scaleLabel: {
          display: true,
          labelString: 'Value'
        }
      }]
    },
    title: {
      display: false,
      text: ''
    }
  };
  lineChartDataDefault = [{data: []}];
  lineChart1Data = {};
  lineChart2Data = {};

  constructor(
      private tokenStorage: TokenStorageService,
      private crudService: CrudServiceService,
      private messageService: InfoMessagesService,
      private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.companyId = this.tokenStorage.getCompanyId();
    this.fetchTypes();
    this.fetchUsers();
  }

  clearData() {
    this.assigned = {};
    this.delayed = {};
    this.pending = {};
    this.onTime = {};
    this.endedInADayAvg = {};
    this.newInADayAvg = {};

    this.endedTicketsPerType = {};
    this.newTicketsPerType = {};
    this.avgTime = {};
    this.typesInReport = {};
    this.usersInReport = [];
    this.activities = [];
    this.showPie = {};
    this.showLineChart1 = {};
    this.showLineChart2 = {};
    this.showAvgTimeTable = {};
    this.pieChartData = {};
    this.lineChart1Data = {};
    this.lineChart2Data = {}
  }

  fetchReportData() {
    if (new Date(this.startDate).getTime() >= new Date(this.finalDate).getTime()) {
      this.messageService.getInfoMessagePersonalized(
          'error',
          'La fecha inicial no puede ser posterior a la fecha final',
          'Error en el rango de fechas'
      );
      return;
    }
    this.clearData()
    this.searching = true;
    const search = {
      company: this.tokenStorage.getCompanyId(),
      type: this.types[this.type],
      user: this.users[this.user],
      profile: this.profile,
      startDate: this.startDate,
      finalDate: this.finalDate
    };
    const path = 'actividad/indicador-actividades';
    this.crudService.createModel(path, search).toPromise().then((response: GenericResponseModel) => {
      this.activities = response.answerList;
      if (this.activities.length <= 0) {
        this.messageService.getInfoMessagePersonalized('warning', 'No hay elementos para mostrar', 'Sin resultados');
      } else {
        this.getUsersInReport();
      }
      this.searching = false;
    }).catch(error => {
      console.log(error);
      this.messageService.getInfoMessagePersonalized(
        'error',
        'No se pudo obtener información de los indicadores de actividad',
        'Error obteniendo indicadores'
      );
      this.searching = false;
    });
  }

  fetchUsers() {
    const path = 'usuarios/usuarios-por-empresa?company=' + this.companyId;
    this.crudService.getModel(path).toPromise().then((response: GenericResponseModel) => {
      const usersList = response.answerList;
      this.users = {};
      usersList.forEach(u => {
        this.users[u.id] = u;
      });
    }).catch(error => {
      console.log(error);
      this.messageService.getInfoMessagePersonalized(
          'error',
          'No se pudo obtener información de usuarios',
          'Error cargando usuarios'
      );
    });
  }

  fetchTypes() {
    const path = 'empresa/tipo-actividad/listar-tipo-actividad';
    this.crudService.getModel(path).toPromise().then((response: GenericResponseModel) => {
      const listTypes = response.answerList.filter(type => type.name !== 'Soporte Seguros App');
      this.types = {};
      listTypes.forEach(t => {
        this.types[t.id] = t;
      });
    }).catch(error => {
      console.log(error);
      this.messageService.getInfoMessagePersonalized(
          'error',
          'No se pudo obtener información de tipos de actividad',
          'Error cargando tipos de actividad'
      );
    });
  }

  getKeys(obj) {
    return Object.keys(obj);
  }

  filterUsers() {
    if (!this.profile) {
      return [];
    }
    return Object.keys(this.users).filter(u => this.users[u].role === this.profile);
  }

  calcPieIndicators(user) {
    const userActivities = this.activities.filter(a => a.responsibleUserId.id == user);
    this.assigned[user] = userActivities.length;
    this.delayed[user] = 0;
    this.pending[user] = 0;
    this.onTime[user] = 0;
    for (let i = 0; i < userActivities.length; i++) {
      const act = userActivities[i];
      if (!act.endedAt) {
        this.pending[user]++;
        continue;
      }
      const expiredTime = new Date(act.expireDate).getTime();
      const endedTime = new Date(act.endedAt).getTime();
      if (expiredTime >= endedTime) {
        this.onTime[user] = this.onTime[user] ? this.onTime[user] + 1 : 1;
      } else {
        this.delayed[user] = this.delayed[user] ? this.delayed[user] + 1 : 1;
      }
    }
    this.pieChartData[user] = [this.delayed[user], this.pending[user], this.onTime[user]];
    this.showPie[user] = true;
  }

  calcBars1Indicators(user) {
    this.showLineChart1[user] = false;
    const startTime = new Date(this.startDate).getTime();
    const finalTime = new Date(this.finalDate).getTime();
    const totalDays = Math.ceil(finalTime - startTime) / (1000 * 60 * 60 * 24);
    const userActivities = this.activities.filter(a => a.responsibleUserId.id == user);

    this.endedInADayAvg[user] = userActivities.filter(a => {
      if (!a.endedAt) {
        return false;
      }
      const endedTime = new Date(a.endedAt).getTime();
      return startTime <= endedTime && endedTime <= finalTime;
    }).length / totalDays;
    this.newInADayAvg[user] = userActivities.filter(a => {
      const createdTime = new Date(a.createdDate).getTime();
      return startTime <= createdTime && createdTime <= finalTime;
    }).length / totalDays;

    const diff = (finalTime - startTime) / 4;
    const breakpoint1 = startTime + diff;
    const breakpoint2 = breakpoint1 + diff;
    const breakpoint3 = breakpoint2 + diff;
    const createdActivities = [0, 0, 0, 0];
    const endedActivities = [0, 0, 0, 0];
    userActivities.forEach(a => {
      const createdTime = new Date(a.createdDate).getTime();
      const endedTime = a.endedAt ? new Date(a.endedAt).getTime() : null;
      // Calc created activities in a segment
      if (createdTime >= startTime && createdTime < breakpoint1) {
        createdActivities[0] = createdActivities[0] + 1;
      } else if (createdTime >= breakpoint1 && createdTime < breakpoint2) {
        createdActivities[1] = createdActivities[1] + 1;
      } else if (createdTime >= breakpoint2 && createdTime < breakpoint3) {
        createdActivities[2] = createdActivities[2] + 1;
      } else if (createdTime >= breakpoint3 && createdTime < finalTime) {
        createdActivities[3] = createdActivities[3] + 1;
      }
      // Calc ended activities in a segment
      if (endedTime >= startTime && endedTime < breakpoint1) {
        endedActivities[0] = endedActivities[0] + 1;
      } else if (endedTime >= breakpoint1 && endedTime < breakpoint2) {
        endedActivities[1] = endedActivities[1] + 1;
      } else if (endedTime >= breakpoint2 && endedTime < breakpoint3) {
        endedActivities[2] = endedActivities[2] + 1;
      } else if (endedTime >= breakpoint3 && endedTime < finalTime) {
        endedActivities[3] = endedActivities[3] + 1;
      }
    })
    this.lineChart1Data[user] = [
      { data: createdActivities },
      { data: endedActivities }
    ];
    this.showLineChart1[user] = true;
    console.log(this.lineChart1Data[user])
  }

  calcBars2Indicators(user) {
    this.showLineChart2[user] = false;
    this.typesInReport[user] = [];
    this.newTicketsPerType[user] = {};
    this.endedTicketsPerType[user] = {};
    const userActivities = this.activities.filter(a => a.responsibleUserId.id == user);
    const newTicketsSegmented = {};
    const endedTicketsSegmented = {};
    const startTime = new Date(this.startDate).getTime();
    const finalTime = new Date(this.finalDate).getTime();
    const diff = (finalTime - startTime) / 4;
    const breakpoint1 = startTime + diff;
    const breakpoint2 = breakpoint1 + diff;
    const breakpoint3 = breakpoint2 + diff;
    userActivities.forEach(a => {
      if (!this.typesInReport[user].includes(a.activityTypeId.id)) {
        this.typesInReport[user].push(a.activityTypeId.id);
      }
      const createdTime = new Date(a.createdDate).getTime();
      const endedTime = a.endedAt ? new Date(a.endedAt).getTime() : null;
      if (createdTime >= startTime && createdTime <= finalTime) {
        if (!this.newTicketsPerType[user][a.activityTypeId.id]) {
          this.newTicketsPerType[user][a.activityTypeId.id] = [a];
        } else {
          this.newTicketsPerType[user][a.activityTypeId.id].push(a);
        }
      }
      if (endedTime && endedTime >= startTime && endedTime <= finalTime) {
        if (!this.endedTicketsPerType[user][a.activityTypeId.id]) {
          this.endedTicketsPerType[user][a.activityTypeId.id] = [a];
        } else {
          this.endedTicketsPerType[user][a.activityTypeId.id].push(a);
        }
      }
      // Compare if activity between break points
      if (createdTime >= startTime && createdTime < breakpoint1) {
        if (!newTicketsSegmented[a.activityTypeId.id]) {
          newTicketsSegmented[a.activityTypeId.id] = [1, 0, 0, 0];
        } else {
          newTicketsSegmented[a.activityTypeId.id][0] = newTicketsSegmented[a.activityTypeId.id][0] + 1;
        }
      } else if (createdTime >= breakpoint1 && createdTime < breakpoint2) {
        if (!newTicketsSegmented[a.activityTypeId.id]) {
          newTicketsSegmented[a.activityTypeId.id] = [0, 1, 0, 0];
        } else {
          newTicketsSegmented[a.activityTypeId.id][1] = newTicketsSegmented[a.activityTypeId.id][1] + 1;
        }
      } else if (createdTime >= breakpoint2 && createdTime < breakpoint3) {
        if (!newTicketsSegmented[a.activityTypeId.id]) {
          newTicketsSegmented[a.activityTypeId.id] = [0, 0, 1, 0];
        } else {
          newTicketsSegmented[a.activityTypeId.id][2] = newTicketsSegmented[a.activityTypeId.id][2] + 1;
        }
      } else if (createdTime >= breakpoint3 && createdTime < finalTime) {
        if (!newTicketsSegmented[a.activityTypeId.id]) {
          newTicketsSegmented[a.activityTypeId.id] = [0, 0, 0, 1];
        } else {
          newTicketsSegmented[a.activityTypeId.id][3] = newTicketsSegmented[a.activityTypeId.id][3] + 1;
        }
      }
      // Calc ended activities in a segment
      if (endedTime >= startTime && endedTime < breakpoint1) {
        if (!endedTicketsSegmented[a.activityTypeId.id]) {
          endedTicketsSegmented[a.activityTypeId.id] = [1, 0, 0, 0];
        } else {
          endedTicketsSegmented[a.activityTypeId.id][0] = endedTicketsSegmented[a.activityTypeId.id][0] + 1;
        }
      } else if (endedTime >= breakpoint1 && endedTime < breakpoint2) {
        if (!endedTicketsSegmented[a.activityTypeId.id]) {
          endedTicketsSegmented[a.activityTypeId.id] = [0, 1, 0, 0];
        } else {
          endedTicketsSegmented[a.activityTypeId.id][1] = endedTicketsSegmented[a.activityTypeId.id][1] + 1;
        }
      } else if (endedTime >= breakpoint2 && endedTime < breakpoint3) {
        if (!endedTicketsSegmented[a.activityTypeId.id]) {
          endedTicketsSegmented[a.activityTypeId.id] = [0, 0, 1, 0];
        } else {
          endedTicketsSegmented[a.activityTypeId.id][2] = endedTicketsSegmented[a.activityTypeId.id][2] + 1;
        }
      } else if (endedTime >= breakpoint3 && endedTime < finalTime) {
        if (!endedTicketsSegmented[a.activityTypeId.id]) {
          endedTicketsSegmented[a.activityTypeId.id] = [0, 0, 0, 1];
        } else {
          endedTicketsSegmented[a.activityTypeId.id][3] = endedTicketsSegmented[a.activityTypeId.id][3] + 1;
        }
      }
    });
    this.lineChart2Data[user] = [];
    this.typesInReport[user].forEach(t => {
      this.lineChart2Data[user].push({ data: newTicketsSegmented[t] || [0, 0, 0, 0] });
      this.lineChart2Data[user].push({ data: endedTicketsSegmented[t] || [0, 0, 0, 0] });
    })
    this.showLineChart2[user] = true;
    console.log(this.lineChart2Data[user]);
  }

  calcTableIndicators(user) {
    this.showAvgTimeTable[user] = false;
    this.avgTime[user] = {};
    const userActivities = this.activities.filter(a => a.responsibleUserId.id == user);
    userActivities.forEach(a => {
      if (!a.endedAt) {
        return
      }
      const createdTime = new Date(a.createdDate).getTime();
      const endedTime = new Date(a.endedAt).getTime();
      const diff = (endedTime - createdTime) / (1000 * 60 * 60 * 24);
      if (!this.avgTime[user][a.activityTypeId.id]) {
        this.avgTime[user][a.activityTypeId.id] = [diff];
      } else {
        this.avgTime[user][a.activityTypeId.id].push(diff);
      }
    });
    this.showAvgTimeTable[user] = true;
  }

  getUsersInReport() {
    const totalUsers = this.activities.map(a => a.responsibleUserId.id);
    this.usersInReport = Array.from(new Set(totalUsers));
    this.usersInReport.forEach(u => {
      // Calc indicators
      this.calcPieIndicators(u);
      this.calcBars1Indicators(u);
      this.calcBars2Indicators(u);
      this.calcTableIndicators(u);
    });
  }

  getPendingByResponsible(id) {
    return this.activities.filter(a => a.responsibleUserId.id == id && !a.endedAt);
  }

  getEndedByResponsible(id) {
    return this.activities.filter(a => a.responsibleUserId.id == id && a.endedAt);
  }

  getAvg(list) {
    let total = 0;
    for (let i = 0; i < list.length; i++) {
      total += list[i];
    }
    return total / list.length
  }

  openReportModal(user) {
    const modal = this.modalService.open(ActivitiesIndicatorReportModalComponent, {
      windowClass: 'mediumModal', size: 'lg', backdrop: 'static'
    });
    modal.componentInstance.userName = this.users[user].completeName;
    modal.componentInstance.pieChartData = this.pieChartData[user];
    modal.componentInstance.lineChart1Data = this.lineChart1Data[user];
    modal.componentInstance.lineChart2Data = this.lineChart2Data[user];
    modal.componentInstance.assigned = this.assigned[user];
    modal.componentInstance.delayed = this.delayed[user];
    modal.componentInstance.pending = this.pending[user];
    modal.componentInstance.onTime = this.onTime[user];
    modal.componentInstance.endedInADayAvg = this.endedInADayAvg[user];
    modal.componentInstance.newInADayAvg = this.newInADayAvg[user];
    modal.componentInstance.endedTicketsPerType = this.endedTicketsPerType[user];
    modal.componentInstance.newTicketsPerType = this.newTicketsPerType[user];
    modal.componentInstance.avgTime = this.avgTime[user];
    modal.componentInstance.typesInReport = this.typesInReport[user];
    modal.componentInstance.types = this.types;
  }
}
