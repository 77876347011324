import { ClientModel } from './client-model';
import { BudgetModel } from './budget-model';
import { UserModel } from './user-model';
import { ClassOfInsuranceModel } from './class-of-insurance.model';
import { CompanyModel } from './company-model';
import { InsurerModel } from './insurer-model';

export class PolicyModel {

    id: string;
    policy: string;
    insurerId: InsurerModel;
    classOfInsuranceId: ClassOfInsuranceModel;
    validSince: any;
    validUntil: any;
    sellerUserId: UserModel;
    expeditionDate: any;
    clientId: ClientModel;
    state: string;
    createdDate: string;
    lastUpdateDate: string;
    sellerAndInsuredEquals: boolean;
    creatorId: UserModel;
    lastUpdateId: UserModel;
    insuredId: ClientModel;
    companyId: CompanyModel;
    budgetId: BudgetModel;
    policyMotherId: any;
    expirationDateSoat: Date;


    public constructor() {
        this.id = '';
        this.policy = '';
        this.insurerId = new InsurerModel();
        this.classOfInsuranceId = new ClassOfInsuranceModel();
        this.validSince = '';
        this.validUntil = '';
        this.sellerUserId = new UserModel();
        this.expeditionDate = '';
        this.clientId = new ClientModel();
        this.state = '';
        this.createdDate = '';
        this.lastUpdateDate = '';
        this.creatorId = new UserModel();
        this.lastUpdateId = null;
        this.insuredId = new ClientModel();
        this.sellerAndInsuredEquals = false;
        this.companyId = new CompanyModel();
        this.budgetId = new BudgetModel();
        this.policyMotherId = null;
        this.expirationDateSoat = null;
    }
}
