export class SearchClientModel {

    clientName: string;
    documentNumberClient:string;
    sellerName: string;
    gender: string;

    constructor() {
        this .clientName = '';
        this .documentNumberClient='';
        this .sellerName = '';
        this.gender = '';
    }
}
