import { PairModuleMenuModel } from './../../../models/utilities/pair-module-menu-model';
import { CrudServiceService } from './../../shared/backend/cruds/crud-service.service';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModuleModel } from 'models/entities/module-model';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';

@Component({
  selector: 'app-to-pair-menu',
  templateUrl: './to-pair-menu.component.html',
  styleUrls: ['./to-pair-menu.component.scss']
})
export class ToPairMenuComponent implements OnInit {

  rows = [];
  temp: string[];
  public menuInfo: any;
  selectedModule: any = '';
  moduleMenu: PairModuleMenuModel = new PairModuleMenuModel()
  public moduleInfo = new ModuleModel()
  title = 'Seleccione'
  errorMessage = '';
  isPairedMenuFailed = false;
  dato: any;
  form: any = {};

  columns = [
    { prop: 'menu' },
    { name: 'createdDate' }
  ];
  @Input() public moduleInfoUpdate;
  @ViewChild('f') ModuleForm: NgForm;

  constructor(
    private modalService: NgbModal,
    private crudServices: CrudServiceService,
    private messageService: InfoMessagesService
  ) { }

  ngOnInit() {
    this.form.moduleMenu = new PairModuleMenuModel()
    this.listMenuModule(this.moduleInfoUpdate.id);
  }

  configSelectMenu() {
    this.crudServices.getModel('menu/ver-todos').subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.menuInfo = genericResponse.answerList;
          const menuToSelect = Array();
          for (const menu of this.menuInfo) {
            let itsIn = false;
            for (const menuPorModulo of this.dato) {
              if (menu.id === menuPorModulo.id) {
                itsIn = true;
              }
            }
            if (!itsIn) {
              menuToSelect.push(menu);
            }
          }
          this.menuInfo = menuToSelect;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se encontraron menús')
        }
      },
      error => {
        this.errorMessage = error.error.message;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  listMenuModule(id) {
    this.crudServices.getModel('modulos/listar-menu-por-modulo?moduleId=' + id).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.dato = genericResponse.answerList;
          this.temp = [...this.dato];
          this.rows = this.dato;
          this.configSelectMenu()
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No hay menús emparejados')
        }
      },
      error => {
        this.errorMessage = error.error.message;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  toPair(idMenu, idModule) {
    this.moduleMenu.menuId = {
      id: idMenu
    };
    this.moduleMenu.moduleId = {
      id: idModule
    }
    this.crudServices.createModel('modulos/enlazar-modulo-menu', this.moduleMenu).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.isPairedMenuFailed = false;
          this.listMenuModule(idModule);
          this.messageService.getInfoMessageCreate();
          this.moduleMenu.menuId = '-1';
          this.form.moduleMenu.idMenu = '-1';
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Error en el emparejamiento')
        }
      },
      error => {
        this.isPairedMenuFailed = true;
        this.errorMessage = error.error.info;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  unPair(idMenu, idModule) {
    this.moduleMenu.menuId = {
      id: idMenu
    };
    this.moduleMenu.moduleId = {
      id: idModule
    }
    console.log(this.moduleMenu)
    this.crudServices.createModel('modulos/desenlazar-modulo-menu', this.moduleMenu).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.listMenuModule(idModule);
          this.messageService.getInfoMessageDelete();
          this.moduleMenu.menuId = '-1';
          this.form.moduleMenu.idMenu = '-1';
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Error en el desemparejamiento')
        }
      },
      error => {
        this.errorMessage = error.error.info;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  closeWindow() {
    this.modalService.dismissAll();
  }

}
