export class PlantillaModel {
    id: string;
    nombre: string;
    descripcion: any;
    responsable: any;
    fechaCreacion: any;

    public constructor() {
        this.id = '';
        this.nombre = '';
        this.descripcion = '';
        this.responsable = '';
        this.fechaCreacion = '';
    }

}