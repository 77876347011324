import { Injectable } from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ActivitiesService {

  activitiesListSubscription = new Subject<any>();
  activitiesPageSubscription = new Subject<any>();
  constructor() { }

  getActivitiesListStatus() {
    return this.activitiesListSubscription.asObservable();
  }

  setActivitiesListStatus() {
    this.activitiesListSubscription.next();
  }

  getActivitiesPage() {
    return this.activitiesPageSubscription.asObservable();
  }

  setActivitiesPage(pageInfo: any) {
    this.activitiesPageSubscription.next(pageInfo);
  }
}
