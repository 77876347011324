import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { ClientModel } from 'models/entities/client-model';
import { ModalInformationModel } from 'models/utilities/modal-information-model';
import { RelationalFileModel } from 'models/utilities/relational-type-model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import {TokenStorageService} from "../../shared/storage-services/token-storage.service";
import {CrudServiceService} from "../../shared/backend/cruds/crud-service.service";
import {GenericResponseModel} from "../../../models/utilities/generic.response.model";
import {InfoMessagesService} from "../../shared/messages/info-messages.service";

@Component({
  selector: 'app-manage-clients',
  templateUrl: './manage-clients.component.html',
  styleUrls: ['./manage-clients.component.scss']
})

export class ManageClientsComponent implements OnInit {

  @Input() public clientModel: ClientModel = new ClientModel();
  @Input() public modelInfoClient: ModalInformationModel = new ModalInformationModel();
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @ViewChild('f') createClientForm: NgForm;
  @Input() public actvityClient=false;
  @ViewChild('t') tabset;
  @ViewChild('txtUrlInput') txtUrlInput;

  public relationalModel: RelationalFileModel = new RelationalFileModel();

  role = '';
  tempUrl = '';

  constructor(
      private activeModal: NgbActiveModal,
      private tokenStorage: TokenStorageService,
      private crudService: CrudServiceService,
      private messagesService: InfoMessagesService
  ) {}

  ngOnInit() {
    this.role = this.tokenStorage.getAuthorities();
    this.relationalModel.relationalId = this.clientModel.id;
    this.relationalModel.relationalType = 'DOCUMENTS_CLIENT_FILE';
    this.relationalModel.relationalFileType = 'ADJUNTO';
    this.relationalModel.relationalObjetive = 'adjunto para cliente';
    this.relationalModel.relationalRestrictions = new Array();
  }

  close() {
    this.activeModal.close();
  }

  reportOfSon($event) {
    if ($event.response === 'ok') {      
      this.modelInfoClient.isCreate = false;
      this.passEntry.emit('ok');
      this.relationalModel.relationalId = $event.id;
      setTimeout(() => {
         this.tabset.select('tab-documnets');
      }, 50);
    }
  }

  generateTempUrl() {
    const path = `cliente/obtener-url-temporal?clientId=${this.clientModel.id}`;
    this.crudService.getModel(path).toPromise().then((response: GenericResponseModel) => {
      this.tempUrl = `https://www.segurosapp.co${response.genericObject.tempUrl}`;
    }).catch(error => {
      console.log(error);
      this.messagesService.getInfoMessagePersonalized(
          'error',
          'Hubo un problema generando link temporal',
          'No se puede obtener link temporal'
      );
    });
  }

  copyUrl() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.innerText = this.tempUrl;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.messagesService.getInfoMessagePersonalized(
        'success',
        'Link copiado',
        'El link ha sido copiado a tu portapapeles'
    );
  }
}
