import { UserModel } from 'models/entities/user-model';
import { BankAccountModel } from 'models/entities/bank-account-model';
import { PolicyModel } from './policy-model';
export class RemissionModel {
    id: string;
    expeditionDate: any;
    dueDate: any;
    documentClass: string;
    concept: string;
    premium: string;
    spendings: string;
    iva: string;
    totalValue: string;
    bankAccountId: BankAccountModel;
    policyId: any;
    createdDate: any;
    lastUpdateDate: string;
    creatorId: any;
    state: string;
    lastUpdateId: UserModel;
    sequence: number;

    public constructor() {
        this.id = '';
        this.expeditionDate = '';
        this.dueDate = '';
        this.documentClass = 'Renovación';
        this.state = '';
        this.concept = '';
        this.premium = '0';
        this.spendings = '0';
        this.iva = '0';
        this.totalValue = '0';
        this.bankAccountId = null;
        this.policyId = null;
        this.createdDate = '';
        this.lastUpdateDate = '';
        this.creatorId = null;
        this.lastUpdateId = null;
        this.sequence = null;
    }

    validateCero(toValid) {
        if (toValid <= 9) {
            if (toValid === 0) {
                return '0' + 1
            } else {
                return '0' + toValid
            }

        } else {
            return toValid
        }
    }

}