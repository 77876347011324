import { Routes, RouterModule } from '@angular/router';

//Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...

export const CONTENT_ROUTES: Routes = [
    {
        path: 'pages',
        loadChildren: './pages/content-pages/content-pages.module#ContentPagesModule'
    },
    {
        path: 'welcome',
        loadChildren: './welcome/welcome.module#WelcomeModule'
    },
    {
        path: 'trial',
        loadChildren: './trial-account/trial-account.module#TrialAccountModule'
    },
    {
        path: 'clientes-temp',
        loadChildren: './clients-tmp/clients-tmp.module#ClientsTmpModule'
    }
];