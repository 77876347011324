export class ClassOfInsuranceModel {
    id: any;
    name: string;
    description: string;
    createdDate: string;

    public constructor(){
        this.id = null;
        this.name = '';
        this.description = '';
        this.createdDate = '';
    }
}