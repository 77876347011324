import { InsurerModel } from 'models/entities/insurer-model';
import { ClassOfInsuranceModel } from 'models/entities/class-of-insurance.model';
import { UserModel } from 'models/entities/user-model';

export class SearchProductivityReportModel {
    initialDate: any;
    finalDate: any;
    classOfInsuranceId: ClassOfInsuranceModel;
    insurerId: InsurerModel;
    sellerId: UserModel;

    constructor() {
        this.initialDate = '';
        this.finalDate = '';
        this.classOfInsuranceId = new ClassOfInsuranceModel();
        this.insurerId= new InsurerModel();
        this.sellerId= new UserModel();
    }
}