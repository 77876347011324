import { RoleAuthGuard } from 'app/shared/auth/roleAuth-guard.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PlansRoutingModule } from './plans-routing.module';
import { CreatePlanComponent } from './create-plan/create-plan.component';
import { ToPairModuleComponent } from './to-pair-module/to-pair-module.component';
import { UpdatePlanComponent } from './update-plan/update-plan.component';
import { DetailPlanComponent } from './detail-plan/detail-plan.component';
import { ListPlanComponent } from './list-plan/list-plan.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    PlansRoutingModule,
    NgxDatatableModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [CreatePlanComponent, ToPairModuleComponent, UpdatePlanComponent, DetailPlanComponent, ListPlanComponent],
  providers: [RoleAuthGuard],
})
export class PlansModule { }
