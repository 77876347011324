import { MenuModel } from './../../../models/entities/menu-model';
import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';

@Component({
  selector: 'app-createmenu',
  templateUrl: './createmenu.component.html',
  styleUrls: ['./createmenu.component.scss']
})
export class CreatemenuComponent implements OnInit {

  public menuInfo = new MenuModel()
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  public isCreatedMenu = true;
  errorMessage: any;
  form: any = {};
  @ViewChild('f') createMenuForm: NgForm;


  constructor(
    private crudServices: CrudServiceService,
    private modalService: NgbModal,
    private messageService: InfoMessagesService
  ) { }

  ngOnInit() {
  }

  onSubmit() {
    this.crudServices.createModel('menu/crear', this.menuInfo).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.isCreatedMenu = true;
          this.messageService.getInfoMessageCreate().then(
            (result) => {
              this.modalService.dismissAll();
              this.passEntry.emit('Menú Creado');
            }
          )
        }
        if (genericResponse.code === 400) {
          this.isCreatedMenu = false;
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'Problema creando menús')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
        this.isCreatedMenu = false;
        this.errorMessage = error.error.answer;
      }
    );
  }

  closeButton() {
    this.modalService.dismissAll();
  }


}
