import { ExportExcelService } from 'app/shared/excel/export-excel.service';
import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { UserModel } from 'models/entities/user-model';
import { Subject, Observable, Observer } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, filter, merge } from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { CommissionModel } from 'models/entities/commission.model';
import { formatDate } from '@angular/common';
import { SelectionType, ColumnMode } from '@swimlane/ngx-datatable';
import { SharedService } from 'app/shared/shared.service';
import { CompanyModel } from 'models/entities/company-model';
import * as jsPDF from 'jspdf';
import { SearchCommissionReportModel } from 'models/utilities/search-commission-report-model';
import { ClassOfInsuranceModel } from 'models/entities/class-of-insurance.model';
import { InsurerModel } from 'models/entities/insurer-model';

@Component({
  selector: 'app-commission-report',
  templateUrl: './commission-report.component.html',
  styleUrls: ['./commission-report.component.scss']
})

export class CommissionReportComponent implements OnInit {

  public canShowLoading = false;
  public filter = {
    initialDate: '',
    finalDate: '',
    sellerId: {
      id: null,
      completeName: '-'
    }
  };

  listSellers: Array<UserModel> = new Array();
  searchCommissionReportModel: SearchCommissionReportModel = new SearchCommissionReportModel();

  focusSeller$ = new Subject<string>();
  clickSeller$ = new Subject<string>();

  @ViewChild('instanceClient') instanceClient: NgbTypeahead;

  rows: Array<CommissionModel> = new Array();
  dataLoad = false;

  total = 'TOTAL';
  commissionCalculatedSum = 0;
  commissionSubtotalSum = 0;
  commissionIvaSum = 0;
  commissionReteIvaSum = 0;
  commissionRetefuente = 0;
  date: any;

  ColumnMode = ColumnMode;
  SelectionType = SelectionType;

  enableSummary = true;
  summaryPosition = 'bottom';

  @ViewChild('customSummaryCell')
  customSummaryCell: TemplateRef<any>;

  isNatural = false;

  companyModel = new CompanyModel();
  logo: any;

  seller = '';
  document = '';

  // Inician variables de RAMO
  focusClassOfInsurance$ = new Subject<string>();
  clickClassOfInsurance$ = new Subject<string>();
  @ViewChild('instanceClassOfInsurance') instanceClassOfInsurance: NgbTypeahead;
  listClassOfInsurances: Array<ClassOfInsuranceModel> = new Array();
  // Terminan variables de RAMO

  // Inician variables de ASEGURADORA
  focusInsurer$ = new Subject<string>();
  clickInsurer$ = new Subject<string>();
  @ViewChild('instanceInsurer') instanceInsurer: NgbTypeahead;
  listInsurer: Array<InsurerModel> = new Array();
  // Terminan variables de ASEGURADORA

  columnsCommission = [
    { prop: 'policyId.policy', width: 200, name: 'Póliza' },
    { prop: 'insurerId.name', width: 200, name: 'Aseguradora' },
    { prop: 'insuredId.nameOrBusinessName', width: 300, name: 'Asegurado' },
    { prop: 'createdDate', width: 300, name: 'Fecha de pago' },
    { prop: 'classofInsuranceId.name', width: 150, name: 'Aseguradora' },
    { prop: 'premiumCalculated', width: 300, name: 'Abono prima' },
    { prop: 'administrationPercentage', width: 150, name: '% Comisión' },
    { prop: 'administrationPercentageCalculated', width: 300, name: 'Valor Comisión' },
    { prop: 'commissionCalculated', width: 300, name: 'Neto Comisión' },
    { prop: 'commissionAdministrationCalculated', width: 300, name: 'Total 1' },
    { prop: 'subtotal', width: 300, name: 'subtotal' },
    { prop: 'retefuentePercentageCalculated', width: 300, name: 'Retefuente' },
    { prop: 'iva', width: 300, name: 'IVA' },
    { prop: 'reteiva', width: 300, name: 'RETEIVA' },

    { prop: 'TOTAL', summaryFunc: null },
  ];

  constructor(
    private crudService: CrudServiceService,
    private messageService: InfoMessagesService,
    private tokenStorage: TokenStorageService,
    private sharedService: SharedService,
    private excelService: ExportExcelService
  ) {
    this.date = formatDate(new Date(), 'yyyy-MM-dd', 'en');
  }

  ngOnInit() {
    this.getSellers();
    this.getClassOfInsurances();
    this.getInsurers();
    setTimeout(() => {
      this.imageLoad();
    }, 2000);
  }

  getSellers() {
    const pathListSelle = 'usuarios/listar-vendedores-y-gestores';
    this.tokenStorage.getCompanyId()
    this.listSellers = new Array();
    this.crudService.getModel(pathListSelle).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          const listSeller = genericResponse['answerList'];
          listSeller.forEach(seller => {
            this.listSellers.push(seller);
          });
        } else {
          this.messageService.getInfoMessageError();
        }
      },
      error => {
        this.messageService.getInfoMessagePersonalized(
          'error',
          'No se pudo Listar Vendedores',
          'Error'
        );
        console.error('El error es ', JSON.stringify(error.message));
      }
    );
  }

  searchSeller = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focusSeller$),
      merge(this.clickSeller$.pipe(filter(() => !this.instanceClient.isPopupOpen()))),
      map(search => (search === '' ? this.listSellers
        : this.listSellers.filter(seller => seller.completeName.toLowerCase().indexOf(search.toLowerCase()) > -1)).slice(0, 10))
    );

  formatterSeller = (x: { completeName: string }) => x.completeName;

  rebuildSeller() {
    this.searchCommissionReportModel.sellerId.id = null;
  }

  filterCommissions() {

    this.commissionIvaSum = 0;
    this.commissionSubtotalSum = 0;
    this.commissionCalculatedSum = 0;
    this.commissionRetefuente = 0;
    this.commissionReteIvaSum = 0;

    this.seller = '';
    this.document = '';
    const path = 'comisiones/listar-comisiones-por-vendedor-entre-fechas?sellerId='
      + this.searchCommissionReportModel.sellerId.id + '&initialDate='
      + this.searchCommissionReportModel.initialDate + '&finalDate=' + this.searchCommissionReportModel.finalDate;
    this.crudService.getModel(path).subscribe(
      (genericResponse: GenericResponseModel) => {
        console.log(genericResponse)
        if (genericResponse.code === 200 && genericResponse.answerList.length > 0) {
          this.rows = genericResponse.answerList;
          // this.isNatural = this.rows[0].sellerUserId.documentTypeId.name !== 'NIT';
          this.seller = this.rows[0].sellerUserId.completeName;
          this.document = this.rows[0].sellerUserId.documentNumber;
          this.calculateSumm();
        } else {
          this.messageService.getInfoMessagePersonalized(
            'warning',
            'No se encontraron comisiones',
            'Aviso'
          );
          this.rows = []
        }
      },
      error => {
        this.messageService.getInfoMessagePersonalized(
          'error',
          'No se pudo Listar Vendedores',
          'Error'
        );
        console.error('El error es ', JSON.stringify(error.message));

      }
    );
  }

  /***
   * Exportación a excel de la a información consultada en el reporte
   */
  async exportToExcel() {
    this.rows.forEach(
      (async commissionItem => {
        commissionItem.commissionCalculated = Math.round(Number(commissionItem.commissionCalculated));
        commissionItem.commissionAdministrationCalculated = Math.round(Number(commissionItem.commissionAdministrationCalculated));
        commissionItem.premiumCalculated = Math.round(Number(commissionItem.premiumCalculated));
      })
    )
    this.excelService.exportAsExcelFile(this.rows, 'Reporte de comisiones - ' + this.companyModel.name);
  }

  downloadPDF() {
    if (this.rows) {
      
        this.dataLoad = true;
        const columns = ['Póliza', 'Aseguradora', 'Asegurado', 'Fecha pago', 'Ramo',
          'Prima', '% Comisión', 'Comisión', 'Subtotal', 'IVA','RETEIVA', 'Retefuente', 'Total'];
        const commissionAux = [];
        this.rows.forEach(commission => {
          commissionAux.push([
            commission.policyId.policy,
            commission.insurerId.name,
            commission.insuredId.nameOrBusinessName,
            commission.createdDate,
            commission.classOfInsuranceId.name,
            parseInt(commission.premiumCalculated, 10).toFixed(0),
            commission.administrationPercentage,
            parseInt(commission.commissionAdministrationCalculated, 10).toFixed(0),
            parseInt((+commission.commissionAdministrationCalculated * 0.69) + '', 10).toFixed(0),
            parseInt(commission.ivaCalculated || 0, 10).toFixed(0),
            parseInt(commission.reteIva || 0, 10).toFixed(0),
            parseInt( commission.retefuentePercentageCalculated, 10).toFixed(0),
            parseInt(commission.commissionCalculated, 10).toFixed(0)
          ]);
        });
        commissionAux.push([
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          'Total',
          this.commissionSubtotalSum.toFixed(0),
          this.commissionIvaSum.toFixed(0),
          this.commissionReteIvaSum.toFixed(0),
          this.commissionRetefuente.toFixed(0),
          this.commissionCalculatedSum.toFixed(0)
        ]);
        console.log(commissionAux);
        const rows = commissionAux;
        this.getCompany();

        setTimeout(() => {
          this.generatePdfCommissions(columns, rows, this.companyModel, 'Reporte de Comisiones');
          this.dataLoad = false;
        }, 1200);
      
    } else {
      this.messageService.getInfoMessagePersonalized(
        'warning',
        '',
        'No hay elementos para generar el documento'
      );
    }

  }

  getCompany() {
    const pathCompanyUrl = 'empresa/compania/ver-compania?id=' + this.tokenStorage.getCompanyId();
    this.crudService.getModel(pathCompanyUrl).subscribe(
      data => {
        this.companyModel = data.genericObject;
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar la compañia: ' + error)
      }
    );
  }

  calculateSumm() {
    if (this.rows) {
      this.rows.forEach((item) => {
        this.commissionCalculatedSum += +item.commissionCalculated;
        this.commissionSubtotalSum += +item.commissionAdministrationCalculated * 0.69;
        this.commissionRetefuente += + item.retefuentePercentageCalculated;
        this.commissionReteIvaSum += +item.reteIva;
        this.commissionIvaSum += +item.ivaCalculated;
      });
    }
  }

  emptySumm() {
    return null;
  }

  imageLoad() {
    this.getBase64ImageFromURL(this.sharedService.getLogoUrlCompany()).subscribe(base64data => {
      // this is the image as dataUrl
      this.logo = 'data:image/jpg;base64,' + base64data;
    });
  }

  getBase64ImageFromURL(url: string) {
    return Observable.create((observer: Observer<string>) => {
      // create an image object
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      if (!img.complete) {
        // This will call another method that will create image from url
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = (err) => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }

  getBase64Image(img: HTMLImageElement) {
    // We create a HTML canvas object that will create a 2d image
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    // This will draw image
    ctx.drawImage(img, 0, 0);
    // Convert the drawn image to Data URL
    const dataURL = canvas.toDataURL('image/png');
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
  }

  private generatePdfCommissions(header: any[], rows: any[], company: CompanyModel, reportName: string) {
    const doc = new jsPDF('l', 'pt');
    const logoo = this.logo;

    doc.setFontSize(16)
    doc.setFontType('bold')
    doc.text(35, 30, 'INFORME DE COMISIONES ');
    doc.addImage(logoo, 'JPG', 35, 40, 80, 80); // base64, formato, X, Y, width, heigth
    doc.setFontSize(10)
    doc.setFontType('bold')
    doc.text(35, 145, 'Vendedor: ' + this.rows[0].sellerUserId.completeName);
    doc.setFontSize(10)
    doc.setFontType('bold')
    doc.text(35, 160, 'Generado: ' + this.date);
    doc.setFontSize(10)
    doc.setFontType('bold')
    doc.text(35, 175, 'Documento: ' + this.rows[0].sellerUserId.documentNumber);
    doc.setFontSize(10)
    doc.setFontType('bold')
    doc.text(35, 190, 'Periodo de liquidación: ' + this.filter.initialDate + ' - ' + this.filter.finalDate);
    doc.autoTable(header, rows, { startY: 200, styles: { fontSize: 8 } });
    doc.save(reportName + ' ' + company.name);
  }

  // Inicia carga de datos de RAMO
  getClassOfInsurances() {
    const pathListClassOfInsurancesUrl = 'ramos/listar-ramos';
    this.listClassOfInsurances = new Array();
    this.crudService.getModel(pathListClassOfInsurancesUrl).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          const listClassOfInsurancesAux = genericResponse.answerList;
          listClassOfInsurancesAux.forEach(classOfInsurances => {
            this.listClassOfInsurances.push(classOfInsurances);
          });
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar los tipos de ramos',
            'Problema consultando los tipos de ramos');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar los tipos de ramos: ' + JSON.stringify(error))
      }
    );
  }

  rebuildClassOfInsurance() {
    this.searchCommissionReportModel.classOfInsuranceId = { id: null };
  }

  searchByNameClassOfInsurance = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focusClassOfInsurance$),
      merge(this.clickClassOfInsurance$.pipe(filter(() => !this.instanceClassOfInsurance.isPopupOpen()))),
      map(search => (search === '' ? this.listClassOfInsurances
        : this.listClassOfInsurances.filter(classOfInsurance =>
          classOfInsurance.name.toLowerCase().indexOf(search.toLowerCase()) > -1)).slice(0, 10))
    );

  formatter = (object: { name: string }) => object.name;

  getInsurers() {
    const pathListInsurerUrl = 'empresa/aseguradora/listar-aseguradoras';
    this.listInsurer = new Array();
    this.crudService.getModel(pathListInsurerUrl).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.listInsurer = genericResponse.answerList;
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar las aseguradoras',
            'Problema consultando aseguradoras');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar las aseguradoras: ' + JSON.stringify(error))
      }
    );
  }

  rebuildInsurer() {
    this.searchCommissionReportModel.insurerId = { id: null };
  }

  searchByNameInsurer = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focusInsurer$),
      merge(this.clickInsurer$.pipe(filter(() => !this.instanceInsurer.isPopupOpen()))),
      map(search => (search === '' ? this.listInsurer
        : this.listInsurer.filter(insurer => insurer.name.toLowerCase().indexOf(search.toLowerCase()) > -1)).slice(0, 10))
    );

}
