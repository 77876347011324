import { Component, OnInit } from '@angular/core';
import {ClientModel} from "../../../models/entities/client-model";
import {PolicyModel} from "../../../models/entities/policy-model";
import {CrudServiceService} from "../../shared/backend/cruds/crud-service.service";
import {Router} from "@angular/router";
import {GenericResponseModel} from "../../../models/utilities/generic.response.model";
import {InfoMessagesService} from "../../shared/messages/info-messages.service";
import {AdminPrincipalRuPoliciesComponent} from "../../policies/admin-principal-ru-policies/admin-principal-ru-policies.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-client-record',
  templateUrl: './client-record.component.html',
  styleUrls: ['./client-record.component.scss']
})
export class ClientRecordComponent implements OnInit {

  client: ClientModel;
  policies: PolicyModel[];
  loading = true;
  status: number;

  constructor(
      private crudService: CrudServiceService,
      private router: Router,
      private messagesService: InfoMessagesService,
      private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.fetchClientInfo()
  }

  fetchClientInfo() {
    const path = 'cliente/obtener-info-cliente-por-url';
    this.crudService.createModelWA(path, this.router.url).toPromise().then((response: GenericResponseModel) => {
      this.status = response.code;
      if (response.code === 200) {
        const clientInfo = response.genericObject;
        this.client = clientInfo.client;
        this.policies = clientInfo.policies;
      }
      this.loading = false;
    }).catch(error => {
      console.log(error);
      this.messagesService.getInfoMessagePersonalized(
          'error',
          'No se pudo obtener información del clientes',
          'Error'
      );
      this.status = 204;
      this.loading = false;
    });
  }

  showPolicy(policy) {
    const modal = this.modalService.open(AdminPrincipalRuPoliciesComponent, {
      windowClass: 'mediumModal', size: 'lg', backdrop: 'static'
    });
    modal.componentInstance.policyModel = policy;
    modal.componentInstance.isTempClient = true;
    modal.componentInstance.passEntry.subscribe(result => {
      modal.dismiss();
    });
  }
}
