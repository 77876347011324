export class DocumentTypeModel {
    id: string;
    name: string;
    objective: string;

    public constructor() {
        this.id = null;
        this.name = '';
        this.objective = '';
    }
}
