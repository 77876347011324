import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SearchClientModel } from 'models/types/search-client-model';
import { ListClientsComponent } from '../list-clients/list-clients.component';

@Component({
  selector: 'app-principal-view',
  templateUrl: './principal-view.component.html',
  styleUrls: ['./principal-view.component.scss']
})
export class PrincipalViewComponent implements OnInit {
  @ViewChild(ListClientsComponent) sonList: ListClientsComponent;

  constructor() { }

  ngOnInit() { }

  comunicationSon($event) {
    if ($event === 1) {
      this.sonList.isSearching = false;
      setTimeout(() => {
        this.sonList.setPage({ offset: 0 });
      }, 500);
    } else {
      this.sonList.modelSearchClient2 = $event;
      this.sonList.isSearching = true;
      setTimeout(() => {
        this.sonList.setPage({ offset: 0 });
      }, 500);
    }
  }
}
