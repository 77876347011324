import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { ProfileModel } from 'models/entities/profile-model';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';

@Component({
  selector: 'app-create-profile',
  templateUrl: './create-profile.component.html',
  styleUrls: ['./create-profile.component.scss']
})
export class CreateProfileComponent implements OnInit {

  public profileInfo = new ProfileModel();
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  public isCreatedProfile = true;
  errorMessage: any;

  form: any = {};
  @ViewChild('f') createModuleForm: NgForm;

  constructor(
    private crudServices: CrudServiceService,
    private messageService: InfoMessagesService,
    private modalService: NgbModal,
    private tokenStorage: TokenStorageService) {
  }

  ngOnInit() {
  }

  onSubmit() {
    if (this.tokenStorage.getAuthorities() === 'ROLE_SUPER') {
      this.profileInfo.companyId = null;
    } else if (this.tokenStorage.getAuthorities() === 'ROLE_ADMIN') {
      this.profileInfo.companyId.id = this.tokenStorage.getCompanyId();
    }
    this.profileInfo.name = this.form.name;
    this.profileInfo.description = this.form.description
    this.crudServices.createModel('perfil/crear', this.profileInfo).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.messageService.getInfoMessageCreate().then(
            (response) => {
              this.isCreatedProfile = true;
              this.modalService.dismissAll();
              this.passEntry.emit('Perfil Creado');
            }
          )
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se creo el perfil')

        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
        this.isCreatedProfile = false;
        this.errorMessage = error.error.info;
      }
    );

  }

  closeButton() {
    this.modalService.dismissAll();
  }


}
