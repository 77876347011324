import { ProfileModel } from 'models/entities/profile-model';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import swal from 'sweetalert2';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { PairUserProfileModel } from 'models/utilities/pair-user-profile-model';

@Component({
  selector: 'app-to-pair-profile',
  templateUrl: './to-pair-profile.component.html',
  styleUrls: ['./to-pair-profile.component.scss']
})
export class ToPairProfileComponent implements OnInit {

  public profileInfo: any;
  errorMessage = '';
  isPairedProfileFailed = false;
  public userProfile: ProfileModel = new ProfileModel();
  form: any = {};

  pairProfile: PairUserProfileModel = new PairUserProfileModel();

  @Input() public userInfoUpdate;
  @ViewChild('f') profileForm: NgForm;

  constructor(
    private crudService: CrudServiceService,
    private messageService: InfoMessagesService,
    private modalService: NgbModal,
    private tokenStorage: TokenStorageService
  ) { }

  ngOnInit() {
    this.configSelectProfile();
    this.listProfileUser(this.userInfoUpdate.userAux.id);
  }

  configSelectProfile() {
    let url = ''
    if (this.tokenStorage.getAuthorities() === 'ROLE_SUPER') {
      url = 'perfil/ver-todos';
    } else {
      url = 'perfil/admin/listar-perfiles-por-empresa'
    }
    this.crudService.getModel(url).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.profileInfo = genericResponse.answerList;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se encontraron planes')
        }
      },
      error => {
        this.errorMessage = error.error.message;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  listProfileUser(id) {
    this.crudService.getModel('usuarios/obtener-perfil?userId=' + id).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          if (genericResponse.genericObject) {
            this.userProfile = genericResponse.genericObject;
          }
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se encontro el perfil')
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
        this.errorMessage = error.error.message;
      }
    );
  }

  toPairProfile(idRole, idUser) {
    this.pairProfile.profileId = {
      id: idRole
    }

    this.pairProfile.userId = {
      id: idUser
    }
    this.crudService.createModel('usuarios/enlazar-perfil-usuario', this.pairProfile).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.messageService.getInfoMessageCreate().then(
            (response) => {
              this.isPairedProfileFailed = false;
              this.listProfileUser(idUser);
            }
          )
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se creó el enlace')
          this.isPairedProfileFailed = true;
        }
      },
      error => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
        this.errorMessage = error.error.info;
      }
    );
  }

  unPair(idRole, idUser) {
    this.pairProfile.profileId = {
      id: idRole
    }
    this.pairProfile.userId = {
      id: idUser
    }
    this.crudService.createModel('usuarios/desenlazar-perfil-usuario', this.pairProfile).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.messageService.getInfoMessageCreate().then(
            (response) => {
              this.isPairedProfileFailed = false;
              this.listProfileUser(idUser);
            }
          )
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se elimin[o] el enlace')
        }
      },
      error => {
        this.errorMessage = error.error.info;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  closeWindow() {
    this.modalService.dismissAll();
  }

}


