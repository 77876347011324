import { UserModel } from './../entities/user-model';
import { ClientModel } from './../entities/client-model';
import { InsurerModel } from './../entities/insurer-model';
import { ClassOfInsuranceModel } from 'models/entities/class-of-insurance.model';
export class SearchPolicyModel {
    expeditionDate: any;
    expirationDate: any;
    classOfInsuranceId: ClassOfInsuranceModel;
    insurerId: InsurerModel
    policyNumber: any;
    clientId: ClientModel;
    sellerId: UserModel;

    public constructor() {
        this.expeditionDate = '2018-01-02';
        this.expirationDate = '';
        this.classOfInsuranceId = null;
        this.insurerId = null;
        this.policyNumber = '';
        this.clientId = null;
        this.sellerId = null;
    }
}
