import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { ngxLoadingAnimationTypes } from 'ngx-loading';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  /* Variables para ventana de loading */
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  canShowLoading = false;

  adviceForm: FormGroup;
  formSubmitted = false;

  currentDate : Date = new Date();

  constructor(
    private router: Router,
    private messageServices: InfoMessagesService,
    private crudServices: CrudServiceService,
    private messageService: InfoMessagesService
  ) { }

  ngOnInit() {

    this.adviceForm = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      phone: new FormControl(null, [Validators.required]),
      message: new FormControl(null, [Validators.required]),
      packet: new FormControl(null, [Validators.required])
    });
  }


  get fa() {
    return this.adviceForm.controls;
  }

  openLogin(){
    this.router.navigate(['pages/auth']);
  }

  contactarWhatsapp() {
    window.open(
      "https://wa.me/573118550138?text=Hola, deseo obtener información acerca de seguros app");
  }

  irPlayStore() {
    window.open("https://play.google.com/store/apps/details?id=com.softlond.segurosapp");
  }

  irAppStore() {
    window.open("https://apps.apple.com/co/app/thurni/id1562173488");
  }

  irRegister() {
    this.router.navigate(['/trial']);
  }


  /* Se encarga de enviar el correo electronico para la asesoría
  */
  requestAdvice() {

    this.formSubmitted = true;

    if (this.adviceForm.invalid) {
      this.messageServices.getInfoMessagePersonalized(
        'warning',
        'Debe registrar todos los campos',
        'Atención!'
      );
    }
    else {
      this.canShowLoading = true;
      const data = this.adviceForm ? this.adviceForm.getRawValue() : '';

      let object = {
        "name": data.name,
        "email": data.email,
        "phone": data.phone,
        "message": data.message,
        "plan": data.packet
      }

      const path = 'api/industria/asesoria-planes-thurni';
      this.crudServices.createModelWA(path, object).toPromise().then((response: GenericResponseModel) => {
        if (response.code === 200) {
          this.messageService.getInfoMessagePersonalized(
            'success',
            'Nos pondremos en contacto contigo muy pronto',
            '!Gracias por contactarnos!'
          );

          this.cleanForm();
        } 
        else {
          this.messageService.getInfoMessagePersonalized(
            'error',
            'No fue posible recibir tu mensaje, por favor intentalo de nuevo',
            'Error!'
          );
        }

        this.canShowLoading = false;
      })
      .catch(error => {
        this.canShowLoading = false;
        this.messageService.getInfoMessageError();
      });
    }
  }

  
  cleanForm() {
    this.canShowLoading = false;
    this.formSubmitted = false;
    
    this.adviceForm.get('name').setValue(null);
    this.adviceForm.get('email').setValue(null);
    this.adviceForm.get('phone').setValue(null);
    this.adviceForm.get('message').setValue(null);
    this.adviceForm.get('packet').setValue(null);
  }

}
