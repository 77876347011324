import { UserModel } from 'models/entities/user-model';
import { ActivityManagmentModel } from './../../../models/entities/activity-managment';
import { ActivityModel } from 'models/entities/activity';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { RelationalFileModel } from 'models/utilities/relational-type-model';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';

@Component({
  selector: 'app-create-activity-managment',
  templateUrl: './create-activity-managment.component.html',
  styleUrls: ['./create-activity-managment.component.scss']
})
export class CreateActivityManagmentComponent implements OnInit {
  
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() activity=new ActivityModel();
  @Input() component:string='';

  @Output() action: EventEmitter<any> = new EventEmitter();
  user:any;
  activityManagmentModel=new ActivityManagmentModel();
  public relationalModel: RelationalFileModel = new RelationalFileModel();
  constructor(    private messageCtrl:InfoMessagesService,
    private serviceCtrl:CrudServiceService,
    private tokenServiceCtrl:TokenStorageService
  ) { }

  ngOnInit() {
    this.relationalModel.relationalId = this.activity.id;
    this.relationalModel.relationalType = 'ACTIVITY'
    this.relationalModel.relationalFileType = 'ARCHIVO';
    this.relationalModel.relationalRestrictions = new Array();
    this.getUser();
  }


  getUser(){
    let path='usuarios/buscar-por-id?userId='+this.tokenServiceCtrl.getId();
    this.serviceCtrl.getModel(path).subscribe(
      result=>{
        if(result.code===200)
        {
      
         this.user=result.genericObject;
        }
      },
      error=>{
       this.messageCtrl.getInfoMessageError();
      }
    )
  }

  close(){
    this.passEntry.emit('created');
  }

  createComent(){
    
    this.activityManagmentModel.activityId=this.activity;
    this.activityManagmentModel.creatorId=this.user.userAux;   
    let path='gestion-actividad';
    this.serviceCtrl.createModel(path,this.activityManagmentModel).subscribe(
      result=>{
        if(result.code===200)
        {
         this.messageCtrl.getInfoMessageCreate();
         this.passEntry.emit('created');
         this.action.emit('created');
         this.activityManagmentModel=new ActivityManagmentModel();
        }
      },
      error=>{
       this.messageCtrl.getInfoMessageError();
      }
    )
  }

}
