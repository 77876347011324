import {
  Component,
  OnInit,
  ElementRef,
  Inject,
  Renderer2,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  Output,
  EventEmitter
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'app/shared/services/config.service';
import { DOCUMENT } from '@angular/common';
import { LayoutService } from 'app/shared/services/layout.service';
import { Subscription } from 'rxjs';
import { HappyBirthDayReportComponent } from 'app/reports/happy-birth-day-report/happy-birth-day-report.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { ClientModel } from 'models/entities/client-model';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { InitialClientSearcherComponent } from 'app/shared/components/initial-client-searcher/initial-client-searcher.component';

var fireRefreshEventOnWindow = function() {
  var evt = document.createEvent('HTMLEvents');
  evt.initEvent('resize', true, false);
  window.dispatchEvent(evt);
};

@Component({
  selector: 'app-full-layout',
  templateUrl: './full-layout.component.html',
  styleUrls: ['./full-layout.component.scss']
})
export class FullLayoutComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('sidebarBgImage') sidebarBgImage: ElementRef;
  @ViewChild('appSidebar') appSidebar: ElementRef;
  @ViewChild('wrapper') wrapper: ElementRef;
  userToUpdate: any;

  options = {
    direction: 'ltr',
    bgColor: 'black',
    bgImage: 'assets/img/sidebar-bg/01.jpg'
  };
  hideSidebar: boolean;
  layoutSub: Subscription;
  iscollapsed = false;
  isSidebar_sm = false;
  isSidebar_lg = false;
  bgColor = 'black';
  bgImage = 'assets/img/sidebar-bg/01.jpg';

  public config: any = {};

  // Initial Clients Searcher variables start
  clientValue = '';
  idClient = -1;
  listClients: Array<ClientModel> = new Array();
  @Output() emitterValueToSearch: EventEmitter<any> = new EventEmitter();
  @ ViewChild(DatatableComponent) table: DatatableComponent;
  // Initial Clients Searcher variables ends

  constructor(
    private elementRef: ElementRef,
    private layoutService: LayoutService,
    private configService: ConfigService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private modalService: NgbModal,
    private crudService: CrudServiceService,
    private messageService: InfoMessagesService,
    private tokenStorage: TokenStorageService,
    private crudServices: CrudServiceService,
  ) {
    //event emitter call from customizer
    this.layoutSub = layoutService.customizerChangeEmitted$.subscribe(
      options => {
        if (options) {
          if (options.bgColor) {
            this.bgColor = options.bgColor;
          }
          if (options.bgImage) {
            this.bgImage = options.bgImage;
            this.renderer.setAttribute(
              this.sidebarBgImage.nativeElement,
              'style',
              'background-image: url(' + this.bgImage + ')'
            );
          }

          if (options.bgImageDisplay === true) {
            this.bgImage = options.bgImage;
            this.renderer.setAttribute(
              this.sidebarBgImage.nativeElement,
              'style',
              'display: block; background-image: url(' + this.bgImage + ')'
            );
          } else if (options.bgImageDisplay === false) {
            this.bgImage = '';
            // this.renderer.setAttribute(this.sidebarBgImage.nativeElement, 'style', 'display: none');
            this.renderer.setAttribute(
              this.sidebarBgImage.nativeElement,
              'style',
              'background-image: none'
            );
          }

          if (options.compactMenu === true) {
            this.renderer.addClass(this.wrapper.nativeElement, 'nav-collapsed');
            this.renderer.addClass(
              this.wrapper.nativeElement,
              'menu-collapsed'
            );
          } else if (options.compactMenu === false) {
            if (
              this.wrapper.nativeElement.classList.contains('nav-collapsed')
            ) {
              this.renderer.removeClass(
                this.wrapper.nativeElement,
                'nav-collapsed'
              );
              this.renderer.removeClass(
                this.wrapper.nativeElement,
                'menu-collapsed'
              );
            }
          }

          if (options.sidebarSize === 'sidebar-lg') {
            this.isSidebar_sm = false;
            this.isSidebar_lg = true;
          } else if (options.sidebarSize === 'sidebar-sm') {
            this.isSidebar_sm = true;
            this.isSidebar_lg = false;
          } else {
            this.isSidebar_sm = false;
            this.isSidebar_lg = false;
          }

          if (options.layout === 'Light') {
            this.renderer.removeClass(this.document.body, 'layout-dark');
            this.renderer.removeClass(this.document.body, 'layout-transparent');
            this.renderer.removeClass(this.document.body, 'bg-hibiscus');
            this.renderer.removeClass(this.document.body, 'bg-purple-pizzazz');
            this.renderer.removeClass(this.document.body, 'bg-blue-lagoon');
            this.renderer.removeClass(this.document.body, 'bg-electric-violet');
            this.renderer.removeClass(this.document.body, 'bg-portage');
            this.renderer.removeClass(this.document.body, 'bg-tundora');
            this.renderer.removeClass(this.document.body, 'bg-glass-1');
            this.renderer.removeClass(this.document.body, 'bg-glass-2');
            this.renderer.removeClass(this.document.body, 'bg-glass-3');
            this.renderer.removeClass(this.document.body, 'bg-glass-4');
          } else if (options.layout === 'Dark') {
            if (this.document.body.classList.contains('layout-transparent')) {
              this.renderer.removeClass(
                this.document.body,
                'layout-transparent'
              );
              this.renderer.removeClass(this.document.body, 'bg-hibiscus');
              this.renderer.removeClass(
                this.document.body,
                'bg-purple-pizzazz'
              );
              this.renderer.removeClass(this.document.body, 'bg-blue-lagoon');
              this.renderer.removeClass(
                this.document.body,
                'bg-electric-violet'
              );
              this.renderer.removeClass(this.document.body, 'bg-portage');
              this.renderer.removeClass(this.document.body, 'bg-tundora');
              this.renderer.removeClass(this.document.body, 'bg-glass-1');
              this.renderer.removeClass(this.document.body, 'bg-glass-2');
              this.renderer.removeClass(this.document.body, 'bg-glass-3');
              this.renderer.removeClass(this.document.body, 'bg-glass-4');

              this.renderer.addClass(this.document.body, 'layout-dark');
            } else {
              this.renderer.addClass(this.document.body, 'layout-dark');
            }
          } else if (options.layout === 'Transparent') {
            this.renderer.addClass(this.document.body, 'layout-transparent');
            this.renderer.addClass(this.document.body, 'layout-dark');
            this.renderer.addClass(this.document.body, 'bg-glass-1');
          }

          if (options.transparentColor) {
            this.renderer.removeClass(this.document.body, 'bg-hibiscus');
            this.renderer.removeClass(this.document.body, 'bg-purple-pizzazz');
            this.renderer.removeClass(this.document.body, 'bg-blue-lagoon');
            this.renderer.removeClass(this.document.body, 'bg-electric-violet');
            this.renderer.removeClass(this.document.body, 'bg-portage');
            this.renderer.removeClass(this.document.body, 'bg-tundora');
            this.renderer.removeClass(this.document.body, 'bg-glass-1');
            this.renderer.removeClass(this.document.body, 'bg-glass-2');
            this.renderer.removeClass(this.document.body, 'bg-glass-3');
            this.renderer.removeClass(this.document.body, 'bg-glass-4');
            this.renderer.addClass(
              this.document.body,
              options.transparentColor
            );
          }
        }
      }
    );
  }

  ngOnInit() {
    this.config = this.configService.templateConf;
    this.bgColor = this.config.layout.sidebar.backgroundColor;

    if (!this.config.layout.sidebar.backgroundImage) {
      this.bgImage = '';
    } else {
      this.bgImage = this.config.layout.sidebar.backgroundImageURL;
    }

    if (this.config.layout.variant === 'Transparent') {
      if (this.config.layout.sidebar.backgroundColor.toString().trim() === '') {
        this.bgColor = 'bg-glass-1';
      }
    } else {
      if (this.config.layout.sidebar.backgroundColor.toString().trim() === '') {
        this.bgColor = 'black';
      }
    }

    setTimeout(() => {
      if (this.config.layout.sidebar.size === 'sidebar-lg') {
        this.isSidebar_sm = false;
        this.isSidebar_lg = true;
      } else if (this.config.layout.sidebar.size === 'sidebar-sm') {
        this.isSidebar_sm = true;
        this.isSidebar_lg = false;
      } else {
        this.isSidebar_sm = false;
        this.isSidebar_lg = false;
      }
      this.iscollapsed = this.config.layout.sidebar.collapsed;
    }, 0);

    //emit event to customizer
    this.options.bgColor = this.bgColor;
    this.options.bgImage = this.bgImage;

    this.layoutService.emitCustomizerChange(this.options);
    if (this.tokenStorage.getToken()) {
        setTimeout(() => {
          const path = 'usuarios/buscar-por-id?userId=' + this.tokenStorage.getId();
          this.crudService.getModel(path).subscribe(
            (genericResponse: GenericResponseModel) => {
              if (genericResponse.code === 200) {
                this.userToUpdate = genericResponse.genericObject.userAux;
                console.log('reminder: ' + this.userToUpdate.reminderBirthday);
                if (this.userToUpdate.reminderBirthday === 'true') {
                  this.openHappyBirthdayReport();
                }
              } else {
                this.messageService.getInfoMessagePersonalized('warning', 'No se pudo consultar los cumpleaños de los usuarios',
                  'Problema consultando los cumpleaños asociados a su empresa');
              }
            },
            error => {
              this.messageService.getInfoMessageError();
              console.error('Error al cargar los cumpleaños asociados a su empresa ' + JSON.stringify(error))
            }
          );
        }, 500);
      }

    //customizer events
    // this.elementRef.nativeElement
    //   .querySelector('#cz-compact-menu')
    //   .addEventListener('click', this.onClick.bind(this));
    // this.elementRef.nativeElement
    //   .querySelector('#cz-sidebar-width')
    //   .addEventListener('click', this.onClick.bind(this));
  }

  openHappyBirthdayReport() {
    const modal = this.modalService.open(HappyBirthDayReportComponent, {
          windowClass: 'alertModal', size: 'sm', backdrop: 'static'
        });
    modal.componentInstance.isReminder = true;

    modal.componentInstance.passEntry.subscribe((retorno: string) => {
      if (retorno === 'remindMeTomorrow') {
        // tslint:disable-next-line:prefer-const
        let userAux = this.userToUpdate;
        userAux.reminderBirthday = false;
        userAux.activatedPlanId = { id: this.userToUpdate.activatedPlanId.id};
        userAux.companyId = { id: this.userToUpdate.companyId.id};
        // console.log(userAux);
        this.crudServices.createModel('usuarios/actualizar', userAux).subscribe(
          genericUpdatedResponse => {
            if (genericUpdatedResponse.code === 200) {
            //  console.error('Se se actualizó jejeje');
            } if (genericUpdatedResponse.code === 400) {
              this.messageService.getInfoMessagePersonalized('warning', 'Error al actualizar el estado', 'No se realizo la actualización')
            }
          }, error => {
            this.messageService.getInfoMessageBadInternet();
          })
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.config.layout.dir) {
        this.options.direction = this.config.layout.dir;
      }

      if (this.config.layout.variant === 'Dark') {
        this.renderer.addClass(this.document.body, 'layout-dark');
      } else if (this.config.layout.variant === 'Transparent') {
        this.renderer.addClass(this.document.body, 'layout-dark');
        this.renderer.addClass(this.document.body, 'layout-transparent');
        if (this.config.layout.sidebar.backgroundColor) {
          this.renderer.addClass(
            this.document.body,
            this.config.layout.sidebar.backgroundColor
          );
        } else {
          this.renderer.addClass(this.document.body, 'bg-glass-1');
        }
        this.bgColor = 'black';
        this.options.bgColor = 'black';
        this.bgImage = '';
        this.options.bgImage = '';
        this.bgImage = '';
        this.renderer.setAttribute(
          this.sidebarBgImage.nativeElement,
          'style',
          'background-image: none'
        );
      }
    }, 0);
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
  }

  onClick(event) {
    //initialize window resizer event on sidebar toggle click event
    setTimeout(() => {
      fireRefreshEventOnWindow();
    }, 300);
  }

  toggleHideSidebar($event: boolean): void {
    setTimeout(() => {
      this.hideSidebar = $event;
    }, 0);
  }

  getOptions($event): void {
    this.options = $event;
  }

  searchClient(value: string) {
    this.listClients = new Array();
    // console.log('El valor a buscar es: ' + value);
    this.getClientIdInList(value);
    setTimeout(() => {
      // console.log('clientModel ' + JSON.stringify(this.listClients));
    //   console.log(('Hay ' + this.listClients.length + ' clientes en el arreglo.'));
      // listClients: Array<ClientModel> = new Array();
      if (this.listClients.length > 0) {
        const modal = this.modalService.open(InitialClientSearcherComponent, {
          windowClass: 'mediumModal', size: 'lg', backdrop: 'static'
        });
        const clientModelToSend: Array<ClientModel> = this.listClients;
        modal.componentInstance.listClients = clientModelToSend;
        modal.componentInstance.canShowLoading = true;
        modal.componentInstance.valueToSearch = this.clientValue;
      } else {
        const modal = this.modalService.open(InitialClientSearcherComponent, {
          windowClass: 'mediumModal', size: 'lg', backdrop: 'static'
        });
        modal.componentInstance.valueToSearch = this.clientValue;
      }
    }, 500);
  }

  getClientIdInList (value: string) {
    const pathIdClient = 'cliente/ver-por-nombre-o-documento?valueToSearch=' + value;
    this.crudService.getModel(pathIdClient).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          if (genericResponse.answerList.length > 0) {
            genericResponse.answerList.forEach((client: ClientModel) => {
              this.listClients.push(client)
            });
          } else {
            // this.messageService.getInfoMessagePersonalized('warning', 'No se pudo encontrar el cliente en la empresa',
            // 'Problema consultando el cliente');
            this.listClients = new Array();
          }
        } else {
          // this.messageService.getInfoMessagePersonalized('warning', 'No se pudo encontrar el cliente en la empresa',
          //   'Problema consultando el cliente');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar los clientes de la empresa: ' + JSON.stringify(error))
      }
    );
  }
}
