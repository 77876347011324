import { ListarPlantillasComponent } from './listar-plantillas/listar-plantillas.component';
import { CrearPlantillasComponent } from './crear-plantillas/crear-plantillas.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoleAuthGuard } from 'app/shared/auth/roleAuth-guard.service';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'listar-plantillas',
        component: ListarPlantillasComponent,
        data: {
          title: 'listplantillas',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN', 'ROLE_USER']
        },
        canActivate: [RoleAuthGuard]
      },
      {
        path: 'crear-plantillas',
        component: CrearPlantillasComponent,
        data: {
          title: 'listplantillas',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN', 'ROLE_USER']
        },
        canActivate: [RoleAuthGuard]
      }
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PlantillasRoutingModule { }
