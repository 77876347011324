import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TrialAccountRoutingModule } from './trial-account-routing.module';
import { FormComponent } from './form/form.component';
import { ContainerComponent } from './container/container.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [FormComponent, ContainerComponent],
  imports: [
    CommonModule,
    TrialAccountRoutingModule,
    FormsModule
  ],
  entryComponents: [FormComponent]
})
export class TrialAccountModule { }
