import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { formatDate } from '@angular/common';
import { HappyBirthdayModel } from 'models/entities/happy-birthday.model';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { ExportExcelService } from 'app/shared/excel/export-excel.service';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PolicyModel } from 'models/entities/policy-model';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-happy-birth-day-report',
  templateUrl: './happy-birth-day-report.component.html',
  styleUrls: ['./happy-birth-day-report.component.scss']
})
export class HappyBirthDayReportComponent implements OnInit {
  listClients: any[]= [];
  listManagers: any[];
  listSellers: any[];
  listManagersAndSellers: any[];
  @Input() initialDate = '';
  @Input() finalDate = '';
  date = formatDate(new Date(), 'yyyy-MM-dd', 'en');
  @Input() todayDate = this.date;
  @Input() isReminder = false;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
 

  row = [];
  rows= Array<any>();
  rowsAux = Array<any>();
  temp: string[];
  errorMessage: string;
  dato: any;
  polizas: any[]= [];
  columns = [
    { prop: 'id', width: 200, name: 'ID' },
    
  ];

  constructor(
    private crudService: CrudServiceService,
    private messageService: InfoMessagesService,
    private tokenStorage: TokenStorageService,
    private excelService: ExportExcelService,
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    
     
      this.getClientsByBirthday(this.todayDate, this.todayDate);
    
  }

  createReport() {
   this.rows = new Array();
   
   setTimeout(() => {
     
     this.getClientsByBirthday(this.initialDate, this.finalDate);
     
   }, 500);
  }

  exportReport() {
    this.rowsAux = new Array();
    const results = this.rows;
    results.forEach( x => {
      
        this.rowsAux.push({
          'Nombre': x.clientId.nameOrBusinessName?x.clientId.nameOrBusinessName:'No aplica',
          'Fecha de nacimiento': x.clientId.birthDate.split('T')[0]?x.clientId.birthDate.split('T')[0]:'No aplica',
          'Celular': x.clientId.cellphone? x.clientId.cellphone:'No aplica',
          'Correo electrónico': x.clientId.email ? x.clientId.email:'No aplica',
          'Num. Póliza': x.policy ? x.policy:'No aplica',
          'Vencimiento Póliza': x.validUntil ? x.validUntil:'No aplica',
        });
      
    });
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.rowsAux);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, 'Reporte de cumpleaños ' + this.tokenStorage.getCompanyName());
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
 }

 getManagersAndSellersByBirthday(_birthday: any) {
  const path = 'reportes/listar-usuarios-vendedores-gestores-por-fecha-nacimiento?birthday=' + _birthday;
  this.crudService.getModel(path).subscribe(
    (genericResponse: GenericResponseModel) => {
      if (genericResponse.code === 200) {
        this.rows = genericResponse.answerList;
        setTimeout(() => {
          if (this.rows.length === 0) {
            this.close('accept');
          } 
        }, 100);
      } else {
        this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar los gestores y vendedores asociados a su empresa',
          'Problema consultando los  gestores y vendedores asociados a su empresa');
      }
    },
    error => {
      this.messageService.getInfoMessageError();
      console.error('Error al cargar los  gestores y vendedores asociados a su empresa ' + JSON.stringify(error))
    }
  );
 }


  getPolicyByClient(clientId: any) {
    var poliza= 'No aplica';
    this.polizas.forEach((element:any) => {
    
      if(element.clientId.id.toString() === clientId.toString()){
        
       poliza= element.policy.toString();
      }
    });
    return poliza;
  }
  getValidByClient(clientId: any) {
    var fecha= 'No aplica';

    this.polizas.forEach((element:any) => {
      
      if(element.clientId.id.toString() === clientId.toString()){
        fecha= element.validUntil.toString().split('T')[0];
      }
    });
    return fecha;
  }
  getClientsByBirthday(initialDate: string,finalDate) {
    const path = 'reportes/listar-clientes-por-fecha-nacimiento?fechaInicial=' + initialDate+"&fechaFinal="+finalDate;
    
    this.crudService.getModel(path).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
        genericResponse.answerList.forEach((element:any) => {
            this.listClients.push(element);
            this.rows.push(element)

        });          
        this.buscarPolizas();
        
        } else {
          // tslint:disable-next-line:max-line-length
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar los gestores y vendedores asociados a su empresa',
            'Problema consultando los  gestores y vendedores asociados a su empresa');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar los  gestores y vendedores asociados a su empresa ' + JSON.stringify(error))
      }
    );
  }
  buscarPolizas(){
    var ids=[];
    this.listClients.forEach((element:any) => {
      ids.push(element.id)
    });
    const path = 'poliza/buscar-polizas-clientsId?clientsId=' + ids;

    this.crudService.getModel(path).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
        genericResponse.answerList.forEach((element:any) => {
            this.polizas.push(element)
          
        });          
        console.log(this.rows);
        } else {
          // tslint:disable-next-line:max-line-length
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar los gestores y vendedores asociados a su empresa',
            'Problema consultando los  gestores y vendedores asociados a su empresa');
        }

      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar los  gestores y vendedores asociados a su empresa ' + JSON.stringify(error))
      }
    );
  }


 getManagersAndSellersByBetweenDates(_initialDate: any, _finalDate: any) {
    // Se separan los meses y días de la fecha de inicio
    const iMonth = _initialDate.split('-')[1];
    const iDay = _initialDate.split('-')[2];
    // Se separan los meses y días de la fecha final
    const fMonth = _finalDate.split('-')[1];
    const fDay = _finalDate.split('-')[2];
    // Si iMonth < fMonth && iDay < fDay, es la consulta normal
    if (fMonth >= iMonth && fDay > iDay) {
      const path = 'reportes/listar-usuarios-vendedores-gestores-entre-fechas?initialMonth=' + iMonth + '&finalMonth=' + fMonth +
      '&initialDay=' + iDay + '&finalDay=' + fDay;
      this.crudService.getModel(path).subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            genericResponse.answerList.forEach((c: any) => {
              this.rows.push(c);
            });
          } else {
            // tslint:disable-next-line:max-line-length
            this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar los gestores y vendedores asociados a su empresa',
              'Problema consultando los  gestores y vendedores asociados a su empresa');
          }
        },
        error => {
          this.messageService.getInfoMessageError();
          console.error('Error al cargar los  gestores y vendedores asociados a su empresa ' + JSON.stringify(error))
        }
      );
    } else if (fMonth < iMonth && fDay > iDay)  {
      setTimeout(() => {
        const path1 = 'reportes/listar-usuarios-vendedores-gestores-entre-fechas?initialMonth=' + iMonth + '&finalMonth=' + '12' +
        '&initialDay=' + iDay + '&finalDay=' + fDay;
        this.crudService.getModel(path1).subscribe(
          (genericResponse: GenericResponseModel) => {
            if (genericResponse.code === 200) {
              genericResponse.answerList.forEach((c: any) => {
                this.rows.push(c);
              });
            } else {
              // tslint:disable-next-line:max-line-length
              this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar los gestores y vendedores asociados a su empresa',
                'Problema consultando los  gestores y vendedores asociados a su empresa');
            }
          },
          error => {
            this.messageService.getInfoMessageError();
            console.error('Error al cargar los  gestores y vendedores asociados a su empresa ' + JSON.stringify(error))
          }
        );
      }, 200);
      setTimeout(() => {
        const path2 = 'reportes/listar-usuarios-vendedores-gestores-entre-fechas?initialMonth=' + '01' + '&finalMonth=' + fMonth +
        '&initialDay=' + iDay + '&finalDay=' + fDay;
        this.crudService.getModel(path2).subscribe(
          (genericResponse: GenericResponseModel) => {
            if (genericResponse.code === 200) {
              genericResponse.answerList.forEach((u: any) => {
                this.rows.push(u);
              });
            } else {
              // tslint:disable-next-line:max-line-length
              this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar los gestores y vendedores asociados a su empresa',
                'Problema consultando los  gestores y vendedores asociados a su empresa');
            }
          },
          error => {
            this.messageService.getInfoMessageError();
            console.error('Error al cargar los  gestores y vendedores asociados a su empresa ' + JSON.stringify(error))
          }
        );
      }, 200);
    }
  }

  getClientsByBetweenDates(_initialDate: any, _finalDate: any) {
    // this.rows = new Array();
     // Se separan los meses y días de la fecha de inicio
     const iMonth = _initialDate.split('-')[1];
     const iDay = _initialDate.split('-')[2];
     // Se separan los meses y días de la fecha final
     const fMonth = _finalDate.split('-')[1];
     const fDay = _finalDate.split('-')[2];
     // Si iMonth < fMonth && iDay < fDay, es la consulta normal
     if (fMonth >= iMonth && fDay > iDay) {
       const path = 'reportes/listar-clientes-entre-fechas?initialMonth=' + iMonth + '&finalMonth=' + fMonth +
       '&initialDay=' + iDay + '&finalDay=' + fDay;
       this.crudService.getModel(path).subscribe(
         (genericResponse: GenericResponseModel) => {
           if (genericResponse.code === 200) {
             // this.rows = new Array();
             genericResponse.answerList.forEach((c: any) => {
              console.log(c);
            });
           } else {
             // tslint:disable-next-line:max-line-length
             this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar los clientes asociados a su empresa',
               'Problema consultando los clientes asociados a su empresa');
           }
         },
         error => {
           this.messageService.getInfoMessageError();
           console.error('Error al cargar los  clientes asociados a su empresa ' + JSON.stringify(error))
         }
       );
     } else if (fMonth < iMonth && fDay > iDay)  {
       // this.rows = new Array();
       setTimeout(() => {
         const path1 = 'reportes/listar-clientes-entre-fechas?initialMonth=' + iMonth + '&finalMonth=' + '12' +
         '&initialDay=' + iDay + '&finalDay=' + fDay;
         this.crudService.getModel(path1).subscribe(
           (genericResponse: GenericResponseModel) => {
             if (genericResponse.code === 200) {
              genericResponse.answerList.forEach((c: any) => {
                console.log(c);
              });
               // console.log('Date Range: ' + iMonth + '-> ' + '12');
               // console.log(genericResponse.answerList);
             } else {
               // tslint:disable-next-line:max-line-length
               this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar los clientes asociados a su empresa',
                 'Problema consultando los  gestores y vendedores asociados a su empresa');
             }
           },
           error => {
             this.messageService.getInfoMessageError();
             console.error('Error al cargar los clientes asociados a su empresa ' + JSON.stringify(error))
           }
         );
       }, 200);
       setTimeout(() => {
         const path2 = 'reportes/listar-usuarios-vendedores-gestores-entre-fechas?initialMonth=' + '01' + '&finalMonth=' + fMonth +
         '&initialDay=' + iDay + '&finalDay=' + fDay;
         this.crudService.getModel(path2).subscribe(
           (genericResponse: GenericResponseModel) => {
             if (genericResponse.code === 200) {
               genericResponse.answerList.forEach((u: any) => {
                 this.rows.push(u);
                });
             } else {
               // tslint:disable-next-line:max-line-length
               this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar los clientes asociados a su empresa',
                 'Problema consultando los clientes asociados a su empresa');
             }
           },
           error => {
             this.messageService.getInfoMessageError();
             console.error('Error al cargar los clientes asociados a su empresa ' + JSON.stringify(error))
           }
         );
       }, 200);
     }
  }

  close(answer: string) {
    this.passEntry.emit(answer);
    this.activeModal.close();
  }
}
