import { PdfService } from './../../shared/pdf/pdf.service';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { CrudServiceService } from './../../shared/backend/cruds/crud-service.service';
import { CollectionModel } from './../../../models/entities/collection-model';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { InstallmentModel } from 'models/entities/installment-model';
import { PolicyModel } from 'models/entities/policy-model';
import { CompanyModel } from 'models/entities/company-model';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { RelationalFileModel } from 'models/utilities/relational-type-model';
import { Observable, Observer } from 'rxjs';
import { SharedService } from 'app/shared/shared.service';

import * as moment from "moment";
import * as jspdf from 'jspdf';
import 'jspdf-autotable'
import {date} from "ng2-validation/dist/date";

@Component({
  selector: 'app-pay-report',
  templateUrl: './pay-report.component.html',
  styleUrls: ['./pay-report.component.scss']
})
export class PayReportComponent implements OnInit {

  public relationalFileModel: RelationalFileModel = new RelationalFileModel();
  @Input() installmentModel: InstallmentModel = new InstallmentModel();
  @Input() policyModel: PolicyModel = new PolicyModel();
  justPrint = false;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() collectionModel: CollectionModel = new CollectionModel();
  companyModel: CompanyModel = new CompanyModel();
  @ViewChild('contentToConvert') contentDiv: ElementRef;
  @Input() isPrinting = false;
  logo: any = '';

  constructor(
    private crudService: CrudServiceService,
    public tokenStorage: TokenStorageService,
    private messageService: InfoMessagesService,
    private tokenStorageService: TokenStorageService,
    private pdfService: PdfService,
    private sharedService: SharedService
  ) {
    this.relationalFileModel.showTitle = false;
    this.relationalFileModel.maxNumberOfFiles = 1
    this.relationalFileModel.relationalId = this.installmentModel.id;
    this.relationalFileModel.relationalType = 'DOCUMENTS_BY_PAYMENT'
    this.relationalFileModel.relationalObjetive = 'Anexo'
    this.relationalFileModel.relationalFileType = 'Archivo';
    this.relationalFileModel.relationalRestrictions = new Array();

    setTimeout(() => {
      this.imageLoad();
    }, 300);
  }

  ngOnInit() {
    this.collectionModel.installmentId = this.installmentModel;
    this.collectionModel.paymentValue = this.installmentModel.total;
    this.collectionModel.paymentDate = new Date(Date.now() - (1000 * 60 * 60 * 5)).toISOString().slice(0, 10);
    this.getCompany();
    if (this.isPrinting) {
      this.getCollection();
    }
  }

  closeModal() {
    this.passEntry.emit(0);
  }

  getCollection() {
    this.crudService.getModel('collection/obtener-por-pago?installmentId=' + this.installmentModel.id).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.collectionModel = genericResponse.genericObject;
          this.collectionModel.paymentValue = this.installmentModel.total;
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudo obtener el recaudo con exito',
            'Problema obteniendo el recaudo');
        }
      }, (error) => {
        this.messageService.getInfoMessageError();
        console.error('Error, no se pudo obtener el recaudo con exito: ' + JSON.stringify(error))
      }
    )
  }

  onSubmit() {
    this.collectionModel.installmentId = this.installmentModel;
    this.collectionModel.creatorId.id = this.tokenStorageService.getId();
    this.collectionModel.createdDate = new Date();
    this.crudService.createModel('collection/create-or-update', this.collectionModel).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.updateInstallment();
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudo guardar el recaudo con exito',
            'Problema creando el recaudo');
        }
      }, (error) => {
        this.messageService.getInfoMessageError();
        console.error('Error, no se pudo guardar el recaudo con exito: ' + JSON.stringify(error))
      }
    )
  }

  updateInstallment() {
    this.crudService.putModelParams('cuotas/actualizar-cuota', {}, {
      paymentValue: this.collectionModel.paymentValue,
      installmentId: this.installmentModel.id
    }).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.isPrinting = true;
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudo actualizar la cuota',
            'Problema creando la cuota');
        }
      }, (error) => {
        this.messageService.getInfoMessageError();
        console.error('Error, no se pudo actualizar la cuota con exito: ' + JSON.stringify(error))
      }
    )
  }

  getCompany() {
    const pathCompanyUrl = 'empresa/compania/ver-compania?id=' + this.tokenStorage.getCompanyId();
    this.crudService.getModel(pathCompanyUrl).subscribe(
      data => {
        this.companyModel = data.genericObject;
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar la compañia: ' + error)
      }
    );
  }

  public captureScreen() {
    this.justPrint = true;
    setTimeout(() => {
      const dataToConvert = this.contentDiv.nativeElement;
      this.pdfService.captureScreen(dataToConvert, 295, 'landscape').then(
        () => {
          this.passEntry.emit(1);
        }
      )
    }, 2000);

  }

  imageLoad() {
    console.log(this.sharedService.getLogoUrlCompany())
    this.getBase64ImageFromURL(this.sharedService.getLogoUrlCompany()).subscribe(base64data => {
      // this is the image as dataUrl
      this.logo = 'data:image/jpg;base64,' + base64data;
    });
  }

  getBase64ImageFromURL(url: string) {

    return Observable.create((observer: Observer<string>) => {
      // create an image object
      let img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      if (!img.complete) {
        // This will call another method that will create image from url
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = (err) => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }


  getBase64Image(img: HTMLImageElement) {
    // We create a HTML canvas object that will create a 2d image

    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    // This will draw image    
    ctx.drawImage(img, 0, 0);
    // Convert the drawn image to Data URL
    var dataURL = canvas.toDataURL("image/png");
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }

  removeAccents(str) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  createPdf() {
    const company = this.companyModel;
    const doc = new jspdf('portrait', 'pt', 'letter');
    doc.setTextColor('black');
    doc.setFontSize(17);
    doc.setFontType('bold');

    const maxX = 592;
    const maxY = 772;
    let posY = 30;
    let posX = 20;

    // Crear header
    doc.setFontSize(14);
    doc.setFontType('bold');
    doc.text(posX, posY, company.name);
    doc.setFontSize(8);
    doc.setFontType('normal');
    doc.setTextColor('gray');
    const texts = [ company.documentNumber, company.address];
    if (company.phone) {
      texts.push(company.phone);
    }
    if (company.cellphone) {
      texts.push(company.cellphone);
    }
    texts.forEach((t, i) => {
      posY += 12;
      doc.text(posX, posY, t);
    });

    doc.addImage(this.logo, 'JPG', maxX - (posY - 30), 30, (posY - 30), (posY - 30));

    doc.setTextColor('black');
    posY += 20;
    doc.setFontSize(10);

    doc.setFillColor(210, 210, 210);
    doc.rect(posX, posY, maxX - posX, 45, 'FD');
    let lastRectY = posY + 45;

    doc.setFillColor('white');
    posY += 19;
    posX = 40;
    doc.setFontType('bold');
    doc.text('FECHA:', posX, posY);
    doc.setFontType('normal');
    posX += 45;
    doc.text(moment(this.collectionModel.createdDate).format('DD-MM-yyyy'), posX, posY);

    const policyStr = `PÓLIZA:  ${this.policyModel.policy}`;
    posX = maxX - ((policyStr.length + 5) * 5);
    doc.setFontType('bold');
    doc.text('PÓLIZA:', posX, posY);
    doc.setFontType('normal');
    posX += 45;
    doc.text(this.policyModel.policy, posX, posY);

    posY += 20;
    posX = 40;

    doc.setFontType('bold');
    doc.text('ASEGURADORA:', posX, posY);
    doc.setFontType('normal');
    posX += 90;
    doc.text(this.policyModel.insurerId.name.toUpperCase(), posX, posY);

    const coiStr = `RAMO:  ${this.policyModel.classOfInsuranceId.name.toUpperCase()}`;
    posX = maxX - ((coiStr.length + 6) * 5);
    doc.setFontType('bold');
    doc.text('RAMO:', posX, posY);
    doc.setFontType('normal');
    posX += 40;
    doc.text(this.policyModel.classOfInsuranceId.name.toUpperCase(), posX, posY);

    posX = 40;
    posY += 20;

    doc.setFontType('bold');
    doc.text('RECIBIDO POR:', posX, posY);
    doc.setFontType('normal');
    posX += 80;
    doc.text(this.tokenStorage.getCompleteName().toUpperCase(), posX, posY);

    const strValue = `VALOR:  ${Number(this.collectionModel.paymentValue).toLocaleString('es-ES')}`;
    posX = maxX - ((strValue.length + 5) * 5);
    doc.setFontType('bold');
    doc.text('VALOR:', posX, posY);
    doc.setFontType('normal');
    posX += 45;
    doc.text(Number(this.collectionModel.paymentValue).toLocaleString('es-ES'), posX, posY);

    posX = 40;
    posY += 20;

    doc.setFontType('bold');
    doc.text('CLIENTE:', posX, posY);
    doc.setFontType('normal');
    posX += 50;
    doc.text(this.policyModel.clientId.nameOrBusinessName.toUpperCase(), posX, posY);

    const docStr = `DOCUMENTO:  ${this.policyModel.clientId.documentNumber}`;
    posX = maxX - ((docStr.length + 6) * 5);
    doc.setFontType('bold');
    doc.text('DOCUMENTO:', posX, posY);
    doc.setFontType('normal');
    posX += 75;
    doc.text(this.policyModel.clientId.documentNumber, posX, posY);

    posX = 40;
    posY += 20;

    doc.setFontType('bold');
    doc.text('FORMA DE PAGO:', posX, posY);
    doc.setFontType('normal');
    posX += 95;
    doc.text(this.collectionModel.paymentMode.toUpperCase(), posX, posY);

    const dateStr = `FECHA DE PAGO:  ${moment(this.collectionModel.paymentDate).format('DD-MM-yyyy')}`;
    posX = maxX - ((dateStr.length + 6) * 5);
    doc.setFontType('bold');
    doc.text('FECHA DE PAGO:', posX, posY);
    doc.setFontType('normal');
    posX += 95;
    doc.text(moment(this.collectionModel.paymentDate).format('DD-MM-yyyy'), posX, posY);

    posX = 40;
    posY += 20;

    doc.setFontType('bold');
    doc.text('CONCEPTO:', posX, posY);
    doc.setFontType('normal');
    posX += 65;
    doc.text(this.collectionModel.concept.toUpperCase(), posX, posY);

    posX = 40;
    posY += 20;
    if (this.collectionModel.paymentMode === 'Cheque') {
      doc.setFontType('bold');
      doc.text('BANCO:', posX, posY);
      doc.setFontType('normal');
      posX += 45;
      doc.text(this.collectionModel.bank.toUpperCase(), posX, posY);

      posX = 40;
      posY += 20;

      doc.setFontType('bold');
      doc.text('Nº. CHEQUE:', posX, posY);
      doc.setFontType('normal');
      posX += 70;
      doc.text(this.collectionModel.checkNumber.toUpperCase(), posX, posY);

      const accountStr = `CUENTA DE BANCO:  ${this.collectionModel.accountNumber.toUpperCase()}`;
      posX = maxX - ((accountStr.length + 6) * 5);
      doc.setFontType('bold');
      doc.text('CUENTA DE BANCO:', posX, posY);
      doc.setFontType('normal');
      posX += 107;
      doc.text(this.collectionModel.accountNumber.toUpperCase(), posX, posY);
    } else if (this.collectionModel.paymentMode === 'Financiera') {
      doc.setFontType('bold');
      doc.text('FINANCIERA:', posX, posY);
      doc.setFontType('normal');
      posX += 70;
      doc.text(this.collectionModel.financial.toUpperCase(), posX, posY);
    }

    posX = 20;
    doc.rect(posX, lastRectY, maxX - posX, (posY + 10) - lastRectY);
    const filename = `payment-report.pdf`;
    doc.save(filename);
  }
}
