import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { RelationalFileModel } from 'models/utilities/relational-type-model';
import { PolicyModel } from 'models/entities/policy-model';
import { ListFilesComponent } from 'app/shared/components/list-files/list-files.component';
import { AdminCruListRisksComponent } from '../admin-cru-list-risks/admin-cru-list-risks.component';
import { ActivatedRoute, Router } from '@angular/router';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BudgetModel } from 'models/entities/budget-model';
import { CruAttachedComponent } from '../cru-attached/cru-attached.component';
import { AttachedInfoModel } from 'models/entities/attached-info-model';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-admin-principal-ru-policies',
  templateUrl: './admin-principal-ru-policies.component.html',
  styleUrls: ['./admin-principal-ru-policies.component.scss']
})
export class AdminPrincipalRuPoliciesComponent implements OnInit {

  public relationalModel: RelationalFileModel = new RelationalFileModel();
  @Input() public policyModel: PolicyModel = new PolicyModel();
  public policyModelToSend: PolicyModel = new PolicyModel();
  @Input() public originalPolicyModel: PolicyModel = new PolicyModel();
  @Input() public budgetModel: BudgetModel = new BudgetModel();
  @Input() public originalBudgetModel: BudgetModel = new BudgetModel();
  @ViewChild(ListFilesComponent) listFileComponente: ListFilesComponent;
  @ViewChild('listRisks') adminCruListRisksInstancie: AdminCruListRisksComponent;
  @Input() public initialPolicyModel: PolicyModel = new PolicyModel();
  @Output() public passEntry: EventEmitter<any> = new EventEmitter();
  changesList = new Array();
  changesListRisk = new Array();
  changesListBeneficiary = new Array();
  public policyWasUpdated = false;

  isSeller = false;

  @Input() isTempClient;

  constructor(
    private activeModal: NgbActiveModal,
    private messageService: InfoMessagesService,
    private modalService: NgbModal,
    private crudService: CrudServiceService,
    private tokenStorageService: TokenStorageService
  ) {
  }

  ngOnInit() {
    if (this.tokenStorageService.getAuthorities() === 'ROLE_SELLER' || this.isTempClient || this.policyModel.state === 'DELETED') {
      this.isSeller = true;
    }
    this.relationalModel.relationalId = this.policyModel.id;
    this.relationalModel.relationalType = 'DOCUMENTS_POLICY_FILE'
    this.relationalModel.relationalFileType = 'ADJUNTO';
    this.relationalModel.relationalObjetive = 'adjunto para póliza';
    this.relationalModel.relationalRestrictions = new Array();
    this.saveInitialPolicyModel()
    this.policyModelToSend = { ...this.policyModel }
  }

  saveInitialPolicyModel() {
    this.initialPolicyModel = { ...this.policyModel };
  }

  closeModal() {
    this.passEntry.emit(null);
    this.activeModal.dismiss();
  }

  reportOfChangesRisk($event) {
    this.changesListRisk = $event;
  }

  reportOfChangesBeneficiary($event) {
    this.changesListBeneficiary = $event;
  }

  getChanges(fromClose) {
    if (this.policyWasUpdated) {
      if (this.policyModel.clientId.id !== this.initialPolicyModel.clientId.id) {
        this.changesList.push('El cliente cambio: nuevo cliente: ' + this.policyModel.clientId.nameOrBusinessName);
      }
      if (this.policyModel.insuredId.id !== this.initialPolicyModel.insuredId.id) {
        this.changesList.push('El asegurado cambio: nuevo cliente: ' + this.policyModel.insuredId.nameOrBusinessName);
      }
      if (this.policyModel.insurerId.id !== this.initialPolicyModel.insurerId.id) {
        this.changesList.push('La aseguradora cambio: nuevo cliente: ' + this.policyModel.insurerId.name);
      }
      if (this.policyModel.policy !== this.initialPolicyModel.policy) {
        this.changesList.push('EL número de póliza cambió, nuevo número: ' + this.policyModel.policy);
      }
      if (this.initialPolicyModel.sellerUserId != null && this.policyModel.sellerUserId.id !== this.initialPolicyModel.sellerUserId.id) {
        this.changesList.push('El vendedor cambio, nuevo vendedor: ' + this.policyModel.sellerUserId.completeName);
      }
      if (this.policyModel.validSince !== this.initialPolicyModel.validSince) {
        this.changesList.push('La fecha de inicio de vigencia para la póliza cambio, nueva fecha: ' + this.policyModel.validSince);
      }
      if (this.policyModel.validUntil !== this.initialPolicyModel.validUntil) {
        this.changesList.push('La fecha de finalización de vigencia para la póliza cambio, nueva fecha: ' + this.policyModel.validUntil);
      }
      if (this.policyModel.expeditionDate !== this.initialPolicyModel.expeditionDate) {
        this.changesList.push('La fecha de expedición de la póliza cambio, nueva fecha: ' + this.policyModel.expeditionDate);
      }
    }
    if (this.changesListRisk.length > 0) {
      this.changesListRisk.forEach(riskChange => {
        this.changesList.push(riskChange);
      });
    }
    if (this.changesListBeneficiary.length > 0) {
      this.changesListBeneficiary.forEach(beneficiaryChange => {
        this.changesList.push(beneficiaryChange);
      });
    }
    if (fromClose) {
      if (this.changesList.length > 0) {
        swal({
          title: '¿Desea generar un anexó con los cambios realacionados?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sí, Generar',
          cancelButtonText: 'No, Salir Y Guardar'
        }).then(swalResult => {
          if (swalResult.value) {
            this.generateAttached();
          } else {
            this.closeModal();
          }
        })
      } else {
        this.closeModal();
      }
    } else {
      this.generateAttached();
    }
  }

  cancelPolicy() {
    swal({
      title: '¿Está seguro que desea cancelar la póliza?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, cancelar!',
      cancelButtonText: 'Volver'
    }).then((result) => {
      if (result.value) {
        this.policyModel.state = 'DELETED';
        this.crudService.createModel('poliza/crear-poliza', this.policyModel).subscribe(
          (genericResponse: GenericResponseModel) => {
            if (genericResponse.code === 200) {
              this.policyModel = genericResponse.genericObject;
              this.goToList();
            } else {
              this.messageService.getInfoMessagePersonalized('warning', 'No se pudo eliminar la póliza con exito',
                'Problema eliminando la póliza');
            }
          }, (error) => {
            this.messageService.getInfoMessageError();
            console.error('Error, no se pudo eliminar la póliza con exito: ' + JSON.stringify(error))
          }
        )
      }
    })
  }

  renovatorPolicy() {
    swal({
      title: '¿Está seguro que desea renovar la póliza?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, renovar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        let newPolicy: PolicyModel = new PolicyModel();
        newPolicy.companyId = this.policyModel.companyId;
        newPolicy.classOfInsuranceId = this.policyModel.classOfInsuranceId;
        newPolicy.clientId = this.policyModel.clientId;
        newPolicy.createdDate = new Date().toISOString();
        newPolicy.creatorId.id = this.tokenStorageService.getId();
        newPolicy.expeditionDate = new Date().toISOString();
        newPolicy.insuredId = this.policyModel.insuredId;
        newPolicy.insurerId = this.policyModel.insurerId;
        newPolicy.policy = this.policyModel.policy;
        newPolicy.sellerAndInsuredEquals = this.policyModel.sellerAndInsuredEquals;
        newPolicy.sellerUserId = this.policyModel.sellerUserId;
        newPolicy.state = 'CURRENT';
        newPolicy.validSince = new Date(this.policyModel.validUntil+ "T00:00:00Z");
        newPolicy.validSince.setMinutes(newPolicy.validSince.getMinutes() + newPolicy.validSince.getTimezoneOffset())
        
        console.log( newPolicy.validSince);
        const today = newPolicy.validSince.getFullYear() + '-'
          + this.validateCero(newPolicy.validSince.getMonth()) + '-' + this.validateCero(newPolicy.validSince.getDate());
        newPolicy.validUntil = this.getOneYearMore(today);
        newPolicy.policyMotherId = { id: this.policyModel.id };
        this.getUrlRiskToCopy(newPolicy.classOfInsuranceId.id).then(
          (urlsResult) => {
            this.crudService.createModel('poliza/crear-poliza', newPolicy).subscribe(
                (createPolicyResponse: GenericResponseModel) => {
                  if (createPolicyResponse.code === 200) {
                    newPolicy = createPolicyResponse.genericObject;
                    this.saveRiskInRenovatePolicy(newPolicy, urlsResult);
                    console.log(newPolicy.id);
                    this.renovateBeneficiary(this.policyModel.id,newPolicy.id);
                  } else {
                    this.messageService.getInfoMessagePersonalized('warning', 'No se pudo renovar la póliza con exito',
                        'Problema renovando la póliza');
                  }
                }, (error) => {
                  this.messageService.getInfoMessageError();
                  console.error('Error, no se pudo renovar la póliza con exito: ' + JSON.stringify(error))
                }
            )
          }
        ).catch(
          (error) => {
            console.error('Error en el consultar url: ' + error);
          }
        )
      }
    })
  }


  renovateBeneficiary(policyIdMother, policyIdRenovated) {
    this.crudService.getModel('beneficiario/renovar-beneficiarios-por-poliza?newPolicy=' +policyIdRenovated +'&oldPolicy='+ policyIdMother).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          console.log('Beneficiarios actualizados');
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudo renovar los beneficiarios con exito',
              'Problema renovando los beneficiarios de la póliza');
        }
      }, (error) => {
        console.error('Error en el consultar url: ' + error);
      }
    )

  }
  async saveRiskInRenovatePolicy(newPolicyId, urls) {
    await this.crudService.getModel(urls[0]).subscribe(
      async (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          const riskList: Array<any> = await genericResponse.answerList;

          await riskList.forEach(async risk => {
            risk.policyId = await { id: newPolicyId.id }
            risk.id = null;
          });

          this.crudService.createModel(urls[1], riskList).subscribe(
            (genericRiskResponse: GenericResponseModel) => {
              if (genericRiskResponse.code === 200) {
                swal({
                  title: 'Se ha renovado su póliza, ¿Desea generar el anexo de creación?',
                  type: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: '¡Sí, ver!',
                  cancelButtonText: 'No, ir a polizas'
                }).then((result) => {
                  if (result.value) {
                    this.activeModal.close();
                    this.generateRenovatedAttached(newPolicyId);
                  } else {
                    this.goToList();
                  }
                });
              } else {
                this.messageService.getInfoMessagePersonalized('warning', 'No se pudo crear los riesgos exito',
                  'Problema creando riesgos de la póliza renovada');
              }
            }, (error) => {
              this.messageService.getInfoMessageError();
              console.error('Error, no se pudo crear los riesgos de la póliza renovada: ' + JSON.stringify(error))
            }
          )

        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudo listar los riesgos con exito',
            'Problema listando riesgos de la póliza');
        }
      }, (error) => {
        this.messageService.getInfoMessageError();
        console.error('Error, no se pudo listar los riesgos de la póliza con exito: ' + JSON.stringify(error))
      }
    )
  }

  generateRenovatedAttached(policyModel: PolicyModel) {
    const modal = this.modalService.open(CruAttachedComponent, {
      windowClass: 'mediumModal', size: 'lg', backdrop: 'static'
    });
    const attachedModelToSend: AttachedInfoModel = new AttachedInfoModel();
    attachedModelToSend.valuable = true;
    attachedModelToSend.type = 'CREATE';
    attachedModelToSend.policyId = policyModel;
    modal.componentInstance.attachedModel = attachedModelToSend;
    modal.componentInstance.budgetModel = new BudgetModel();
    modal.componentInstance.policyModel = policyModel;
    modal.componentInstance.fromList = true;
    modal.componentInstance.passEntry.subscribe(result => {
      modal.dismiss();
      this.goToList();
    });
  }

  getUrlRiskToCopy(classOfInsuranceId): Promise<any> {
    return new Promise((resolve, reject) => {
      switch (classOfInsuranceId) {
        case 1: {
          resolve(new Array('riesgo-salud/listar-riesgos-salud-por-poliza?policyId=' + this.policyModelToSend.id,
            'riesgo-salud/crear-riesgo-salud-masivo'));
          break;
        }
        case 2: {
          resolve(new Array('riesgo-vehiculo/listar-riesgos-vehiculo-por-poliza?policyId=' + this.policyModelToSend.id,
            'riesgo-vehiculo/crear-riesgo-vehiculo-masivo'));
          break;
        }
        case 3: {
          resolve(new Array('riesgo-copropiedad/listar-riesgos-copropiedad-por-poliza?policyId=' + this.policyModelToSend.id,
            'riesgo-copropiedad/crear-riesgo-copropiedad-masivo'));
          break;
        }
        case 4: {
          resolve(new Array('riesgo-rc-profesional/listar-riesgos-rc-profesional-por-poliza?policyId=' + this.policyModelToSend.id,
            'riesgo-rc-profesional/crear-riesgo-rc-profesional-masivo'));
          break;
        }
        case 5: {
          resolve(new Array('riesgo-pyme/listar-riesgos-pyme-por-poliza?policyId=' + this.policyModelToSend.id,
            'riesgo-pyme/crear-riesgo-pyme-masivo'));
          break;
        }
        case 6: {
          resolve(new Array('riesgo-vida/listar-riesgos-vida-por-poliza?policyId=' + this.policyModelToSend.id,
            'riesgo-vida/crear-riesgo-vida-masivo'));
          break;
        }
        case 7: {
          resolve(new Array('riesgo-accidente-personal/listar-riesgos-accidente-personal-por-poliza?policyId='
            + this.policyModelToSend.id, 'riesgo-accidente-personal/crear-riesgo-accidente-personal-masivo'));
          break;
        }
        case 8: {
          resolve(new Array('riesgo-cumplimiento/listar-riesgos-cumplimiento-por-poliza?policyId=' + this.policyModelToSend.id,
            'riesgo-cumplimiento/crear-riesgo-cumplimiento-masivo'));
          break;
        }
        case 9: {
          resolve(new Array('riesgo-casa/listar-riesgos-casa-por-poliza?policyId=' + this.policyModelToSend.id,
            'riesgo-casa/crear-riesgo-casa-masivo'));
          break;
        }
        case 10: {
          resolve(new Array('riesgo-capitalizacion/listar-riesgos-capitalizacion-por-poliza?policyId=' + this.policyModelToSend.id,
            'riesgo-capitalizacion/crear-riesgo-capitalizacion-masivo'));
          break;
        }
        case 11: {
          resolve(new Array('riesgo-soat/listar-riesgos-soat-por-poliza?policyId=' + this.policyModelToSend.id,
            'riesgo-soat/crear-riesgo-soat-masivo'));
          break;
        }
        case 12: {
          resolve(new Array('riesgo-rce-vehiculo/listar-riesgos-rce-vehiculo-por-poliza?policyId=' + this.policyModelToSend.id,
            'riesgo-rce-vehiculo/crear-riesgo-rce-vehiculo-masivo'));
          break;
        }
        case 13: {
          resolve(new Array('riesgo-rcc-vehiculo/listar-riesgos-rcc-vehiculo-por-poliza?policyId=' + this.policyModelToSend.id,
            'riesgo-rcc-vehiculo/crear-riesgo-rcc-vehiculo-masivo'));
          break;
        }
        case 14: {
          resolve(new Array('riesgo-rotura-maquinaria/listar-riesgos-rotura-maquinaria-por-poliza?policyId='
            + this.policyModelToSend.id, 'riesgo-rotura-maquinaria/crear-riesgo-rotura-maquinaria-masivo'));
          break;
        }
        case 15: {
          resolve(new Array('riesgo-multi/listar-riesgos-multi-por-poliza?policyId=' + this.policyModelToSend.id,
            'riesgo-multi/crear-riesgo-multi-masivo'));
          break;
        }
        case 16: {
          resolve(new Array('riesgo-rc-cyber/listar-riesgos-rc-cyber-por-poliza?policyId=' + this.policyModelToSend.id,
            'riesgo-rc-cyber/crear-riesgo-rc-cyber-masivo'));
          break;
        }
        case 17: {
          resolve(new Array('riesgo-transporte-maritimo/listar-riesgos-transporte-maritimo-por-poliza?policyId='
            + this.policyModelToSend.id, 'riesgo-transporte-maritimo/crear-riesgo-transporte-maritimo-masivo'));
          break;
        }
        case 18: {
          resolve(new Array('riesgo-todo-riesgo-construccion/listar-riesgos-todo-riesgo-construccion-por-poliza?policyId=' +
            this.policyModelToSend.id, 'riesgo-todo-riesgo-construccion/crear-riesgo-todo-riesgo-construccion-masivo'));
          break;
        }
        case 19: {
          resolve(new Array('riesgo-responsabilidad-civil-contractual-pasajero/listar-riesgos-responsabilidad-civil-contractual-pasajero-por-poliza?policyId=' +
            this.policyModelToSend.id, 'riesgo-responsabilidad-civil-contractual-pasajero/crear-riesgo-responsabilidad-civil-contractual-pasajero-masivo'));
          break;
        }
        case 20: {
          resolve(new Array('riesgo-responsabilidad-civil-extracontractual-pasajero/listar-riesgos-responsabilidad-civil-extracontractual-pasajero-por-poliza?policyId=' +
            this.policyModelToSend.id, 'riesgo-responsabilidad-civil-extracontractual-pasajero/crear-riesgo-responsabilidad-civil-extracontractual-pasajero-masivo'));
          break;
        }
        case 21: {
          resolve(new Array('riesgo-responsabilidad-civil-contractual-carga/listar-riesgos-responsabilidad-civil-contractual-carga-por-poliza?policyId=' +
            this.policyModelToSend.id, 'riesgo-responsabilidad-civil-contractual-carga/crear-riesgo-responsabilidad-civil-contractual-carga-masivo'));
          break;
        }
        case 22: {
          resolve(new Array('riesgo-responsabilidad-civil-extracontractual-carga/listar-riesgos-responsabilidad-civil-extracontractual-carga-por-poliza?policyId=' +
            this.policyModelToSend.id, 'riesgo-responsabilidad-civil-extracontractual-carga/crear-riesgo-responsabilidad-civil-extracontractual-carga-masivo'));
          break;
        }
        case 23: {
          resolve(new Array('riesgo-responsabilidad-civil-hidrocarburos-carga/listar-riesgos-responsabilidad-civil-hidrocarburos-carga-por-poliza?policyId=' +
            this.policyModelToSend.id, 'riesgo-responsabilidad-civil-hidrocarburos-carga/crear-riesgo-responsabilidad-civil-hidrocarburos-carga-masivo'));
          break;
        }
        case 24: {
          resolve(new Array('riesgo-vida-deudores/listar-riesgos-vida-deudores-por-poliza?policyId=' +
            this.policyModelToSend.id, 'riesgo-vida-deudores/crear-riesgo-vida-deudores-masivo'));
          break;
        }
        case 25: {
          resolve(new Array('riesgo-plan-complementario/listar-riesgos-plan-complementario-por-poliza?policyId=' +
            this.policyModelToSend.id, 'riesgo-plan-complementario/crear-riesgo-plan-complementario-masivo'));
          break;
        }
        case 26: {
          resolve(new Array('riesgo-rc-cumplimiento/listar-riesgos-rc-cumplimiento-por-poliza?policyId=' +
            this.policyModelToSend.id, 'riesgo-rc-cumplimiento/crear-riesgo-rc-cumplimiento-masivo'));
          break;
        }
        case 27: {
          resolve(new Array('riesgo-aviacion/listar-riesgos-aviacion-por-poliza?policyId=' +
            this.policyModelToSend.id, 'riesgo-aviacion/crear-riesgo-aviacion-masivo'));
          break;
        }
        case 28: {
          resolve(new Array('riesgo-exequial/listar-riesgos-exequial-por-poliza?policyId=' +
            this.policyModelToSend.id, 'riesgo-exequial/crear-riesgo-exequial-masivo'));
          break;
        }
        case 29: {
          resolve(new Array('riesgo-agricola-cultivos/listar-riesgos-agricola-cultivos-por-poliza?policyId=' +
            this.policyModelToSend.id, 'riesgo-agricola-cultivos/crear-riesgo-agricola-cultivos-masivo'));
          break;
        }
        case 30: {
          resolve(new Array('riesgo-arl/listar-riesgos-arl-por-poliza?policyId=' +
            this.policyModelToSend.id, 'riesgo-arl/crear-riesgo-arl-masivo'));
          break;
        }
        case 31: {
          resolve(new Array('riesgo-arrendamiento/listar-riesgos-arrendamiento-por-poliza?policyId=' +
            this.policyModelToSend.id, 'riesgo-arrendamiento/crear-riesgo-arrendamiento-masivo'));
          break;
        }
        case 32: {
          resolve(new Array('riesgo-casco-embarcacion/listar-riesgos-casco-embarcacion-por-poliza?policyId=' +
            this.policyModelToSend.id, 'riesgo-casco-embarcacion/crear-riesgo-casco-embarcacion-masivo'));
          break;
        }
        case 33: {
          resolve(new Array('riesgo-directores-administradores/listar-riesgos-directores-administradores-por-poliza?policyId=' +
            this.policyModelToSend.id, 'riesgo-directores-administradores/crear-riesgo-directores-administradores-masivo'));
          break;
        }
        case 34: {
          resolve(new Array('riesgo-infidelidad-riesgos-financieros/listar-riesgos-infidelidad-riesgos-financieros-por-poliza?policyId=' +
            this.policyModelToSend.id, 'riesgo-infidelidad-riesgos-financieros/crear-riesgo-infidelidad-riesgos-financieros-masivo'));
          break;
        }
        case 35: {
          resolve(new Array('riesgo-maquinaria-equipo/listar-riesgos-maquinaria-equipo-por-poliza?policyId=' +
            this.policyModelToSend.id, 'riesgo-maquinaria-equipo/crear-riesgo-maquinaria-equipo-masivo'));
          break;
        }
        case 36: {
          resolve(new Array('riesgo-rc-clinica-hospitales/listar-riesgos-rc-clinica-hospitales-por-poliza?policyId=' +
            this.policyModelToSend.id, 'riesgo-rc-clinica-hospitales/crear-riesgo-rc-clinica-hospitales-masivo'));
          break;
        }
        case 37: {
          resolve(new Array('riesgo-rc-parqueadero-talleres/listar-riesgos-rc-parqueadero-talleres-por-poliza?policyId=' +
            this.policyModelToSend.id, 'riesgo-rc-parqueadero-talleres/crear-riesgo-rc-parqueadero-talleres-masivo'));
          break;
        }
        case 38: {
          resolve(new Array('riesgo-responsabilidad-civil-extracontractual/listar-riesgos-responsabilidad-civil-extracontractual-por-poliza?policyId=' +
            this.policyModelToSend.id, 'riesgo-responsabilidad-civil-extracontractual/crear-riesgo-responsabilidad-civil-extracontractual-masivo'));
          break;
        }
        case 39: {
          resolve(new Array('riesgo-transporte-valores/listar-riesgos-transporte-valores-por-poliza?policyId=' +
            this.policyModelToSend.id, 'riesgo-transporte-valores/crear-riesgo-transporte-valores-masivo'));
          break;
        }
        case 40: {
          resolve(new Array('riesgo-transporte-mercancia/listar-riesgos-transporte-mercancia-por-poliza?policyId=' +
            this.policyModelToSend.id, 'riesgo-transporte-mercancia/crear-riesgo-transporte-mercancia-masivo'));
          break;
        }
        case 41: {
          resolve(new Array('riesgo-manejo-global-comercial/listar-riesgos-manejo-global-comercial-por-poliza?policyId=' +
            this.policyModelToSend.id, 'riesgo-manejo-global-comercial/crear-riesgo-manejo-global-comercial-masivo'));
          break;
        }
      }
    })
  }

  getOneYearMore(date): any {
    const selectedExpeditionDate = date.split('-');
    const momentaryDate = new Date(Number(selectedExpeditionDate[0]) + 1,
      selectedExpeditionDate[1], selectedExpeditionDate[2]);
    return momentaryDate.toISOString();
  }

  validateCero(toValid) {
    if (toValid <= 9) {
      return '0' + toValid
    } else {
      return toValid
    }
  }

  infoCruEntry(infoCruEntry: any) {
    this.policyWasUpdated = true;
    this.policyModel = infoCruEntry.policyModel;
    this.budgetModel = infoCruEntry.budgetModel;
    this.messageService.getInfoMessageUpdate().then(
      (result) => {
        if (this.initialPolicyModel.state === 'DRAFT' && this.policyModel.state === 'CURRENT') {
          this.generateAttached();
        }
      }
    )
  }

  generateAttached() {
    const modal = this.modalService.open(CruAttachedComponent, {
      windowClass: 'mediumModal', size: 'lg', backdrop: 'static'
    });
    const attachedModelToSend: AttachedInfoModel = new AttachedInfoModel();
    attachedModelToSend.valuable = true;
    attachedModelToSend.type = 'UPDATE';
    attachedModelToSend.policyId = this.policyModel;
    modal.componentInstance.attachedModel = attachedModelToSend;
    modal.componentInstance.budgetModel = this.budgetModel;
    modal.componentInstance.policyModel = this.policyModel;
    modal.componentInstance.changesList = this.changesList;
    modal.componentInstance.passEntry.subscribe(result => {
      if (result !== null) {
        modal.dismiss();
        this.goToList();
      } else {
        modal.dismiss();
        this.changesList = new Array();
      }
    });
  }

  goToList() {
    this.passEntry.emit(1);
  }

}
