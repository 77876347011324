import { ExportExcelService } from './../../shared/excel/export-excel.service';
import { ClientModel } from 'models/entities/client-model';
import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { ActivityModel } from 'models/entities/activity';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { SearchClientModel } from 'models/types/search-client-model';
import {Observable, Subject, Observer, Subscription} from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, merge } from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { ELEMENT_MARKER } from '@angular/core/src/render3/interfaces/i18n';
import { PdfService } from 'app/shared/pdf/pdf.service';
import { SharedService } from 'app/shared/shared.service';
import { UserModel } from 'models/entities/user-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateActivityComponent } from '../create-activity/create-activity.component';
import {ActivitiesService} from "../activities-service/activities.service";
import {PageDataModel} from "../../../models/utilities/page-data-model";



@Component({
  selector: 'app-managment-activity',
  templateUrl: './managment-activity.component.html',
  styleUrls: ['./managment-activity.component.scss']
})
export class ManagmentActivityComponent implements OnInit {

  public canShowLoading = false;
  public listActivities: Array<ActivityModel> = new Array();
  public client=new ClientModel();
  public filter = {
      startDate: null,
      finalDate: null,
      responsable: new UserModel(),
      status: '',
      client: '',
      companyId: this.tokenServiceCtrl.getCompanyId()
  };
  public listTypes: any [];
  public listStates = ['Asignada', 'Finalizada'];
  public listUsers: any []; 
  dataLoad=false;
  listSeacrhClient: any;
  listClients:any;
  temp: any;
  justRead = false;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @ViewChild('instanceClient') instanceClient: NgbTypeahead;
  modelSearchClient: SearchClientModel = new SearchClientModel();
  focusClient$ = new Subject<string>();
  clickClient$ = new Subject<string>();
  focusClientIn$ = new Subject<string>();
  clickClientIn$ = new Subject<string>();
  logo:any
  @ViewChild('contentToConvert') contentDiv: ElementRef;
  focusUser$ = new Subject<string>();
  clickUser$ = new Subject<string>();
  focusUserIn$ = new Subject<string>();
  @ViewChild('instanceUser') instanceUser: NgbTypeahead;
  print:boolean=false;
  searchType = 'seq';
  sequence: number = undefined;
  lastSearch: any = {
      type: '',
      filter: null
  };

  listSubscription: Subscription;
  pageSubscription: Subscription;
  page = new PageDataModel();

  constructor(
    private messageCtrl:InfoMessagesService,
    private serviceCtrl:CrudServiceService,
    private tokenServiceCtrl:TokenStorageService,
    private excelService: ExportExcelService,
    private modalService:NgbModal,
    private pdfService: PdfService,
    private sharedService:SharedService,
    private activitiesService: ActivitiesService
  ) {
      this.page.pageNumber = 0;
      this.page.size = 10;
  }

  ngOnInit() {
    this.filter.startDate = null;
    this.filter.finalDate = null;
    this.getActivities(0);
    this.getUsers();
    this.getClients();

    setTimeout(() => {
      this.imageLoad();
    }, 3000);
    this.pageSubscription = this.activitiesService.getActivitiesPage().subscribe((pageInfo: any) => {
        this.setPage(pageInfo);
    });
    this.listSubscription = this.activitiesService.getActivitiesListStatus().subscribe(() => {
        this.searchAfterEvent();
    });
  }

  ngOnDestroy() {
      if (this.listSubscription) {
          this.listSubscription.unsubscribe();
      }
      if (this.pageSubscription) {
          this.pageSubscription.unsubscribe();
      }
  }

  imageLoad(){
    this.getBase64ImageFromURL(this.sharedService.getLogoUrlCompany()).subscribe(base64data => {     
      // this is the image as dataUrl
     this.logo = 'data:image/jpg;base64,' + base64data;
    });
  }

  formatterUser = (object: { completeName: string }) => object.completeName

  searchByNameUser = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    merge(this.focusUserIn$),
    merge(this.clickUser$.pipe(filter(() => !this.instanceUser.isPopupOpen()))),
    map(search => (search === '' ? this.listUsers
      : this.listUsers.filter(user => user.completeName.toLowerCase().indexOf(search.toLowerCase()) > -1)).slice(0, 10))
  );

  getBase64ImageFromURL(url: string) {
    return Observable.create((observer: Observer<string>) => {
      // create an image object
      let img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      if (!img.complete) {
          // This will call another method that will create image from url
          img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = (err) => {
           observer.error(err);
        };
      } else {
          observer.next(this.getBase64Image(img));
          observer.complete();
      }
    });
 }


 getBase64Image(img: HTMLImageElement) {
  // We create a HTML canvas object that will create a 2d image

  var canvas = document.createElement("canvas");
  canvas.width = img.width;
  canvas.height = img.height;
  var ctx = canvas.getContext("2d");
  // This will draw image    
  ctx.drawImage(img, 0, 0);
  // Convert the drawn image to Data URL
  var dataURL = canvas.toDataURL("image/png");
return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
}


  captureScreen() {
    this.justRead = true;
    setTimeout(() => {
      const dataToConvert = this.contentDiv.nativeElement;
      this.pdfService.captureScreen(dataToConvert, 295, 'landscape').then(
        () => {
          this.passEntry.emit(1);
        }
      )
    }, 2000);
  }

    exportExcel(){
      this.excelService.exportAsExcelFile(this.listActivities,'Actividades' + this.tokenServiceCtrl.getCompanyName());
    }

    getClients() {
      let pathListClientsUrl = 'cliente/ver-por-empresa';
      if (this.tokenServiceCtrl.getAuthorities() === 'ROLE_SELLER') {
          pathListClientsUrl = 'cliente/ver-por-vendedor';
      }
      this.listClients = new Array();
      this.serviceCtrl.getModel(pathListClientsUrl).subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            const listClientsAux = genericResponse.answerList;
            listClientsAux.forEach(client => {
              this.listClients.push(client);
            });
          } else {
            this.messageCtrl.getInfoMessagePersonalized('warning', 'No se pudieron listar los clientes asociados a su empresa',
              'Problema consultando los clientes asociados a su empresa');
          }
        },
        error => {
          this.messageCtrl.getInfoMessageError();
          console.error('Error al cargar los clientes asociados a su empresa ' + JSON.stringify(error))
        }
      );
    }

    formatterClient = (object: { nameOrBusinessName: string }) => object.nameOrBusinessName;

    searchByNameClient = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focusClientIn$),
      merge(this.clickClient$.pipe(filter(() => !this.instanceClient.isPopupOpen()))),
      map(search => (search === '' ? this.listClients
        : this.listClients.filter(client => client.nameOrBusinessName.toLowerCase().indexOf(search.toLowerCase()) > -1)).slice(0, 10))
    );

    getUsers(){
      let path='usuarios/listar-vendedores-y-gestores';
      this.serviceCtrl.getModel(path).subscribe(
        result => {
          if(result.code===200) {
             this.listUsers=result.answerList;
          }
        },
        error=>{
         this.messageCtrl.getInfoMessageDelete();
        }
      )
    }

  newActivity() {
    const modal = this.modalService.open(CreateActivityComponent, {
      windowClass: '',
      size: 'lg',
      backdrop: 'static'
    });
    modal.componentInstance.isModal = true;
    modal.componentInstance.passEntry.subscribe(result => {
        if(result === 'close') {
            this.activitiesService.setActivitiesListStatus();
            modal.dismiss();
        }
    });
  }

  filterActivities(page=0){
    if (this.searchType === 'seq') {
        const path = 'actividad/actividades-por-consecutivo?activitySeq=' + this.sequence + '&page=' + page;
        this.serviceCtrl.getModel(path).toPromise().then((result: GenericResponseModel) => {
            if(result.code === 200) {
                this.listActivities = result.answerList;
                this.listActivities.forEach(element => {
                    if(element.relationalEntityType === 'CLIENTE') {
                        this.searchClient(element)
                    }
                });
                this.page.totalElements = result.variable;
                this.page.pageNumber = page;
                this.lastSearch.type = 'seq';
                this.lastSearch.sequence = this.sequence;
                if (this.listActivities.length <= 0) {
                    this.messageCtrl.getInfoMessagePersonalized(
                        'warning',
                        'No hay resultados para mostrar',
                        'Sin resultados'
                    );
                }
            }
        })
    } else {
        const startTime = new Date(this.filter.startDate).getTime();
        const endTime = new Date(this.filter.finalDate).getTime();
        if (startTime >= endTime) {
            this.messageCtrl.getInfoMessagePersonalized(
                'error',
                'La fecha inicial debe ser anterior a la fecha final',
                'Error en las fechas'
            );
            return;
        }
        let path = 'actividad/filtrar?page=' + page;
        console.log(this.filter);
        const normalizedFilter = {...this.filter};
        if (!this.filter.responsable.id) {
            normalizedFilter.responsable = null;
        }
        if (!this.filter.client) {
            normalizedFilter.client = null;
        }
        if (!this.filter.status) {
            normalizedFilter.status = null;
        }
        this.serviceCtrl.createModel(path, normalizedFilter).subscribe(
            result => {
                console.log(result);
                if (result.code === 200) {
                    this.listActivities = result.answerList;
                    this.listActivities.forEach(element => {
                        if (element.relationalEntityType === 'CLIENTE') {
                            this.searchClient(element)
                        }
                    });
                    this.page.totalElements = result.variable;
                    this.page.pageNumber = page;
                    this.lastSearch.type = 'filter';
                    this.lastSearch.filter = normalizedFilter;
                    if (this.listActivities.length <= 0) {
                        this.messageCtrl.getInfoMessagePersonalized(
                            'warning',
                            'No hay resultados para mostrar',
                            'Sin resultados'
                        );
                    }
                }
            }, error => {
                this.messageCtrl.getInfoMessageError();
            }
        )
    }
  }


  getActivities(page = 0) {
    let path='actividad?page=' + page;
    this.serviceCtrl.getModel(path).subscribe(
      result => {
        if(result.code === 200) {
          this.listActivities = result.answerList;
          this.listActivities.forEach(element => {
            if(element.relationalEntityType === 'CLIENTE') {
              this.searchClient(element)
            }
          });
          this.page.totalElements = result.variable;
          this.page.pageNumber = page;
          this.lastSearch = {
            type: '',
            filter: null
          };
          this.dataLoad=true;
            if (this.listActivities.length <= 0) {
                this.messageCtrl.getInfoMessagePersonalized(
                    'warning',
                    'No hay resultados para mostrar',
                    'Sin resultados'
                );
            }
        }
      }
      ,error=>{
        this.messageCtrl.getInfoMessageError();
      }
    )
  }

  searchClient(activity) {
    let path='cliente/ver-por-id?clientId='+activity.relationalEntityId
    this.serviceCtrl.getModel(path).subscribe(
      result=>{
           if(result.code===200)
           {
             console.log(result);
            let client=result.genericObject;
            
            activity.relationalEntityType=client.nameOrBusinessName;
            console.log(activity);
           }
           else{
                   this.messageCtrl.getInfoMessageError();
           }
      },
      error=>{
        this.messageCtrl.getInfoMessageError();
      }
    )
  }

    changeSearchType(type) {
        this.searchType = type;
    }

    validateSearch() {
        return (this.searchType === 'seq' && this.sequence && this.sequence > 0) ||
            (this.searchType === 'filter' && this.filter.startDate && this.filter.finalDate);
    }

    searchAfterEvent(page = 0) {
        let action = null;
        let url;
        if (!this.lastSearch.type) {
            this.getActivities(page);
        } else if (this.lastSearch.type === 'seq') {
            url = 'actividad/actividades-por-consecutivo?activitySeq=' + this.lastSearch.sequence + '&page=' + page;
            action = this.serviceCtrl.getModel(url);
        } else if (this.lastSearch.type === 'filter') {
            url = 'actividad/filtrar?page=' + page;
            action = this.serviceCtrl.createModel(url, this.lastSearch.filter);
        }
        if (action) {
            action.toPromise().then((result: GenericResponseModel) => {
                if (result.code === 200) {
                    this.listActivities = result.answerList;
                    this.listActivities.forEach(element => {
                        if(element.relationalEntityType === 'CLIENTE') {
                            this.searchClient(element)
                        }
                    });
                    this.dataLoad = true;
                    this.page.totalElements = result.variable;
                    this.page.pageNumber = page;
                    if (this.listActivities.length <= 0) {
                        this.messageCtrl.getInfoMessagePersonalized(
                            'warning',
                            'No hay resultados para mostrar',
                            'Sin resultados'
                        );
                    }
                }
            });
        }
    }

    setPage(pageInfo) {
        this.searchAfterEvent(pageInfo.offset);
    }

    clearSearch() {
        this.filter = {
            startDate: null,
            finalDate: null,
            responsable: new UserModel(),
            status: '',
            client: '',
            companyId: this.tokenServiceCtrl.getCompanyId()
        };
        this.sequence = null;
        this.getActivities();
    }
}
