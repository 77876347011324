export class CommissionModelResponse {
    insurerId: any;
    policyId: any;
    attachedId: any;
    premium: string;
    insuredId: any;
    classOfInsuranceId: any;
    sellerId: any;
    commissionPercentage: string;
    agencyPercentage: string;
    premiumCalculated: string;
    commissionValue: string;
    retefuentePercentage: string;
    iva: string;
    reteiva:string;
    icaPercentage: string;
    creePercentage: string;
    commissionCalculated: string;
    documentTypeId: any;

    public constructor() {
        this.insurerId = null;
        this.policyId = null;
        this.attachedId = null;
        this.premium = '';
        this.insuredId = null;
        this.classOfInsuranceId = null;
        this.sellerId = null;
        this.commissionPercentage = '';
        this.agencyPercentage = '';
        this.premiumCalculated = '';
        this.commissionValue = '';
        this.retefuentePercentage = '';
        this.iva = '';
        this.reteiva = '';
        this.icaPercentage = '';
        this.creePercentage = '';
        this.commissionCalculated = '';
        this.documentTypeId = '';
    }
}
