import { FirebaseStorageService } from './../../shared/firebase/firebase-storage.service';
import { CrudServiceService } from './../../shared/backend/cruds/crud-service.service';
import { CompanyModel } from 'models/entities/company-model';
import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { NgbModal, NgbActiveModal, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { UserModel } from 'models/entities/user-model';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import swal from 'sweetalert2';
import { DocumentTypeModel } from 'models/entities/document-type-model';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { CompanyConfigsComponent } from '../company-configs/company-configs.component';
import { DepartmentModel } from 'models/entities/department-model';
import { debounceTime, distinctUntilChanged, filter, map, merge } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs-compat';
import { CityModel } from 'models/entities/city-model';



@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {
  currentFileUpload: File;
  focusDeparment$ = new Subject<string>();
  clickDeparment$ = new Subject<string>();
  focusCity$ = new Subject<string>();
  clickCity$ = new Subject<string>();
  selectedExtension = '';
  user: UserModel = new UserModel();
  company: CompanyModel = new CompanyModel();
  isSignedUp = false;
  isSignUpFailed = false;
  listDepartaments: Array<DepartmentModel> = new Array();
  listCities: Array<CityModel> = new Array();
  errorMessage = '';
  roleTitle = this.tokenStorage.getAuthorities();
  passwordValidationError = '';
  isErrorPassword = false;
  typesRestrictions = ['jpg', 'PNG', 'png', 'JPGE'];
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  passwordConfirmation = '';
  termsAndConditions = false;
  listDocumentTypes: Array<DocumentTypeModel> = new Array();
  listCompanies;

  @Input() public modalInformacion;
  @ViewChild('instanceClient') instanceClient: NgbTypeahead;
  @ViewChild('f') registerForm: NgForm;
  @ViewChild('ft') registerFormTwo: NgForm;

  constructor(
    private modalService: NgbModal,
    private firebaseStorageService: FirebaseStorageService,
    private messageService: InfoMessagesService,
    private crudService: CrudServiceService,
    private tokenStorage: TokenStorageService,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.getDocumentsTypes();
    this.getDepartamnet(1);
    // debugger;
    if(this.roleTitle==='ROLE_SUPER')
    {
    this.getCompanies();
    }
  }
  formatter = (x: { name: string }) => x.name;

  getDocumentsTypes() {
    const pathListDOcumentTypesUrl = 'tipos-de-documento/ver-por-objetivo?objective=Personal';
    this.listDocumentTypes = new Array();
    this.crudService.getModel(pathListDOcumentTypesUrl).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.listDocumentTypes = genericResponse.answerList;
          this.user.documentTypeId.id = this.listDocumentTypes[0].id;
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar los tipos de documentos del sistema',
            'Problema consultando los tipos de documentos del sistema');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar los tipos de documentos del sistema ' + JSON.stringify(error))
      }
    );
  }

  selectFile(event) {
    if (this.typesRestrictions.includes(event.target.files.item(0).name.split('.').pop())) {
      this.currentFileUpload = event.target.files.item(0);
      this.selectedExtension = event.target.files.item(0).name.split('.').pop()
    } else {
      this.currentFileUpload = undefined;
      swal({
        title: 'El tipo no es correcto', type: 'error',
        text: 'El archivo debe tener formato jpg, PNG, png ó JPEG, jpeg'
      })
    }
  }

  passwordValidation() {
    if (this.user.password !== this.passwordConfirmation) {
      this.passwordValidationError = '¡Las Contraseñas No Coinciden!';
      this.isErrorPassword = true;
      setTimeout(() => { this.isErrorPassword = false; }, 3000);
    } else {
    }
  }

  onSubmit() {
    if (this.tokenStorage.getAuthorities() === 'ROLE_SUPER') {
      this.user.role = 'ROLE_ADMIN';
    }
    if(this.tokenStorage.getAuthorities() === 'ROLE_ADMIN' || this.tokenStorage.getAuthorities() === 'ROLE_USER' )
    {
      this.user.companyId.id=this.tokenStorage.getCompanyId();
    }

    this.crudService.createModel('usuarios/crear', this.user).subscribe(
      genericResponse => {
        if (genericResponse.code === 201) {
          this.isSignedUp = true;
          this.isSignUpFailed = false;
          this.user = new UserModel();
          this.messageService.getInfoMessageCreate();
          this.activeModal.dismiss();
          this.passEntry.emit('Usuario Registrado');
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se creó el usuario')
          this.errorMessage = genericResponse.answer;
          this.isSignedUp = false;
          this.isSignUpFailed = true;
        }
      },
      error => {
        this.errorMessage = error.error.message;
        this.isSignUpFailed = true;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  routeDashboard(){
    
  }


  getCompanies() {

    const path = 'empresa/compania/listar-companias'
    this.crudService.getModel(path).subscribe(
      result => {

        if (result.code == 200) {
          console.log(result)
          this.listCompanies = result.answerList;
        }

      },
      error => {
        this.messageService.getInfoMessageError();
      }
    )
  }

  openCreateCompany() {
    const modal = this.modalService.open(CompanyConfigsComponent, { windowClass: 'dark-modal' });
    modal.componentInstance.modal = true;
    modal.componentInstance.activeModal = modal;
    modal.componentInstance.emit.subscribe(
      result => {
        this.getCompanies();
        this.user.companyId.id = result.id;

      }
    )
  }


  upload(company: CompanyModel) {
    this.firebaseStorageService.uploadFile(company.id + '/' + 'logos', 'logo.' + this.selectedExtension, this.currentFileUpload).then(
      (responseFirebase) => {
        this.firebaseStorageService.loadFile(company.id + '/' + 'logos', 'logo.' + this.selectedExtension).then(
          (dowloadURL) => {
            company.logoUrl = dowloadURL
            this.modalService.dismissAll();
            this.passEntry.emit('Usuario Registrado');
          }
        ).catch(error => {
          console.error(error);
          this.messageService.getInfoMessageBadInternet();
        })
      }, (error) => {
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    )
  }
  getDepartamnet(countriId) {
    const pathListDepartament =
      'departamento/departamentos-por-pais?countryId=' + countriId;
    this.listDepartaments = new Array();
    this.crudService.getModel(pathListDepartament).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          const listDeparta = genericResponse['answerList'];
          listDeparta.forEach(departamnet => {
            this.listDepartaments.push(departamnet);
          });
        } else {
          this.messageService.getInfoMessageError();
        }
      },
      error => {
        this.messageService.getInfoMessagePersonalized(
          'error',
          'No se pudo Listar Departamento',
          'Error'
        );
        console.error('El error es ', JSON.stringify(error.message));
      }
    );
  }
  getCity() {
    let departmentId: any;
    this.listDepartaments.forEach(departamnet => {
      if (this.user.department.name === departamnet.name) {
        departmentId = departamnet.id;
      }
    });
    if (departmentId) {
      const pathListCities =
        'ciudad/ciudades-por-departamento?departmentId=' + departmentId;
      this.listCities = new Array();
      this.crudService.getModel(pathListCities).subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.listCities = genericResponse.answerList;
           
              this.user.city = genericResponse.answerList[0];
            
          } else {
            this.messageService.getInfoMessageError();
          }
        },
        error => {
          this.messageService.getInfoMessagePersonalized(
            'error',
            'No se pudo Listar Departamento',
            'Error'
          );
          console.error('El error es ', JSON.stringify(error.message));
        }
      );
    }
  }
  searchDepartamnet = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    merge(this.focusDeparment$),
    merge(this.clickDeparment$.pipe(filter(() => !this.instanceClient.isPopupOpen()))),
    map(search => (search === '' ? this.listDepartaments
      : this.listDepartaments.filter(department => department.name.toLowerCase().indexOf(search.toLowerCase()) > -1)).slice(0, 10))
  );
  
  searchCities = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    merge(this.focusCity$),
    merge(this.clickCity$.pipe(filter(() => !this.instanceClient.isPopupOpen()))),
    map(search => (search === '' ? this.listCities
      : this.listCities.filter(city => city.name.toLowerCase().indexOf(search.toLowerCase()) > -1)).slice(0, 10))
  );

}


