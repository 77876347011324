import { Component, OnInit } from '@angular/core';
import {TokenStorageService} from "../../shared/storage-services/token-storage.service";
import {CrudServiceService} from "../../shared/backend/cruds/crud-service.service";
import {CommissionBillModel} from "../../../models/entities/commission-bill-model";
import {GenericResponseModel} from "../../../models/utilities/generic.response.model";
import {InfoMessagesService} from "../../shared/messages/info-messages.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {BillComponent} from "../bill/bill.component";
import {RegisterBillComponent} from "../register-bill/register-bill.component";
import swal from "sweetalert2";

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {

  initialDate: string;
  finalDate: string;
  billingList: CommissionBillModel[] = [];
  searching = false;
  lastSearch: any;

  constructor(
      private tokenStorage: TokenStorageService,
      private crudService: CrudServiceService,
      private messagesService: InfoMessagesService,
      private modalService: NgbModal
  ) { }

  ngOnInit() {
  }

  fetchBilling() {
    const path = `facturacion-comisiones/obtener-facturas?company=${this.tokenStorage.getCompanyId()}&initialDate=${this.initialDate}&finalDate=${this.finalDate}`;
    this.searching = true;
    this.crudService.getModel(path).toPromise().then((response: GenericResponseModel) => {
      this.billingList = response.answerList;
      this.searching = false;
      this.lastSearch = {
        initialDate: this.initialDate,
        finalDate: this.finalDate
      }
      if (this.billingList.length <= 0) {
        this.messagesService.getInfoMessagePersonalized(
            'warning',
            'No hay resultados para mostrar',
            'Sin resultados!'
        );
      }
    }).catch(error => {
      console.log(error);
      this.searching = false;
    });
  }

  showBill(row) {
    const modal = this.modalService.open(BillComponent, {
      windowClass: 'smallModal', backdrop: "static", size: 'lg'
    });
    modal.componentInstance.bill = row;
  }

  handleCancel(row) {
    swal({
      title: 'Está seguro que desea anular esta factura?',
      text: "No puede revertir esta acción después!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Anular!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.cancelBill(row);
      }
    });
  }

  cancelBill(row) {
    const path = `facturacion-comisiones/anular-factura?billId=${row.id}`;
    this.crudService.createModel(path, {}).toPromise().then((response: GenericResponseModel) => {
      this.messagesService.getInfoMessagePersonalized(
          'success',
          'Actualizado',
          'Estado actualizado correctamente'
      );
      row.status = 'CANCELADA';
    }).catch(error => {
      console.log(error);
    });
  }

  getBillStatus(bill) {
    return bill.status === 'CANCELADA' ? 'Anulada' : 'Válida';
  }
}
