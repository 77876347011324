import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  @Input() elementsList: any[];
  @Input() filterFunction: any;
  @Input() displayFunction: any;
  @Input() title: string;
  @Output() emitter: EventEmitter<any> = new EventEmitter<any>();

  searchQuery = '';

  constructor(
      public activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
  }

  selectElement(element) {
    this.emitter.emit({element});
    this.activeModal.dismiss();
  }
}
