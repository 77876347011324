import { UserModel } from 'models/entities/user-model';
import { CompanyModel } from './company-model';
import { AnsModel } from './ans-model';

export class ActivityTypeModel{
    id: any;
    name: string;
    companyId: any;
    ansId: any;
    createdDate: any;
    lastUpdateDate: any;
    creatorId: any;
    lastUpdateId: any;

    public constructor(){
        this.id = null;
        this.name = '';
        this.companyId = new CompanyModel();
        this.ansId = new AnsModel();
        this.createdDate = new Date();
        this.lastUpdateDate = new Date();
        this.creatorId = new UserModel();
        this.lastUpdateId =  new UserModel();
    }
}