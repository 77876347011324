import { TokenStorageService } from './../../shared/storage-services/token-storage.service';
import {Component, OnInit, Input, Output, EventEmitter, ViewChild} from '@angular/core';
import { ActivityModel } from 'models/entities/activity';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { ActivityManagmentModel } from 'models/entities/activity-managment';
import swal from 'sweetalert2';
import { ActivityStateModel } from 'models/entities/activity-state.model';
import { RelationalFileModel } from 'models/utilities/relational-type-model';
import {GenericResponseModel} from "../../../models/utilities/generic.response.model";
import {Observable, Subject} from "rxjs";
import {debounceTime, distinctUntilChanged, filter, map, merge} from "rxjs/operators";
import {NgbTypeahead} from "@ng-bootstrap/ng-bootstrap";
import {UserModel} from "../../../models/entities/user-model";

@Component({
  selector: 'app-view-activity',
  templateUrl: './view-activity.component.html',
  styleUrls: ['./view-activity.component.scss']
})
export class ViewActivityComponent implements OnInit {
  @Input() activity=new ActivityModel();
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  listActivitys: Array<ActivityManagmentModel> = new Array();
  public listUsers: any [];
  public  responsableActual;
  status:boolean=false;
  public activityManagmentModel=new ActivityManagmentModel();
  public user;
  listStates:Array<ActivityStateModel> = new Array();  
  public relationalModel: RelationalFileModel = new RelationalFileModel();

  clickUser$ = new Subject<string>();
  focusUserIn$ = new Subject<string>();
  @ViewChild('instanceUser') instanceUser: NgbTypeahead;

  isExpired = false;
  supportActivityInfo = {
    creator: { completeName: '' },
    company: { name: '' }
  }
  newResponsible: UserModel;

  constructor(
    private messageCtrl:InfoMessagesService,
    private serviceCtrl:CrudServiceService,
    private tokenServiceCtrl:TokenStorageService,
  ) { }

  ngOnInit() {
    this.relationalModel.relationalId = this.activity.id;
    this.relationalModel.relationalType = 'ACTIVITY'
    this.relationalModel.relationalFileType = 'ARCHIVO';
    this.relationalModel.relationalRestrictions = new Array();
    this.getUsers();
    this.responsableActual=this.activity.responsibleUserId.completeName;
    this.user=this.activity.responsibleUserId;
    if (
        (this.activity.activityState === 'Finalizada' && new Date(this.activity.endedAt).getTime() > new Date(this.activity.expireDate).getTime())
        || (new Date(this.activity.expireDate).getTime() < Date.now())
    ) {
      this.isExpired = true;
    }
    this.getSupportActivityInfo();
    this.newResponsible = this.activity.responsibleUserId;
  }

  updateResponsable(){
    swal({
      title: 'Estás seguro que deseas reasignar esta actividad?',
      text: "No puedes revertir esta acción después!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, reasignar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        const updatingActivity = { ...this.activity };
        updatingActivity.responsibleUserId = this.newResponsible;
        let path = 'actividad/crear'
        this.serviceCtrl.createModel(path, updatingActivity).subscribe(
          result => {
            if(result.code === 200) {
              this.createComent();
              swal(
                'Actividad reasignada!',
                'La actividad ha sido reasignada.',
                'success'
              );
              this.activity = updatingActivity;
            } else {
              this.messageCtrl.getInfoMessageError();
            }
          }, error => {
            console.log(error)
            this.messageCtrl.getInfoMessageError();
          }
        )
      }
    })
  }


  validateStatus(id)
  {
    let flag=true;

    this.listStates.forEach(element => {
      if(element.id===id)
      {
        flag=false;
      }
    });

    return flag;
  }

  createComent(){
    this.activityManagmentModel.coment='Reasignación de actividad del usuario '+this.responsableActual+ 'al usuario '+this.activity.responsibleUserId.completeName
    this.activityManagmentModel.activityId=this.activity;
    this.activityManagmentModel.creatorId=this.user;
    let path='gestion-actividad';
    this.serviceCtrl.createModel(path,this.activityManagmentModel).subscribe(
      result=>{
        if(result.code===200)
        {
          
         this.activityManagmentModel=new ActivityManagmentModel();
        }
      },
      error=>{
       this.messageCtrl.getInfoMessageError();
      }
    )
  }

  getUsers(){
    let path='usuarios/listar-vendedores-y-gestores';
    this.serviceCtrl.getModel(path).subscribe(
      result=>{
        if(result.code===200)
        {
         this.listUsers=result.answerList;
        }
      },
      error=>{
       this.messageCtrl.getInfoMessageDelete();
      }
    )
  }

  close(){
    this.passEntry.emit('close');
  }

  updateStatus() {
    swal({
      title: 'Está seguro que desea finalizar la actividad?',
      text: "No puede revertir esta acción después!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, finalizar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        
        let path='actividad/actualizar-estado'

        this.serviceCtrl.createModel(path,this.activity).subscribe(
          result=>{
      
            if(result.code===200)
            {
              swal(
                'Estado de actividad actualizado!',
                'La actividad ha pasado a un estado finalizado.',
                'success'
              )
              this.passEntry.emit('close');
            }
            else
            {
              this.messageCtrl.getInfoMessageError();
            }
      
          },
          error=>{
            this.messageCtrl.getInfoMessageError();
          }
        )
        
      }
    })
    
  }

  getActivitysComents(){
    let path='gestion-actividad?activityId='+this.activity.id;
    this.serviceCtrl.getModel(path).subscribe(
      result=>{
       
        if(result.code===200)
        {
           this.listActivitys=result.answerList;
        }
      }
      ,error=>{
        this.messageCtrl.getInfoMessageError();
      }
    )
  }

  getAction(evt){
    
        this.getActivitysComents();

  }

  getSupportActivityInfo() {
    if (this.activity.activityTypeId.name !== 'Soporte Seguros App') {
      return;
    }
    this.serviceCtrl.getModel('actividad/info-actividades-soporte?activityId=' + this.activity.id)
    .toPromise().then((response: GenericResponseModel) => {
      this.supportActivityInfo = response.genericObject;
    }).catch(error => {
      console.log(error);
    });
  }

  formatterUser = (object: { completeName: string }) => object.completeName

  searchByNameUser = (text$: Observable<string>) =>
      text$.pipe(
          debounceTime(200),
          distinctUntilChanged(),
          merge(this.focusUserIn$),
          merge(this.clickUser$.pipe(filter(() => !this.instanceUser.isPopupOpen()))),
          map(search => (search === '' ? this.listUsers
              : this.listUsers.filter(user => user.completeName.toLowerCase().indexOf(search.toLowerCase()) > -1)).slice(0, 10))
      );

  printuser() {
    console.log(this.newResponsible)
  }
}
