import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrincipalViewComponent } from './principal-view/principal-view.component';
import { RoleAuthGuard } from 'app/shared/auth/roleAuth-guard.service';
import { SearchingClientsComponent } from './searching-clients/searching-clients.component';
import { ListClientsComponent } from './list-clients/list-clients.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: PrincipalViewComponent,
        data: {
          title: '',
          expectedRole: [ 'ROLE_SUPER' , 'ROLE_ADMIN', 'ROLE_USER', 'ROLE_SELLER']
        },
        canActivate: [RoleAuthGuard]
      },
      {
        path: 'busqueda-de-clientes',
        component: SearchingClientsComponent,
        data: {
          title: 'Búsqueda De Clientes',
          expectedRole: [ 'ROLE_SUPER' , 'ROLE_ADMIN', 'ROLE_USER', 'ROLE_SELLER']
        },
        canActivate: [RoleAuthGuard]
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClientsRoutingModule { }
