import { DetailProfileComponent } from './detail-profile/detail-profile.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListProfileComponent } from './list-profile/list-profile.component';
import { RoleAuthGuard } from 'app/shared/auth/roleAuth-guard.service';
import { CreateProfileComponent } from './create-profile/create-profile.component';
import { UpdateProfileComponent } from './update-profile/update-profile.component';
import { ToPairUserComponent } from './to-pair-user/to-pair-user.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'listar-perfiles',
        component: ListProfileComponent,
        data: {
          title: 'listprofile',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN']
        },
        canActivate: [RoleAuthGuard]
      },
      {
        path: 'createprofile',
        component: CreateProfileComponent,
        data: {
          title: 'createprofile',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN']
        },
        canActivate: [RoleAuthGuard]
      },
      {
        path: 'detailprofile',
        component: DetailProfileComponent,
        data: {
          title: 'detailprofile',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN']
        },
        canActivate: [RoleAuthGuard]
      },
      {
        path: 'updateprofile',
        component: UpdateProfileComponent,
        data: {
          title: 'updateprofile',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN']
        },
        canActivate: [RoleAuthGuard]
      },
      {
        path: 'topairuser',
        component: ToPairUserComponent,
        data: {
          title: 'topairuser',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN']
        },
        canActivate: [RoleAuthGuard]
      }
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  providers: [RoleAuthGuard],
  exports: [RouterModule]
})
export class ProfileRoutingModule { }
