import { Component, OnInit, Input } from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {CommissionBillModel} from "../../../models/entities/commission-bill-model";
import * as moment from "moment";
import * as jspdf from 'jspdf';
import 'jspdf-autotable'

@Component({
  selector: 'app-bill',
  templateUrl: './bill.component.html',
  styleUrls: ['./bill.component.scss']
})
export class BillComponent implements OnInit {

  @Input() bill: CommissionBillModel;
  numOfTotalRows = 4;
  today = new Date()
  logoBase64 = null;
  cancelledMark = null;

  constructor(
      public activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {
    this.fetchCompanyLogo();
  
    this.fetchCancelledMark();
  }

  getArray() {
    return this.bill.details.length < this.numOfTotalRows ? Array(this.numOfTotalRows - this.bill.details.length) : [];
  }

  getTotalDetail(detail) {
    return Number(detail.quantity * detail.unitAmount).toLocaleString('es-ES');
  }

  async printPDF(){
    const doc = new jspdf('portrait', 'pt', 'letter');
    doc.setTextColor('black');
    const { company, createdAt, billNumber, seller } = this.bill;
    let posY = 50;
    let posX = 45;
    const maxX = 560;

    // Crear header
    doc.setFontSize(17);
    doc.setFontType('bold');
    doc.text(posX, posY-10, "DOCUMENTO SOPORTE EN");
    doc.text(posX, posY+10, "ADQUISICIONES EFECTUADAS A");
    doc.text(posX, posY+30, "NO OBLIGADOS A FACTURAR");
    doc.setFontSize(17);
    doc.setFontType('bold');
    doc.text((maxX-posX*2), posY-10, company.name);
    doc.setFontSize(10);
    doc.setFontType('normal');
    doc.setTextColor('gray');
    const texts = [ company.documentNumber, company.address];
    if (company.phone) {
      texts.push(company.phone);
    }
    if (company.cellphone) {
      texts.push(company.cellphone);
    }
    texts.forEach((t, i) => {
      posY += 15;
      doc.text(maxX - (posX +40), posY, t);
    });

    doc.addImage(this.logoBase64, 'JPG', maxX - (posY +50), 40, (posY - 50), (posY - 50));
    
    doc.setTextColor('black');
    doc.setFontSize(11);
    posY += 35;

    // Info factura
    doc.text(posX, posY, 'Fecha');
    posX += 35;
    doc.line(posX, posY + 3, posX + 150, posY + 3);
    doc.setFontType('bold');
    doc.text(posX + 30, posY, moment(createdAt).format('DD/MM/yyyy'));

    doc.setFontType('normal');
    doc.text(430, posY, 'Nº');
    doc.line(445, posY + 3, maxX, posY + 3);
    doc.setFontType('bold');
    doc.setTextColor('red');
    doc.text(480, posY, billNumber);

    doc.setTextColor('black');
    doc.setFontType('normal');
    posY += 30;
    posX = 45;

    doc.text(posX, posY, 'BENEFICIARIO DEL PAGO');
    doc.text(maxX - 10, posY, 'NIT');

    doc.setFontType('bold');
    posY += 20;
    doc.text(posX, posY, seller.completeName.toUpperCase());
    doc.text(maxX - (seller.documentNumber.length * 5), posY, seller.documentNumber);

    doc.setTextColor('black');
    doc.setFontType('normal');
    posY += 30;
    posX = 45;

    doc.text(posX, posY, 'DIRECCIÓN');
    doc.text(maxX - 50, posY, 'TELÉFONO');

    

    doc.setFontType('bold');
    posY += 20;
    doc.text(posX, posY, seller.address);
    doc.text(maxX - (seller.cellphone.length * 5), posY, seller.cellphone);
    doc.setFontType('bold');
    posY += 20;
    doc.setFontType('normal');
    doc.text(posX, posY, 'PAIS');
    posY += 20;
    doc.setFontType('bold');
    doc.text(posX, posY, "COLOMBIA");
    posY += 20;
    doc.setFontType('normal');
    doc.text(posX, posY, 'DEPARTAMENTO');
    posY += 20;
    doc.setFontType('bold');
    if (seller.department==null) {
      doc.text(posX, posY, " ");
    }else{
      doc.text(posX, posY, seller.department.name.toUpperCase());
    }

    posY += 20;
    doc.setFontType('normal');
    doc.text(maxX - 50, posY-60, 'CIUDAD');
    doc.setFontType('bold');
    if (seller.city==null) {
      doc.text(maxX - 50, posY-40, " ");
    }else{
      doc.text(maxX - 50, posY-40, seller.city.name.toUpperCase());
    }

    
    posY += 30;

    // Tabla detalles
    const cellStyles = {
      fillColor: 'white',
      halign: 'center',
      valign: 'middle',
      lineColor: 'black',
      lineWidth: 1
    };

    const extraRows = [];
    for (let i = 0; i < (this.numOfTotalRows - this.bill.details.length); i++) {
      extraRows.push(['', '', '', '']);
    }
    extraRows.push([
      { content: '', styles: { lineWidth: 0 }},
      { content: '', styles: { lineWidth: 0 }},
      { content: 'TOTAL OPERACIÓN', styles: { lineWidth: 0 }},
      { content: this.bill.amount.toLocaleString('es-ES'), styles: { fontStyle: 'bold' }},
    ]);

    doc.autoTable({
      theme: 'plain',
      headStyles: cellStyles,
      bodyStyles: cellStyles,
      startY: posY,
      head: [['CANT', 'DETALLE', 'V/UNITARIO', 'TOTAL']],
      body: [
        ...this.bill.details.map(d => [
          `${d.quantity}`,
          d.details.toUpperCase(),
          Number(d.unitAmount).toLocaleString('es-ES'),
          Number(d.unitAmount * d.quantity).toLocaleString('es-ES')
        ]),
        ...extraRows
      ],
    });

    const rowCount = (this.bill.details.length > 6 ? (this.bill.details.length + 2) : 8);
    posY += (rowCount * 20);

    // Resolución
    posY += 20;
    posX = 45;
    doc.setFontSize(8);
    doc.setFontType('normal');
    const len = this.bill.company.billingResolutionMessage.length * 6;
    const diff = (maxX - len) / 2;
    doc.text(posX + 25, posY, this.bill.company.billingResolutionMessage);

    // Espacio firma
    doc.setFontSize(11);
    doc.setFontType('bold');
    posY += 40;
    posX = 200;
    doc.text(posX, posY, 'FIRMA ACEPTACIÓN');
    doc.line(posX + 120, posY + 5, maxX, posY + 5);

    if (this.bill.status === 'CANCELADA') {
      doc.addImage(this.cancelledMark, 'JPG', 150, 120, 289, 246);
    }

    const formattedDate = moment().format('DD/MM/yyyy');
    const filename = `Factura pago de comisiones ${formattedDate} - ${this.bill.seller.completeName}.pdf`;
    doc.save(filename);
  }

  fetchCompanyLogo = () => {
    fetch(this.bill.company.logoUrl).then(response => response.blob()).then(blob => new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => this.logoBase64 = reader.result;
      reader.onerror = reject
      reader.readAsDataURL(blob)
    }));
  }


  fetchCancelledMark = () => {
    const url  = 'https://firebasestorage.googleapis.com/v0/b/seguros-app-8add2.appspot.com/o/anulado.png?alt=media&token=34afa00b-78a8-4c6d-8d54-972162d91dbb';
    fetch(url).then(response => response.blob()).then(blob => new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => this.cancelledMark = reader.result;
      reader.onerror = reject
      reader.readAsDataURL(blob)
    }));
  }
}
