import { LandingComponent } from './landing/landing.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoleAuthGuard } from 'app/shared/auth/roleAuth-guard.service';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'welcome',
        component: LandingComponent,
        data: {
          title: 'welcome',
          expectedRole: []
        }
      },
      {
        path: '',
        component: LandingComponent,
        data: {
          title: 'landing',
          expectedRole: []
        },
      }
    ],
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  providers: [RoleAuthGuard],
  exports: [RouterModule]
})
export class WelcomeRoutingModule { }
