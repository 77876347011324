import { Component, OnInit, ViewChild } from '@angular/core';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { CompanyModel } from 'models/entities/company-model';
import { UserModel } from 'models/entities/user-model';
import { NgForm } from '@angular/forms';
import swal from 'sweetalert2';
import { AuthService } from 'app/shared/auth/auth.service';
import { LoginCredentialsModel } from 'models/auth/login-credentials-model';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

  personalDocumentTypes = [];
  companyDocumentTypes = [];
  acceptTerms = false;
  sameInfo = false;
  password2 = '';
  loading = false;

  companyModel: CompanyModel = new CompanyModel();
  userModel: UserModel = new UserModel();

  @ViewChild('f') registerForm: NgForm;

  countries = [
    "Afganistán",
    "Albania",
    "Alemania",
    "Andorra",
    "Angola",
    "Anguila",
    "Antártida",
    "Antigua y Barbuda",
    "Arabia Saudita",
    "Argelia",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaiyán",
    "Bélgica",
    "Bahamas",
    "Bahrein",
    "Bangladesh",
    "Barbados",
    "Belice",
    "Benín",
    "Bhután",
    "Bielorrusia",
    "Birmania",
    "Bolivia",
    "Bosnia y Herzegovina",
    "Botsuana",
    "Brasil",
    "Brunéi",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Camboya",
    "Camerún",
    "Canadá",
    "Chad",
    "Chile",
    "China",
    "Chipre",
    "Ciudad del Vaticano",
    "Colombia",
    "Comoras",
    "República del Congo",
    "República Democrática del Congo",
    "Corea del Norte",
    "Corea del Sur",
    "Costa de Marfil",
    "Costa Rica",
    "Croacia",
    "Cuba",
    "Curazao",
    "Dinamarca",
    "Dominica",
    "Ecuador",
    "Egipto",
    "El Salvador",
    "Emiratos Árabes Unidos",
    "Eritrea",
    "Eslovaquia",
    "Eslovenia",
    "España",
    "Estados Unidos de América",
    "Estonia",
    "Etiopía",
    "Filipinas",
    "Finlandia",
    "Fiyi",
    "Francia",
    "Gabón",
    "Gambia",
    "Georgia",
    "Ghana",
    "Gibraltar",
    "Granada",
    "Grecia",
    "Groenlandia",
    "Guadalupe",
    "Guam",
    "Guatemala",
    "Guayana Francesa",
    "Guernsey",
    "Guinea",
    "Guinea Ecuatorial",
    "Guinea-Bissau",
    "Guyana",
    "Haití",
    "Honduras",
    "Hong kong",
    "Hungría",
    "India",
    "Indonesia",
    "Irán",
    "Irak",
    "Irlanda",
    "Isla Bouvet",
    "Isla de Man",
    "Isla de Navidad",
    "Isla Norfolk",
    "Islandia",
    "Islas Bermudas",
    "Islas Caimán",
    "Islas Cocos (Keeling)",
    "Islas Cook",
    "Islas de Åland",
    "Islas Feroe",
    "Islas Georgias del Sur y Sandwich del Sur",
    "Islas Heard y McDonald",
    "Islas Maldivas",
    "Islas Malvinas",
    "Islas Marianas del Norte",
    "Islas Marshall",
    "Islas Pitcairn",
    "Islas Salomón",
    "Islas Turcas y Caicos",
    "Islas Ultramarinas Menores de Estados Unidos",
    "Islas Vírgenes Británicas",
    "Islas Vírgenes de los Estados Unidos",
    "Israel",
    "Italia",
    "Jamaica",
    "Japón",
    "Jersey",
    "Jordania",
    "Kazajistán",
    "Kenia",
    "Kirguistán",
    "Kiribati",
    "Kuwait",
    "Líbano",
    "Laos",
    "Lesoto",
    "Letonia",
    "Liberia",
    "Libia",
    "Liechtenstein",
    "Lituania",
    "Luxemburgo",
    "México",
    "Mónaco",
    "Macao",
    "Macedônia",
    "Madagascar",
    "Malasia",
    "Malawi",
    "Mali",
    "Malta",
    "Marruecos",
    "Martinica",
    "Mauricio",
    "Mauritania",
    "Mayotte",
    "Micronesia",
    "Moldavia",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Mozambique",
    "Namibia",
    "Nauru",
    "Nepal",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Noruega",
    "Nueva Caledonia",
    "Nueva Zelanda",
    "Omán",
    "Países Bajos",
    "Pakistán",
    "Palau",
    "Palestina",
    "Panamá",
    "Papúa Nueva Guinea",
    "Paraguay",
    "Perú",
    "Polinesia Francesa",
    "Polonia",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Reino Unido",
    "República Centroafricana",
    "República Checa",
    "República Dominicana",
    "República de Sudán del Sur",
    "Reunión",
    "Ruanda",
    "Rumanía",
    "Rusia",
    "Sahara Occidental",
    "Samoa",
    "Samoa Americana",
    "San Bartolomé",
    "San Cristóbal y Nieves",
    "San Marino",
    "San Martín (Francia)",
    "San Pedro y Miquelón",
    "San Vicente y las Granadinas",
    "Santa Elena",
    "Santa Lucía",
    "Santo Tomé y Príncipe",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leona",
    "Singapur",
    "Sint Maarten",
    "Siria",
    "Somalia",
    "Sri lanka",
    "Sudáfrica",
    "Sudán",
    "Suecia",
    "Suiza",
    "Surinám",
    "Svalbard y Jan Mayen",
    "Swazilandia",
    "Tayikistán",
    "Tailandia",
    "Taiwán",
    "Tanzania",
    "Territorio Británico del Océano Índico",
    "Territorios Australes y Antárticas Franceses",
    "Timor Oriental",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad y Tobago",
    "Tunez",
    "Turkmenistán",
    "Turquía",
    "Tuvalu",
    "Ucrania",
    "Uganda",
    "Uruguay",
    "Uzbekistán",
    "Vanuatu",
    "Venezuela",
    "Vietnam",
    "Wallis y Futuna",
    "Yemen",
    "Yibuti",
    "Zambia",
    "Zimbabue",
  ]

  constructor(
    private crudService: CrudServiceService,
    private messageService: InfoMessagesService,
    private authService: AuthService,
    private tokenStorage: TokenStorageService,
    private router: Router,
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.fetchPersonalDocumentTypes();
    this.userModel.documentTypeId.id = '';
    this.companyModel.documentTypeId.id = '';
  }

  onSubmit() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    if (!this.validatePasswords()) {
      return;
    }
    this.companyModel.email = this.userModel.email;
    this.userModel.username = this.userModel.email;
    const url = 'usuarios/crear-cuenta-trial';
    const body = {
      user: this.userModel,
      company: this.companyModel
    }
    this.crudService.createModelWA(url, body).toPromise()
    .then((response: GenericResponseModel) => {
      this.messageService.getInfoMessagePersonalized(
        'success',
        'Hemos enviado un correo electrónico para que puedas activar tu cuenta',
        'Cuenta creada!'
      );
      this.activeModal.dismiss();
      this.router.navigateByUrl('pages/auth');
      this.userModel = new UserModel();
      this.companyModel = new CompanyModel();
    })
    .catch(error => {
      console.log(error);
      swal({ type: 'error', text: error.error.answer });
    })
  }

  fetchPersonalDocumentTypes() {
    const url = 'tipos-de-documento/ver-por-objetivo?objective=Personal';
    this.crudService.getModelWA(url).toPromise().then((response: GenericResponseModel) => {
      if (response.code === 200) {
        this.personalDocumentTypes = response.answerList;
      } else {
        this.messageService.getInfoMessagePersonalized('warning', 'No se pudo listar los tipos de documentos del sistema',
          'Problema consultando los tipos de documentos del sistema');
      }
    }).catch(error => {
      this.messageService.getInfoMessageError();
      console.error(error);
    });
  }

  validatePasswords() {
    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9a-zA-Z]).{6,15}$/;
    if (!regex.test(this.userModel.password)) {
      swal({ type: 'error', text: 'La contraseña debe contener un dígito, una letra minúscula, una letra mayúscula y debe contener entre 6 caracteres y 15' });
      return false;
    }
    if (this.userModel.password !== this.password2) {
      swal({ type: 'error', text: 'Las contraseñas no coinciden' });
      return false;
    }
    return true;
  }

  handleInfoCheckChange() {
    setTimeout(() => {
      if (this.sameInfo) {
        this.companyModel.phone = this.userModel.phone;
        this.companyModel.cellphone = this.userModel.cellphone;
        this.companyModel.address = this.userModel.address;
        this.companyModel.documentTypeId = this.userModel.documentTypeId;
        this.companyModel.documentNumber = this.userModel.documentNumber;
      }
    }, 300)
  }

  goBack() {
    this.activeModal.dismiss();
    this.router.navigateByUrl('welcome');
  }
}
