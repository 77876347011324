import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";

//COMPONENTS
import { FooterComponent } from "./footer/footer.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { CustomizerComponent } from './customizer/customizer.component';
import { NotificationSidebarComponent } from './notification-sidebar/notification-sidebar.component';

//DIRECTIVES
import { ToggleFullscreenDirective } from "./directives/toggle-fullscreen.directive";
import { SidebarDirective } from './directives/sidebar.directive';
import { SidebarLinkDirective } from './directives/sidebarlink.directive';
import { SidebarListDirective } from './directives/sidebarlist.directive';
import { SidebarAnchorToggleDirective } from './directives/sidebaranchortoggle.directive';
import { SidebarToggleDirective } from './directives/sidebartoggle.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InitialClientSearcherComponent } from './components/initial-client-searcher/initial-client-searcher.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ReportsModule } from 'app/reports/reports.module';
import { NgxLoadingModule } from 'ngx-loading';
import { SearchComponent } from './search/search.component';

@NgModule({
    exports: [
        CommonModule,
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        CustomizerComponent,
        NotificationSidebarComponent,
        ToggleFullscreenDirective,
        SidebarDirective,
        NgbModule,
        TranslateModule,
        InitialClientSearcherComponent
    ],
    imports: [
        RouterModule,
        CommonModule,
        NgbModule.forRoot(),
        TranslateModule,
        PerfectScrollbarModule,
        FormsModule,
        NgxDatatableModule,
        ReactiveFormsModule,
        NgxLoadingModule,
    ],
    declarations: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        CustomizerComponent,
        NotificationSidebarComponent,
        ToggleFullscreenDirective,
        SidebarDirective,
        SidebarLinkDirective,
        SidebarListDirective,
        SidebarAnchorToggleDirective,
        SidebarToggleDirective,
        InitialClientSearcherComponent,
        SearchComponent,
    ],
    entryComponents: [InitialClientSearcherComponent]
})
export class SharedModule { }
