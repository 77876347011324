import { PayStyleModel } from './pay-style-model';
import { UserModel } from './user-model';
import { PolicyModel } from './policy-model';

export class BudgetModel {
    id: string;
    premium: string;
    spendings: string;
    iva: string;
    totalIva: string;
    fosyga: string;
    tasaRUNT: string;
    totalWithIva: string;
    sellersCommission: string;
    companyCommission: string;
    payStyleId: PayStyleModel;
    createdDate: string;
    lastUpdateDate: string;
    creatorId: UserModel;
    lastUpdateId: UserModel;

    public constructor() {
        this.id = '';
        this.premium = '0';
        this.spendings = '0';
        this.iva = '0';
        this.totalIva = '0';
        this.fosyga = '0';
        this.tasaRUNT = '0';
        this.totalWithIva = '0';
        this.sellersCommission = '0';
        this.companyCommission = '0';
        this.payStyleId = new PayStyleModel();
        this.createdDate = '';
        this.lastUpdateDate = '';
        this.creatorId = new UserModel();
        this.lastUpdateId = null;
    }
}
