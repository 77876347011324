export class PairUserProfileModel {
    id: string;
    userId: any;
    profileId: any;

    public constructor() {
        this.id = '';
        this.userId = '';
        this.profileId = '';
    }

}
