import { CommissionsBySellerComponent } from './commissions-by-seller/commissions-by-seller.component';
import { CommissionsByInsurerComponent } from './commissions-by-insurer/commissions-by-insurer.component';
import { ActivitiesByManagerReportComponent } from './activities-by-manager-report/activities-by-manager-report.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HappyBirthDayReportComponent } from './happy-birth-day-report/happy-birth-day-report.component';
import { VehicleByLicensePlateReportComponent } from './vehicle-by-license-plate-report/vehicle-by-license-plate-report.component';
import { RoleAuthGuard } from 'app/shared/auth/roleAuth-guard.service';
import { MixClassOfInsuranceReportComponent } from './mix-class-of-insurance-report/mix-class-of-insurance-report.component';
import { ExpirationPolicyReportComponent } from './expiration-policy-report/expiration-policy-report.component';
import { ProductivityReportComponent } from './productivity-report/productivity-report.component';
import { PortfolioReportComponent } from './portfolio-report/portfolio-report.component';
import { CommissionReportComponent } from './commission-report/commission-report.component';
import {ActivitiesIndicatorComponent} from "./activities-indicator/activities-indicator.component";
import { ExpirationSoatReportComponent } from './expiration-soat-report/expiration-soat-report.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'cumple',
        component: HappyBirthDayReportComponent,
        data: {
          title: 'Cumpleaños',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN', 'ROLE_USER', 'ROLE_SELLER']
        },
        canActivate: [RoleAuthGuard]
      },
      {
        path: 'soat',
        component: ExpirationSoatReportComponent,
        data: {
          title: 'Soat',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN', 'ROLE_USER', 'ROLE_SELLER']
        },
        canActivate: [RoleAuthGuard]
      },
      {
        path: 'placa',
        component: VehicleByLicensePlateReportComponent,
        data: {
          title: 'Placa',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN', 'ROLE_USER']
        },
        canActivate: [RoleAuthGuard]
      },
      {
        path: 'actividad',
        component: ActivitiesByManagerReportComponent,
        data: {
          title: 'Reporte actividad',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN', 'ROLE_SELLER']
        },
        canActivate: [RoleAuthGuard]
      },
      {
        path: 'cruzado-por-ramo',
        component: MixClassOfInsuranceReportComponent,
        data: {
          title: 'Cruzado por ramo',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN', 'ROLE_USER', 'ROLE_SELLER']
        },
        canActivate: [RoleAuthGuard]
      },
      {
        path: 'vencimiento-poliza',
        component: ExpirationPolicyReportComponent,
        data: {
          title: 'Reporte de vencimiento',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN', 'ROLE_USER', 'ROLE_SELLER']
        },
        canActivate: [RoleAuthGuard]
      },
      {
        path: 'produccion',
        component: ProductivityReportComponent,
        data: {
          title: 'Reporte por Producción',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN', 'ROLE_USER', 'ROLE_SELLER']
        },
        canActivate: [RoleAuthGuard]
      },
      {
        path: 'cartera',
        component: PortfolioReportComponent,
        data: {
          title: 'Reporte de Cartera',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN', 'ROLE_USER', 'ROLE_SELLER']
        },
        canActivate: [RoleAuthGuard]
      },
      {
        path: 'comisiones',
        component: CommissionReportComponent,
        data: {
          title: 'Reporte de Comisiones',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN', 'ROLE_USER']
        },
        canActivate: [RoleAuthGuard]
      },
      {
        path: 'comisiones-por-aseguradora',
        component: CommissionsByInsurerComponent,
        data: {
          title: 'Comisiones por aseguradora',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN', 'ROLE_USER']
        },
        canActivate: [RoleAuthGuard]
      },
      {
        path: 'comisiones-por-vendedor',
        component: CommissionsBySellerComponent,
        data: {
          title: 'Comisiones por vendedor',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN', 'ROLE_USER']
        },
        canActivate: [RoleAuthGuard]
      },
      {
        path: 'indicador-actividad',
        component: ActivitiesIndicatorComponent,
        data: {
          title: 'Indicador de actividades',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN']
        },
        canActivate: [RoleAuthGuard]
      }
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  providers: [RoleAuthGuard],
  exports: [RouterModule]
})
export class ReportsRoutingModule {



}
