import {Component, Input, OnInit} from '@angular/core';
import {ActivityModel} from "../../../models/entities/activity";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ViewActivityComponent} from "../../activities/view-activity/view-activity.component";

@Component({
  selector: 'app-activity-card',
  templateUrl: './activity-card.component.html',
  styleUrls: ['./activity-card.component.scss']
})
export class ActivityCardComponent implements OnInit {

  @Input() activity: ActivityModel;
  constructor(
      private modalService: NgbModal
  ) { }

  ngOnInit() {
  }

  openActivityModal(evt) {
    evt.preventDefault();
    const modal = this.modalService.open(ViewActivityComponent, {
      windowClass: 'modal', backdrop: 'static', size: 'lg'
    });
    modal.componentInstance.activity = this.activity;
    modal.componentInstance.passEntry.subscribe(result=> {
      if(result === 'close'){
        modal.dismiss();
      }
    });
  }
}
