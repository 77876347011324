import {CompanyModel} from "./company-model";
import {UserModel} from "./user-model";

export class CommissionBillModel {
    id: number;
    billNumber: string;
    createdAt: Date;
    company: CompanyModel;
    seller: UserModel;
    amount: number;
    creator: UserModel;
    status: string;
    details: any[];

    constructor() {
        this.id = null;
        this.billNumber = '';
        this.createdAt = null;
        this.company = null;
        this.seller = null;
        this.creator = null;
        this.amount = 0;
        this.status = '';
        this.details = [];
    }
}