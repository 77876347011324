import {CommissionBillModel} from "./commission-bill-model";

export class CommissionModel {
    id: any;
    installmentId: any;
    administrationPercentage: string;
    agencyPercentage: string;
    attachedId: any;
    policyId: any;
    classOfInsuranceId: any;
    insurerId: any;
    insuredId: any;
    premium: any;
    sellerUserId: any;
    createdDate: string;
    commissionCalculated: any;
    commissionAdministrationCalculated: any;
    premiumCalculated: any;
    retefuentePercentageCalculated: any;
    icaPercentageCalculated: any;
    creePercentageCalculated: any;
    ivaCalculated: any;
    bill: CommissionBillModel;
    reteIva: any;

    public constructor() {
        this.id = null;
        this.installmentId = '';
        this.administrationPercentage = '';
        this.agencyPercentage = '';
        this.attachedId = null;
        this.policyId = null;
        this.classOfInsuranceId = null;
        this.insurerId = null;
        this.insuredId = null;
        this.premium = '';
        this.sellerUserId = null;
        this.createdDate = '';
        this.commissionCalculated = '';
        this.commissionAdministrationCalculated = '';
        this.premiumCalculated = '';
        this.retefuentePercentageCalculated = '';
        this.icaPercentageCalculated = '';
        this.creePercentageCalculated = '';
        this.ivaCalculated = '';
        this.reteIva = '';
        this.bill = null;
    }
}
