import { InstallmentModel } from './installment-model';
import { UserModel } from './user-model';
export class CollectionModel {
    id: string;
    installmentId: InstallmentModel;
    paymentDate: string;
    concept: string;
    paymentMode: string;
    creatorId: UserModel;
    paymentValue: string;
    checkNumber: string;
    accountNumber: string;
    financial: string;
    lastUpdateId: string;
    createdDate: any;
    bank: string;
    lastUpdateDate: string;

    public constructor() {
        this.id = '';
        this.installmentId = null;
        this.paymentDate = '';
        this.concept = '';
        this.paymentMode = 'Cheque';
        this.creatorId = new UserModel();
        this.paymentValue = '';
        this.checkNumber = '';
        this.accountNumber = '';
        this.bank = '';
        this.financial = '';
        this.lastUpdateId = null;
        this.createdDate = new Date().toISOString();
        this.lastUpdateDate = '';
    }
}