import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-activities-indicator-report-modal',
  templateUrl: './activities-indicator-report-modal.component.html',
  styleUrls: ['./activities-indicator-report-modal.component.scss']
})
export class ActivitiesIndicatorReportModalComponent implements OnInit {

  @Input() userName: string;
  @Input() pieChartData;
  @Input() lineChart1Data;
  @Input() lineChart2Data;
  @Input() assigned;
  @Input() delayed;
  @Input() pending;
  @Input() onTime;
  @Input() endedInADayAvg;
  @Input() newInADayAvg;
  @Input() endedTicketsPerType;
  @Input() newTicketsPerType;
  @Input() avgTime;
  @Input() typesInReport;
  @Input() types;

  @ViewChild('pdf') pdf;

  showPDFButton = false;

  pieChartType = 'pie';
  pieChartColors: any[] = [{ backgroundColor: ["rgba(0, 157, 160, 0.8)", "rgba(28, 188, 216, 0.8)", "rgba(255, 141, 96, 0.8)"] }];
  pieChartOptions: any = {
    animation: true,
    responsive: true,
    maintainAspectRatio: false
  };

  // Data for Line Chart 1
  lineChartColors: Array<any> = [
    {
      fill: false,
      borderDash: [5, 5],
      borderColor: "#9C27B0",
      pointBorderColor: "#9C27B0",
      pointBackgroundColor: "#FFF",
      pointBorderWidth: 2,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
    },
    {
      fill: false,
      borderDash: [5, 5],
      borderColor: "#00A5A8",
      pointBorderColor: "#00A5A8",
      pointBackgroundColor: "#FFF",
      pointBorderWidth: 2,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
    }
  ];
  lineChartOptions: any = {
    animation: {
      duration: 1000, // general animation time
      easing: 'easeOutBack'
    },
    hover: {
      animationDuration: 1000, // duration of animations when hovering an item
      mode: 'label'
    },
    responsiveAnimationDuration: 1000, // animation duration after a resize
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      position: 'bottom',
    },
    scales: {
      xAxes: [{
        display: true,
        gridLines: {
          color: "#f3f3f3",
          drawTicks: false,
        },
        scaleLabel: {
          display: true,
          labelString: 'Month'
        }
      }],
      yAxes: [{
        display: true,
        gridLines: {
          color: "#f3f3f3",
          drawTicks: false,
        },
        scaleLabel: {
          display: true,
          labelString: 'Value'
        }
      }]
    },
    title: {
      display: false,
      text: ''
    }
  };

  constructor(
      private activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.showPDFButton = true;
    }, 1000)
  }

  getKeys(obj) {
    return Object.keys(obj);
  }

  getAvg(list) {
    let total = 0;
    for (let i = 0; i < list.length; i++) {
      total += list[i];
    }
    return total / list.length
  }

  exportPdf() {
    this.pdf.saveAs(`Indicadores actividad ${this.userName}.pdf`);
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  normalizeText(text) {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
  }
}
