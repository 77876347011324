import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { SearchClientModel } from 'models/types/search-client-model';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import {TokenStorageService} from "../../shared/storage-services/token-storage.service";

@Component({
  selector: 'app-searching-clients',
  templateUrl: './searching-clients.component.html',
  styleUrls: ['./searching-clients.component.scss']
})
export class SearchingClientsComponent implements OnInit {
  @Output() passEntry = new EventEmitter<any>();

  modelSeacrhClient = new SearchClientModel();
  isSeller = false;

  constructor(
      private messageService: InfoMessagesService,
      private tokenStorage: TokenStorageService
  ) {}

  ngOnInit() {
    if (this.tokenStorage.getAuthorities() === 'ROLE_SELLER') {
      this.isSeller = true;
      this.modelSeacrhClient.sellerName = this.tokenStorage.getCompleteName();
    }
  }

  submitForm() {
    if(this.modelSeacrhClient.clientName === '' && this.modelSeacrhClient.documentNumberClient === '' &&
        this.modelSeacrhClient.sellerName === '' && this.modelSeacrhClient.gender === '') {
      this.messageService.getInfoMessagePersonalized(
        'warning',
        '',
        'Los filtros están vacíos '
      );  
    } 
    else {
      this.reportOfSon();
    }
  }

  // borra el contenido de los inputi por id
  cleanFilters() {
    $('#clientName').val('');
    $('#sellerName').val('');
    $('#documnetNumberClient').val('');
    $('#gender').val('');

    this.modelSeacrhClient = new SearchClientModel();
    this.passEntry.emit(1);
  }

  reportOfSon() {
    this.passEntry.emit(this.modelSeacrhClient);
  }
}
