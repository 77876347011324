import { ExportExcelService } from 'app/shared/excel/export-excel.service';
import { PageDataModel } from './../../../models/utilities/page-data-model';
import { Input } from '@angular/core';
import { BudgetModel } from 'models/entities/budget-model';
import { AttachedInfoModel } from 'models/entities/attached-info-model';
import { CruAttachedComponent } from './../cru-attached/cru-attached.component';
import { AdminPrincipalRuPoliciesComponent } from './../admin-principal-ru-policies/admin-principal-ru-policies.component';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { InfoMessagesService } from './../../shared/messages/info-messages.service';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { Component, OnInit } from '@angular/core';
import { PolicyModel } from 'models/entities/policy-model';
import { SearchPolicyModel } from 'models/utilities/search-policy-model';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import swal from 'sweetalert2';
@Component({
  selector: 'app-list-policies',
  templateUrl: './list-policies.component.html',
  styleUrls: ['./list-policies.component.scss']
})
export class ListPoliciesComponent implements OnInit {

  @Input() clientId = 0;
  public listPolicies: Array<any> = new Array();
  listPoliciesAux: Array<any> = new Array();
  public columnsPolicy = [
    { prop: 'policy.id', width: 100 },
    { prop: 'policy.sellerUserId.completeName', width: 200, name: 'Vendedor' },
    { prop: 'policy.clientId.documentNumber', width: 200, name: 'Documento tomador' },
    { prop: 'policy.clientId.nameOrBusinessName', width: 300, name: 'Nombre tomador' },
    { prop: 'policy.insurerId.name', width: 300, name: 'Aseguradora' },
    { prop: 'policy.validUntil', width: 150, name: 'Vence' },
    { prop: 'policy.state', width: 150, name: 'Estado' },
    { prop: 'policy.policyMather', width: 150, name: 'Póliza madre' },
    { prop: 'policy.classOfInsuranceId.name', width: 300, name: 'Ramo' }
  ];
  numberOfPages: any = 0;
  page = new PageDataModel();
  urlInConsult = '';
  @Input() searchModel: SearchPolicyModel = new SearchPolicyModel();
  isSearching = false;
  canShowLoading = false;
  isSeller = false;

  constructor(
    private crudService: CrudServiceService,
    private messageService: InfoMessagesService,
    private routerService: Router,
    private excelService: ExportExcelService,
    private modalService: NgbModal,
    private tokenStorage: TokenStorageService
  ) {
    this.page.pageNumber = 0;
    this.page.size = 10;
  }

  searchInPolicy(page) {
    this.isSearching = true;
    setTimeout(() => {
      const searchModelToSent: SearchPolicyModel = { ... this.searchModel };
      const selectedExpeditionIniDate = searchModelToSent.expeditionDate.split('-');
      searchModelToSent.expeditionDate = new Date(selectedExpeditionIniDate[0],
        Number(selectedExpeditionIniDate[1]) - 1, selectedExpeditionIniDate[2]).toISOString();

      const selectedExpeditionFinalDate = searchModelToSent.expirationDate.split('-');
      searchModelToSent.expirationDate = new Date(selectedExpeditionFinalDate[0],
        Number(selectedExpeditionFinalDate[1]) - 1, selectedExpeditionFinalDate[2]).toISOString();

      const pathListPoliciesUrl = 'poliza/filtrar-lista-polizas';
      this.listPolicies = new Array();
      this.crudService.putModelParams(pathListPoliciesUrl, searchModelToSent, {
        page
      }).subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.listPolicies = genericResponse.answerList;
            this.page.totalElements = genericResponse.variable;
            this.page.pageNumber = page;
          } else {
            this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar las polizas de su empresa',
              'Problema consultando las polizas');
          }
        },
        error => {
          this.messageService.getInfoMessageError();
          console.error('Error al cargar las polizas del sistema: ' + JSON.stringify(error))
        }
      );
    }, 500);
  }

  ngOnInit() {
    if (this.tokenStorage.getAuthorities() === 'ROLE_SELLER') {
      this.isSeller = true;
    }
    if (this.clientId === 0) {
      const entity = this.isSeller ? 'vendedor' : 'mi-empresa';
      this.urlInConsult = 'poliza/listar-polizas-por-' + entity + '?page=';
      this.setPage({ offset: 0 });
    } else {
      this.urlInConsult = 'poliza/listar-polizas-por-cliente?clientId=' + this.clientId + '&page=';
      this.setPage({ offset: 0 });
    }
  }

  configListPolicies(page) {
    const pathListPoliciesUrl = this.urlInConsult + page;
    this.listPolicies = new Array();
    this.listPoliciesAux = new Array();
    this.crudService.getModel(pathListPoliciesUrl).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.listPolicies = genericResponse.answerList;
          this.page.totalElements = genericResponse.variable;
          this.page.pageNumber = page;
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar las polizas de su empresa',
            'Problema consultando las polizas');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar las polizas del sistema: ' + JSON.stringify(error))
      }
    );
  }

  setPage(pageInfo) {
    if (!this.isSearching) {
      this.configListPolicies(pageInfo.offset);
    } else {
      this.searchInPolicy(pageInfo.offset);
    }
  }

  viewPolicy(policyTo: PolicyModel) {
    const modal = this.modalService.open(AdminPrincipalRuPoliciesComponent, {
      windowClass: 'mediumModal', size: 'lg', backdrop: 'static'
    });
    modal.componentInstance.policyModel = policyTo;
    modal.componentInstance.passEntry.subscribe(result => {
      if (result !== null) {
        modal.dismiss();
        // this.configListPolicies(0);
      } else {
        modal.dismiss();
        // this.configListPolicies(0);
      }
    });
  }

  createPolicy() {
    this.routerService.navigateByUrl('polizas/create-poliza');
  }

  generateAttached(policyModel: PolicyModel) {
    const modal = this.modalService.open(CruAttachedComponent, {
      windowClass: 'mediumModal', size: 'lg', backdrop: 'static'
    });
    const attachedModelToSend: AttachedInfoModel = new AttachedInfoModel();
    attachedModelToSend.valuable = true;
    attachedModelToSend.type = 'CREATE';
    attachedModelToSend.policyId = policyModel;
    modal.componentInstance.attachedModel = attachedModelToSend;
    modal.componentInstance.budgetModel = new BudgetModel();
    modal.componentInstance.policyModel = policyModel;
    modal.componentInstance.fromList = true;
    modal.componentInstance.passEntry.subscribe(result => {
      if (result !== null) {
        modal.dismiss();
        this.configListPolicies(0);
      } else {
        modal.dismiss();
      }
    });
  }


  exportAsXLSX(): void {
    this.canShowLoading = true;
    if (this.isSearching) {
      this.exportTotalFilterList();
    } else {
      this.exportTotalListWF();
    }
  }

  exportTotalFilterList() {
    const selectedExpeditionIniDate = this.searchModel.expeditionDate.split('-');
    this.searchModel.expeditionDate = new Date(selectedExpeditionIniDate[0],
      Number(selectedExpeditionIniDate[1]) - 1, selectedExpeditionIniDate[2]).toISOString();

    const selectedExpeditionFinalDate = this.searchModel.expirationDate.split('-');
    this.searchModel.expirationDate = new Date(selectedExpeditionFinalDate[0],
      Number(selectedExpeditionFinalDate[1]) - 1, selectedExpeditionFinalDate[2]).toISOString();

    const pathListPoliciesUrl = 'poliza/filtrar-lista-polizas-wp';
    this.crudService.putModelParams(pathListPoliciesUrl, this.searchModel, {}).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.excelService.exportAsExcelFileJson({ ...genericResponse.answerList }, 'Pólizas' + this.tokenStorage.getCompanyName(),genericResponse.answerList.length);
          this.canShowLoading = false;
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudo exportar la lista a excel',
            'Problemas en la exportación');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar las polizas del sistema: ' + JSON.stringify(error))
      }
    );
  }

  exportTotalListWF() {
    let pathListPoliciesUrl = '';
    if (this.clientId === 0) {
      pathListPoliciesUrl = 'poliza/listar-polizas-por-mi-empresa-wp'
    } else {
      pathListPoliciesUrl = 'poliza/listar-polizas-por-cliente-wp?clientId=' + this.clientId
    }
    this.crudService.getModel(pathListPoliciesUrl).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.excelService.exportAsExcelFileJson({ ...genericResponse.answerList }, 'Pólizas' + this.tokenStorage.getCompanyName(),genericResponse.answerList.length);
          this.canShowLoading = false;
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudo exportar la lista a excel',
            'Problemas en la exportación');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar las polizas del sistema: ' + JSON.stringify(error))
      }
    );
  }

  cancelPolicy(policyToCancel: PolicyModel) {
    swal({
      title: '¿Está seguro que desea cancelar la póliza?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, cancelar!',
      cancelButtonText: 'Volver'
    }).then((result) => {
      if (result.value) {
        policyToCancel.state = 'DELETED';
        this.crudService.createModel('poliza/crear-poliza', policyToCancel).subscribe(
          (genericResponse: GenericResponseModel) => {
            if (genericResponse.code === 200) {
              this.messageService.getInfoMessagePersonalized('success', 'La póliza fue cancelada con exito',
                'Proceso completado');
              this.setPage({ offset: 0 });
            } else {
              this.messageService.getInfoMessagePersonalized('warning', 'No se pudo eliminar la póliza con exito',
                'Problema eliminando la póliza');
            }
          }, (error) => {
            this.messageService.getInfoMessageError();
            console.error('Error, no se pudo eliminar la póliza con exito: ' + JSON.stringify(error))
          }
        )
      }
    })
  }


}
