import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { PlansModel } from './../../../models/entities/plans-model';
import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';

@Component({
  selector: 'app-create-plan',
  templateUrl: './create-plan.component.html',
  styleUrls: ['./create-plan.component.scss']
})
export class CreatePlanComponent implements OnInit {

  public planInfo = new PlansModel()
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  public isCreatedPlan = true;
  errorMessage: any;
  form: any = {};
  @ViewChild('f') createModuleForm: NgForm;

  constructor(
    private messageServices: InfoMessagesService,
    private crudServices: CrudServiceService,
    private modalService: NgbModal) { }

  ngOnInit() {
  }

  onSubmit() {
    this.planInfo.name = this.form.name;
    this.planInfo.description = this.form.description;
    this.planInfo.value = this.form.value;
    this.planInfo.lastUpdateDate = new Date().toUTCString();
    this.crudServices.createModel('plan/crear', this.planInfo).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.isCreatedPlan = true;
          this.modalService.dismissAll();
          this.messageServices.getInfoMessageCreate().then(
            (result) => {
              this.passEntry.emit('Plan Creado');
              this.isCreatedPlan = true;
              this.modalService.dismissAll();
            }
          )
        }
        if (genericResponse.code === 400) {
          this.messageServices.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se creó el plan')
        }
      },
      error => {
        this.isCreatedPlan = false;
        this.errorMessage = error.error.info;
        console.error(error);
        this.messageServices.getInfoMessageBadInternet();
      }
    );
  }

  closeWindow() {
    this.modalService.dismissAll();
  }


}
