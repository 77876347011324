import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PrintingRoutingModule } from './printing-routing.module';
import { RemissionComponent } from './remission/remission.component';
import { PayReportComponent } from './pay-report/pay-report.component';
import { SharedComponentsModule } from 'app/shared/components/shared-components.module';
import {PDFExportModule} from "@progress/kendo-angular-pdf-export";

@NgModule({
  declarations: [RemissionComponent, PayReportComponent],
    imports: [
        CommonModule,
        PrintingRoutingModule,
        FormsModule,
        SharedComponentsModule,
        PDFExportModule
    ],
  exports: [RemissionComponent, PayReportComponent]
})
export class PrintingModule { }
