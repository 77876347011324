import { UserModel } from './user-model';
import { ActivityModel } from './activity';

export class ActivityManagmentModel{
        id:String ;
		activityId:ActivityModel;	
		creatorId:UserModel;
        coment:string;
        createdDate:Date;

        
        constructor(){
        this.id = '';
        this.activityId=new ActivityModel();
        this.creatorId = new UserModel();
        this.coment='';
        this.createdDate=new Date();
        

        }
}