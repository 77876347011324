import {SharedService} from './../../shared/shared.service';
import {FirebaseStorageService} from 'app/shared/firebase/firebase-storage.service';
import {Component, OnInit, Input, EventEmitter, Output} from '@angular/core';
import {CrudServiceService} from '../../shared/backend/cruds/crud-service.service';
import {TokenStorageService} from '../../shared/storage-services/token-storage.service';
import {InfoMessagesService} from '../../shared/messages/info-messages.service';
import {formatDate} from '@angular/common';
import swal from 'sweetalert2';
import {DocumentTypeModel} from 'models/entities/document-type-model';
import {GenericResponseModel} from 'models/utilities/generic.response.model';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CompanyModel} from 'models/entities/company-model';

@Component({
    selector: 'app-company-configs',
    templateUrl: './company-configs.component.html',
    styleUrls: ['./company-configs.component.scss']
})
export class CompanyConfigsComponent implements OnInit {

    @Input() companyModel = new CompanyModel();
    url: any;
    error: any;
    imagePreview: any;
    urlAux: any;
    readonly companyAux: any;
    modal = false;
    documentTypeList: Array<DocumentTypeModel> = new Array();
    public activeModal: NgbActiveModal;
    file: File;
    @Output() emit: EventEmitter<any> = new EventEmitter();
    dataload = false;

    constructor(
        private crudService: CrudServiceService,
        private tokenStorage: TokenStorageService,
        private messageService: InfoMessagesService,
        private firebaseStorageService: FirebaseStorageService,
        private sharedService: SharedService
    ) {
    }

    ngOnInit() {


        this.configPage();

        this.getDocumentsTypes();

    }

    getDocumentsTypes() {
        const pathCompanyUrl = 'tipos-de-documento/ver-por-objetivo?objective=Empresa';
        this.crudService.getModel(pathCompanyUrl).subscribe(
            (data: GenericResponseModel) => {
                if (data.code === 200) {
                    this.documentTypeList = data.answerList
                    this.dataload = true;
                } else {
                    this.messageService.getInfoMessageError();
                }
            },
            error => {
                this.messageService.getInfoMessageError();
                console.error('Error al cargar las cuentas bancarias: ' + JSON.stringify(error))
            }
        );
    }

    configPage() {

        if (this.tokenStorage.getAuthorities() !== 'ROLE_SUPER') {
            const pathCompanyUrl = 'empresa/compania/ver-compania?id=' + this.tokenStorage.getCompanyId();

            this.crudService.getModel(pathCompanyUrl).subscribe(
                data => {

                    this.companyModel = data.genericObject;
                    // this .companyModelAux = data.genericObject;
                    this.sharedService.setCompanyModel(this.companyModel);
                    this.url = this.companyModel.logoUrl;
                    this.urlAux = this.companyModel.logoUrl;

                },
                error => {
                    this.messageService.getInfoMessageError();
                    console.error('Error al cargar la compañia: ' + error)
                }
            );
        } else {
            this.companyModel = new CompanyModel();
        }
    }

    close() {
    }

    onSubmit() {
        swal({
            title: '¿Está seguro que desea aceptar la configuración',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sí, Actualizar',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.value) {
                const path = 'empresa/compania/actualizar-compania';
                const date = formatDate(new Date(), 'yyyy-MM-dd', 'en');
                // aux.lastUpdateId.id = '1';
                this.companyModel.lastUpdateDate = date;
                this.companyModel.lastUpdateId = this.tokenStorage.getCompanyId();


                this.companyModel.creatorId = this.tokenStorage.getCompanyId();


                this.crudService.putModel(path, this.companyModel).subscribe(
                    data => {

                        if (data.code === 200) {
                            this.companyModel = data.genericObject;
                            if (this.file != null) {

                                this.uploadLogoCompany(this.file, this.companyModel.id);
                            } else {
                                this.messageService.getInfoMessageCreate();
                                this.emit.emit(this.companyModel);
                                this.activeModal.dismiss();
                                this.configPage();

                            }

                        }
                    },
                    error => {
                        console.log('ERRROR' + JSON.stringify(error))
                        this.messageService.getInfoMessageError();
                    }
                );
                this.configPage();
            } else {
                // console.log('ENtro a cancelar')
                // this .company = this .companyAux;
            }
        });
    }


    uploadLogoCompany(file, id) {
        this.firebaseStorageService.uploadFile(id + '/logos', 'logo', file).then((result) => {
            if (result) {
                this.firebaseStorageService.loadFile(id + '/logos', 'logo').then((url) => {

                    if (this.tokenStorage.getAuthorities() !== 'ROLE_SUPER') {
                        document.querySelector('img').src = url + new Date().getTime()
                    }
                    this.companyModel.logoUrl = url;
                    this.updateCompanyLogo();

                }).catch((err) => {
                    console.log(err)
                    document.querySelector('img').src = '../../../assets/img/nologo.jpg';
                })
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    updateCompanyLogo() {

        this.crudService.putModel('empresa/compania/actualizar-compania', this.companyModel).subscribe(
            result => {

                if (result.code === 200) {
                    this.messageService.getInfoMessageCreate();
                    this.emit.emit(this.companyModel);
                    this.activeModal.dismiss();
                    this.configPage();
                }
            }
        )
    }

    readUrl(inputValue: any): void {
        const img = new Image();
        img.src = window.URL.createObjectURL(inputValue.target.files.item(0));

        img.onload = () => {
            window.URL.revokeObjectURL(img.src);
            if (img.naturalWidth <= 200 && img.naturalHeight <= 200) {
                this.file = inputValue.target.files[0];
                var myReader: FileReader = new FileReader();

                myReader.onloadend = (e) => {
                    this.url = myReader.result;
                    this.companyModel.logoUrl = myReader.result;
                }
                myReader.readAsDataURL(this.file);
            } else {
                this.messageService.getInfoMessagePersonalized('warning', 'El tamaño de la imagen debe ser menor o igual a 200 x 200 px', 'Imagen fuera de proporciones');
            }
        }
    }

    handleInputMaxSize(evt) {
        const val = evt.target.value;
        console.log('val', val);
        const str = val.replace(/[^\d]/g, "");
        evt.target.value = str;
        this.companyModel.maxDocumentSize = str;
    }
}
