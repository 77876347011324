import { Component, OnInit, OnChanges, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import swal from 'sweetalert2';
import { GroupLifeRiskModel } from 'models/entities/group-life-risk-model';

@Component({
  selector: 'app-group-life-risk-list',
  templateUrl: './group-life-risk-list.component.html',
  styleUrls: ['./group-life-risk-list.component.scss']
})
export class GroupLifeRiskListComponent implements OnInit, OnChanges {
  @Input() policyId: any = 0;
  @Output() emitterLife: EventEmitter<any> = new EventEmitter();
  @Output() emitterActivityRecord: EventEmitter<any>;
  @Input() successfulUpdateFlag: any; // Recibe la bandera de que un riesgo fue actualizado correctamente para recargar la página


  rows = [];
  temp: string[];
  errorMessage: string;
  dato: any;
  datoAux = new Array();

  columns = [
    { name: 'id', prop: 'id'},
    { name: 'policyId', prop: 'policyId'},
    { name: 'clientId', prop: 'clientId'},
    { name: 'state', prop: 'state'},
    { name: 'gender', prop: 'gender'},
    { name: 'occupation', prop: 'occupation'},
    { name: 'height', prop: 'height'},
    { name: 'weight', prop: 'weight'},
    { name: 'validSince', prop: 'validSince'},
    { name: 'validUntil', prop: 'validUntil'},
    { name: 'insuredValue', prop: 'insuredValue'},
  ];

  constructor(
    private crudService: CrudServiceService,
    private messageService: InfoMessagesService
  ) {
    this.emitterActivityRecord = new EventEmitter();
   }

  ngOnInit() {
    this.getLifeRisks();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.successfulUpdateFlag.currentValue) {
      setTimeout(() => {
        this.ngOnInit();
      }, 500);

    }
  }

  getLifeRisks() {
    if (this.policyId !== '') {
      setTimeout(() => {
        const path = 'riesgo-vida-grupo/listar-riesgos-vida-grupo-por-poliza?policyId=' + this.policyId;
        this.crudService.getModel(path).subscribe(
          (genericResponse: GenericResponseModel) => {
            if (genericResponse.code === 200) {
              this.datoAux = new Array();
              const risksAux = genericResponse.answerList;
              risksAux.forEach(element => {
                if (element.state === 'INCLUIDO') {
                  this.datoAux.push(element);
                }
              });
              risksAux.forEach(element => {
                if (element.state === 'EXCLUIDO') {
                  this.datoAux.push(element);
                }
              });
              this.dato = this.datoAux
              this.temp = this.dato;
              this.rows = this.dato;
            } else {
              this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar los riesgos',
               'Problema consultando los riesgos');
            }
          },
          error => {
            console.error('Error al cargar los riesgos: ' + JSON.stringify(error))
            this.messageService.getInfoMessagePersonalized('warning', error.error.answer + '', 'Error al cargar los riesgos');
          }
        );
      }, 500);
    }
  }

  deleteRisk(id) {
    swal({
      title: '¿Está seguro que desea eliminar este riesgo?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this .deleteRiskAction(id);
      }
    });
  }

  deleteRiskAction( id ) {
    const path = 'riesgo-vida-grupo/eliminar-riesgo-vida-grupo?id=' + id;
    this .crudService.deleteModel(path).subscribe(
      (data: GenericResponseModel) => {
        this.sendActivityRecord('Se eliminó el riesgo con No. Registro: ' + id);
        this.messageService.getInfoMessageDelete();
       this.getLifeRisks();
      },
      error => {
        this.messageService.getInfoMessagePersonalized('warning', error.error.answer + '', 'Problema con la eliminación');
        console.error('Error al borrar Riesgo Salud', error);
      }
    );
  }

  excludeRisk(lifeToExclude: GroupLifeRiskModel) {
    const path = 'riesgo-vida-grupo/crear-riesgo-vida-grupo';
    lifeToExclude.state = 'EXCLUIDO';
    this.crudService.createModel(path, lifeToExclude).subscribe(
      (data: GenericResponseModel) => {
        if (data.code === 200) {
          this.sendActivityRecord('Se excluyó el riesgo con No. Registro: ' + lifeToExclude.id);
          this.messageService.getInfoMessagePersonalized('success', 'Se excluyó el riesgo de la póliza', 'Exclusión exitosa')
          this.ngOnInit();
        } else if (data.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', data.answer, 'No se creó el riesgo')
        }
      },
      error => {
        this.messageService.getInfoMessagePersonalized('error', error.error.answer + '', 'Error');
        console.error('El error es ', JSON.stringify(error));
      }
    );
  }

  getLifeToEdit(lifeToEdit: GroupLifeRiskModel) {
    this.emitterLife.emit(lifeToEdit);
  }

  sendActivityRecord(activity: any) {
    this.emitterActivityRecord.emit(activity);
  }

}
