import { ViewActivityComponent } from './view-activity/view-activity.component';
import { CreateActivityManagmentComponent } from './create-activity-managment/create-activity-managment.component';
import { CruClientComponent } from './cru-client/cru-client.component';
import { ManageClientsComponent } from './manage-clients/manage-clients.component';
import { ListActivitysComponent } from './list-activitys/list-activitys.component';
import { CreateActivityComponent } from './create-activity/create-activity.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoleAuthGuard } from 'app/shared/auth/roleAuth-guard.service';
import { ManagmentActivityComponent } from './managment-activity/managment-activity.component';
import { ListActivityManagmentComponent } from './list-activity-managment/list-activity-managment.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'crear-actividad',
        component: CreateActivityComponent,
        data: {
          title: 'Crear actividad',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN','ROLE_USER', 'ROLE_SELLER']
        },
        canActivate: [RoleAuthGuard]
      },
      {
        path: 'gestion-actividades',
        component: ManagmentActivityComponent,
        data: {
          title: 'Gestion actividades',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN','ROLE_USER', 'ROLE_SELLER']
        },
        canActivate: [RoleAuthGuard]
      },
      {
        path: 'listar-actividades',
        component: ListActivitysComponent,
        data: {
          title: 'Listar actividades',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN','ROLE_USER', 'ROLE_SELLER']
        },
        canActivate: [RoleAuthGuard]
      },
      {
        path: 'gestion-clientes',
        component: ManageClientsComponent,
        data: {
          title: 'Gestion Clientes',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN','ROLE_USER']
        },
        canActivate: [RoleAuthGuard]
      },
      {
        path: 'crear-cliente',
        component: CruClientComponent,
        data: {
          title: 'Crear Cliente',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN','ROLE_USER']
        },
        canActivate: [RoleAuthGuard]
      },
      {
        path: 'comentario-actividad',
        component: CreateActivityManagmentComponent,
        data: {
          title: 'Comentar actividad',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN','ROLE_USER', 'ROLE_SELLER']
        },
        canActivate: [RoleAuthGuard]
      },
      {
        path: 'listar-comentarios',
        component: ListActivityManagmentComponent,
        data: {
          title: 'Listar comentarios',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN','ROLE_USER', 'ROLE_SELLER']
        },
        canActivate: [RoleAuthGuard]
      },      
      {
        path: 'ver-actividad',
        component: ViewActivityComponent,
        data: {
          title: 'Ver actividad',
          expectedRole: ['ROLE_SUPER', 'ROLE_ADMIN','ROLE_USER', 'ROLE_SELLER']
        },
        canActivate: [RoleAuthGuard]
      },

    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ActivitiesRoutingModule { }
