import { CompanyModel } from 'models/entities/company-model';
import { ActivityStateModel } from './activity-state.model';
import { ActivityTypeModel } from 'models/entities/activity-type.model';
import { UserModel } from './user-model';

export class ActivityModel{
        id:String ;
		activity:String;
		relationalEntityId:any;
		relationalEntityType:any;
		responsibleUserId:UserModel;
		creatorId:UserModel;
		activityTypeId:ActivityTypeModel;
		activityState: string;
		companyId:CompanyModel;
		lastUpdateDate:Date;
		lastUpdateId:UserModel;
		expireDate:Date;
		sequence: number;
		isPrivate: boolean;
		deletedAt: Date;
		endedAt: Date;
		createdDate: Date;
        
        constructor(){
			this.id = '';
			this.activity = '';
			this.relationalEntityId = null;
			this.relationalEntityType = '';
			this.responsibleUserId = new UserModel();
			this.creatorId = new UserModel();
			this.activityTypeId = new ActivityTypeModel();
			this.activityState = '';
			this.companyId = new CompanyModel();
			this.lastUpdateDate = new Date();
			this.lastUpdateId = new UserModel();
			this.expireDate=new Date();
			this.sequence = null;
			this.isPrivate = false;
			this.deletedAt = null;
			this.endedAt = null;
			this.createdDate = null;
        }
}