import { CountryModel } from "./country-model";

export class DepartmentModel {
    id: string;
    name: string;
    countryId:  CountryModel;
    indicative: string;

    public constructor() {
        this.id = '';
        this.name = '';
        this.countryId = new CountryModel();
        this.indicative = '';
    }
}
