import { DetailPlanComponent } from './detail-plan/detail-plan.component';
import { CreatePlanComponent } from './create-plan/create-plan.component';
import { ListPlanComponent } from './list-plan/list-plan.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoleAuthGuard } from 'app/shared/auth/roleAuth-guard.service';
import { UpdatePlanComponent } from './update-plan/update-plan.component';
import { ToPairModuleComponent } from './to-pair-module/to-pair-module.component';



const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'listar-planes',
        component: ListPlanComponent,
        data: {
          title: 'listplan',
          expectedRole: ['ROLE_SUPER']
        },
        canActivate: [RoleAuthGuard]
      },
      {
        path: 'createplan',
        component: CreatePlanComponent,
        data: {
          title: 'createplan',
          expectedRole: ['ROLE_SUPER']
        },
        canActivate: [RoleAuthGuard]
      },
      {
        path: 'updateplan',
        component: UpdatePlanComponent,
        data: {
          title: 'updateplan',
          expectedRole: ['ROLE_SUPER']
        },
        canActivate: [RoleAuthGuard]
      },
      {
        path: 'detailplan',
        component: DetailPlanComponent,
        data: {
          title: 'detailplan',
          expectedRole: ['ROLE_SUPER']
        },
        canActivate: [RoleAuthGuard]
      },
      {
        path: 'topairplan',
        component: ToPairModuleComponent,
        data: {
          title: 'topairplan',
          expectedRole: ['ROLE_SUPER']
        },
        canActivate: [RoleAuthGuard]
      }
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  providers: [RoleAuthGuard],
  exports: [RouterModule]
})
export class PlansRoutingModule { }
