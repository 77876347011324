import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClientsTmpRoutingModule } from './clients-tmp-routing.module';
import { ClientRecordComponent } from './client-record/client-record.component';
import { ContainerComponent } from './container/container.component';
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {NgxLoadingModule} from "ngx-loading";

@NgModule({
  declarations: [ClientRecordComponent, ContainerComponent],
    imports: [
        CommonModule,
        ClientsTmpRoutingModule,
        NgxDatatableModule,
        NgxLoadingModule
    ],
    entryComponents: [ClientRecordComponent]
})
export class ClientsTmpModule { }
