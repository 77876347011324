export class PairModuleMenuModel {
    id: string;
    moduleId: any;
    menuId: any;
    createdDate: string;

    public constructor() {
        this.id = '';
        this.moduleId = '';
        this.menuId = '-1';
        this.createdDate = '';
    }
}
