import { Component, OnInit, Input } from '@angular/core';
import { PolicyModel } from 'models/entities/policy-model';
import { AttachedInfoModel } from 'models/entities/attached-info-model';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CruAttachedComponent } from '../cru-attached/cru-attached.component';
import swal from 'sweetalert2'; 
import { PlansModel } from 'models/entities/plans-model';
import { UpdateAttachedComponent } from '../update-attached/update-attached.component';
import { BudgetModel } from 'models/entities/budget-model';
import { BudgetInPolicyComponent } from '../budget-in-policy/budget-in-policy.component';
import {TokenStorageService} from "../../shared/storage-services/token-storage.service";

@Component({
  selector: 'app-list-attached',
  templateUrl: './list-attached.component.html',
  styleUrls: ['./list-attached.component.scss']
}) 
export class ListAttachedComponent implements OnInit {
  @Input() public budgetModel: BudgetModel = new BudgetModel();
  @Input() policyModel: PolicyModel = new PolicyModel();
  attachedList: Array<AttachedInfoModel> = new Array();

  id :  any;
  errorMessage: string;
  attached: AttachedInfoModel;
  attachedColumns = [
    { prop: 'consecutive' },
    { prop: 'createdDate' },
    { prop: 'comments' }
  ]

  isSeller = false;

  constructor(
    private crudService: CrudServiceService,
    private messageService: InfoMessagesService,
    private modalService: NgbModal,
    private tokenStorage: TokenStorageService
  ) { }

  ngOnInit() {
    if (this.tokenStorage.getAuthorities() === 'ROLE_SELLER') {
      this.isSeller = true;
    }
    this.getAttachedList();
  }

  getAttachedList() {
    const pathAttachedListUrl = 'anexo/listar-anexos-por-poliza?policyId=' + this.policyModel.id;
    this.attachedList = new Array();
    this.crudService.getModel(pathAttachedListUrl).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.attachedList = genericResponse.answerList;
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron ',
            'Problema consultando cuotas');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al consultar cuotas: ' + JSON.stringify(error))
      }
    );
  }

  viewAttached(attached: AttachedInfoModel) {
    const modal = this.modalService.open(CruAttachedComponent, {
      windowClass: 'mediumModal', size: 'lg', backdrop: 'static'
    });
    let changesToSend = new Array();
    if (attached.descriptionOfChanges !== '' && attached.descriptionOfChanges !== null) {
      changesToSend = attached.descriptionOfChanges.split('/');
    }
    modal.componentInstance.changesList = changesToSend;
    modal.componentInstance.attachedModel = attached;
    modal.componentInstance.budgetModel = attached.budgetId;
    modal.componentInstance.policyModel = this.policyModel;
    console.log("dio click en ver: ",attached.budgetId);
    modal.componentInstance.readonly = true;
    modal.componentInstance.passEntry.subscribe(result => {
      modal.dismiss();
    });
  }


  deleteAttached(id) {
    console.log(id);
    event.preventDefault();
    swal({
      title: '¿Está seguro que desea eliminar el registro?',
      text: 'No podrá recuperar el registro luego',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '¡Sí, eliminar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.onDelete(id);
      }
    })
  }

  onDelete(id){
    this.crudService.deleteModel('anexo/borrar?id=' + id).subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.ngOnInit();
        } else if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se elimino el anexo')
        }
      },
      error => {
        this.errorMessage = `${error.status}: ${error.error.message}`;
        if (error.error.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', error.error.answer, 'Error eliminando el anexo')
        } else {
          this.messageService.getInfoMessageBadInternet();
        }
      }
    );
  }

  onUpdateAttached(attached: AttachedInfoModel){
     const modal = this.modalService.open(UpdateAttachedComponent, {
            windowClass: 'mediumModal', size: 'lg', backdrop: 'static'
          });
          console.log("dio click en actualizar:", attached.budgetId);
          let changesToSend = new Array();
    if (attached.descriptionOfChanges !== '' && attached.descriptionOfChanges !== null) {
      changesToSend = attached.descriptionOfChanges.split('/');
    }
   
    attached.valuable = true;
    attached.type = 'UPDATE';
    attached.policyId = this.policyModel;
    modal.componentInstance.changesList = changesToSend;
    modal.componentInstance.attachedModel = attached;
    modal.componentInstance.budgetModel = attached.budgetId;
    
    modal.componentInstance.policyModel = this.policyModel;
    modal.componentInstance.readonly = true;
    modal.componentInstance.passEntry.subscribe(result => {
      modal.dismiss();
    });
}


}
