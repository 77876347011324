import { NgForm } from '@angular/forms';
import { InsurerModel } from './../../../models/entities/insurer-model';
import { ClientModel } from 'models/entities/client-model';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { GenericResponseModel } from './../../../models/utilities/generic.response.model';
import { CrudServiceService } from './../../shared/backend/cruds/crud-service.service';
import { ClassOfInsuranceModel } from 'models/entities/class-of-insurance.model';
import {UserModel} from 'models/entities/user-model';
import { ViewChild, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { SearchPolicyModel } from 'models/utilities/search-policy-model';
import { debounceTime, distinctUntilChanged, filter, map, merge } from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import {TokenStorageService} from "../../shared/storage-services/token-storage.service";

@Component({
  selector: 'app-search-policies',
  templateUrl: './search-policies.component.html',
  styleUrls: ['./search-policies.component.scss']
})
export class SearchPoliciesComponent implements OnInit {

  searchModel: SearchPolicyModel = new SearchPolicyModel();
  focusClassOfInsurance$ = new Subject<string>();
  clickClassOfInsurance$ = new Subject<string>(); 
  focusClient$ = new Subject<string>();
  clickClient$ = new Subject<string>();
  focusInsurer$ = new Subject<string>();
  clickInsurer$ = new Subject<string>();
  focusSeller$ = new Subject<string>();
  clickSeller$ = new Subject<string>();
  listClassOfInsurances: Array<ClassOfInsuranceModel> = new Array();
  listClients: Array<ClientModel> = new Array();
  listInsurer: Array<InsurerModel> = new Array();
  listUsers: Array<UserModel> = new Array();
  @Output() searchEmitter: EventEmitter<any> = new EventEmitter();
  @Output() cleanEmitter: EventEmitter<any> = new EventEmitter();
  @ViewChild('instanceClassOfInsurance') instanceClassOfInsurance: NgbTypeahead;
  @ViewChild('instanceClient') instanceClient: NgbTypeahead;
  @ViewChild('instanceInsurer') instanceInsurer: NgbTypeahead;
  @ViewChild('instanceSeller') instanceSeller: NgbTypeahead;
  @ViewChild('formSearch') searchingForm: NgForm;

  isSeller = false;

  constructor(
    private crudService: CrudServiceService,
    private messageService: InfoMessagesService,
    private tokenStorage: TokenStorageService
  ) { }

  ngOnInit() {
    if (this.tokenStorage.getAuthorities() === 'ROLE_SELLER') {
      this.isSeller = true;
      this.searchModel.sellerId = new UserModel();
      this.searchModel.sellerId.id = this.tokenStorage.getId();
      this.searchModel.sellerId.completeName = this.tokenStorage.getCompleteName();
    }
    this.getInsurers();
    this.getClassOfInsurances();
    this.getClients();
    this.getUser();
  }

  getClassOfInsurances() {
    const pathListClassOfInsurancesUrl = 'ramos/listar-ramos';
    this.listClassOfInsurances = new Array();
    this.crudService.getModel(pathListClassOfInsurancesUrl).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          const listClassOfInsurancesAux = genericResponse.answerList;
          listClassOfInsurancesAux.forEach(classOfInsurances => {
            this.listClassOfInsurances.push(classOfInsurances);
          });
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar los tipos de ramos',
            'Problema consultando los tipos de ramos');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar los tipos de ramos: ' + JSON.stringify(error))
      }
    );
  }

  getInsurers() {
    const pathListInsurerUrl = 'empresa/aseguradora/listar-aseguradoras';
    this.listInsurer = new Array();
    this.crudService.getModel(pathListInsurerUrl).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          const listInsurerAux = genericResponse.answerList;
          listInsurerAux.forEach(insurer => {
            this.listInsurer.push(insurer);
          });
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar las aseguradoras',
            'Problema consultando aseguradoras');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar las aseguradoras: ' + JSON.stringify(error))
      }
    );
  }

  getClients() {
    const entity = this.isSeller ? 'vendedor' : 'empresa';
    const pathListClientsUrl = 'cliente/listar-nombre-por-' + entity;
    this.listClients = new Array();
    this.crudService.getModel(pathListClientsUrl).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.listClients = genericResponse.answerList;
          console.log(this.listClients);
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar los clientes',
            'Problema consultando los clientes asociados');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar los clientes asociados' + JSON.stringify(error))
      }
    );
    }

  getUser(){
    const pathListClientsUrl = 'usuarios/listar-todos-los-usuarios';
    this.listUsers = new Array();
    this.crudService.getModel(pathListClientsUrl).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          const listUserAux = genericResponse.answerList;
          listUserAux.forEach(user => {
            this.listUsers.push(user);
           
          });
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar los vendedores asociados a su empresa',
            'Problema consultando los vendedores asociados a su empresa');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar los clientes asociados a su empresa ' + JSON.stringify(error))
      }
    );
  }

  formatter = (object: { name: string }) => object.name;
  formatterClient = (object: { nameOrBusinessName: string }) => object.nameOrBusinessName;
  formatterSeller = (object: { completeName: string }) => object.completeName;

  searchByNameClassOfInsurance = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focusClassOfInsurance$),
      merge(this.clickClassOfInsurance$.pipe(filter(() => !this.instanceClassOfInsurance.isPopupOpen()))),
      map(search => (search === '' ? this.listClassOfInsurances
        : this.listClassOfInsurances.filter(classOfInsurance =>
          classOfInsurance.name.toLowerCase().indexOf(search.toLowerCase()) > -1)).slice(0, 10))
    );

  

  searchByNameClient = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focusClient$),
      merge(this.clickClient$.pipe(filter(() => !this.instanceClient.isPopupOpen()))),
      map(search => (search === '' ? this.listClients
        : this.listClients.filter(client => (client.nameOrBusinessName.toLowerCase().indexOf(search.toLowerCase()) > -1))).slice(0, 10))
    );

   searchByNameSeller = (text$: Observable<string>) =>
   text$.pipe(
     debounceTime(200),
     distinctUntilChanged(),
     merge(this.focusSeller$),
     merge(this.clickSeller$.pipe(filter(() => !this.instanceSeller.isPopupOpen()))),
     map(search => (search === '' ? this.listUsers
       : this.listUsers.filter(seller => (seller.completeName.toLowerCase().indexOf(search.toLowerCase()) > -1) ||
       seller.documentNumber.toLowerCase().indexOf(search.toLowerCase()) > -1)).slice(0, 10))
         );


  searchByNameInsurer = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focusInsurer$),
      merge(this.clickInsurer$.pipe(filter(() => !this.instanceInsurer.isPopupOpen()))),
      map(search => (search === '' ? this.listInsurer
        : this.listInsurer.filter(insurer => insurer.name.toLowerCase().indexOf(search.toLowerCase()) > -1)).slice(0, 10))
    );

  rebuildClient() {
    this.searchModel.clientId = null;
  }

  rebuildClassOfInsurance() {
    this.searchModel.classOfInsuranceId = null;
  }

  rebuildInsurer() {
    this.searchModel.insurerId = null;
  }

  rebuildSeller(){
    this.searchModel.sellerId = null;
  }

  cleanSearch() {
    this.searchModel = new SearchPolicyModel();
    this.cleanEmitter.emit();
    this.searchingForm.form.markAsPristine();
    this.searchingForm.form.markAsUntouched();
    this.searchingForm.form.updateValueAndValidity();
  }

  onSearch() {
    try {
      if (this.searchModel.clientId.id !== undefined) {
        console.log('Si seleccionó');
      } else {
        this.searchModel.clientId = null;
      }
    } catch (e) {
      this.searchModel.clientId = null;
    }

    try {
      if (this.searchModel.classOfInsuranceId.id !== undefined) {
        console.log('Si seleccionó');
      } else {
        this.searchModel.classOfInsuranceId = null;
      }
    } catch (e) {
      this.searchModel.classOfInsuranceId = null;
    }

    try {
      if (this.searchModel.insurerId.id !== undefined) {
        console.log('Si seleccionó');
      } else {
        this.searchModel.insurerId = null;
      }
    } catch (e) {
      this.searchModel.insurerId = null;
    }

    try {
      if (this.searchModel.sellerId.id !== undefined) {
        console.log('Si seleccionó');
      } else {
        this.searchModel.sellerId = null;
      }
    } catch (e) {
      this.searchModel.sellerId = null;
    }


    this.searchEmitter.emit(this.searchModel);
  }

}
