import { CrudServiceService } from './../../shared/backend/cruds/crud-service.service';
import { ChangePasswordByComponent } from './../change-password-by/change-password-by.component';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgForm, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { NgbModal, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { DocumentTypeModel } from 'models/entities/document-type-model';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { debounceTime, distinctUntilChanged, filter, map, merge } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs-compat';
import { DepartmentModel } from 'models/entities/department-model';
import { CityModel } from 'models/entities/city-model';
import { TagInputAccessor } from 'ngx-chips/core/accessor';

@Component({
  selector: 'app-update-user',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.scss']
})
export class UpdateUserComponent implements OnInit {

  form: any = {};
  public planInfo: any;
  user: any;
  role = 'ROLE_ADMIN';
  errorMessage = '';
  public selected: any;
  @Input() public userInfoUpdate;
  @Input() public isView = false;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @ViewChild('f') updateUserForm: NgForm;
  @ViewChild('instanceClient') instanceClient: NgbTypeahead;
  listDocumentTypes: Array<DocumentTypeModel> = new Array();
  roleTitle = this.tokenStorage.getAuthorities();
  focusDeparment$ = new Subject<string>();
  clickDeparment$ = new Subject<string>();
  focusCity$ = new Subject<string>();
  clickCity$ = new Subject<string>();
  listDepartaments: Array<DepartmentModel> = new Array();
  listCities: Array<CityModel> = new Array();
  
  constructor(
    private crudService: CrudServiceService,
    private messageService: InfoMessagesService,
    private modalService: NgbModal,
    private tokenStorage: TokenStorageService,
    private userForm: FormBuilder) { }

  ngOnInit() {
    this.role = this.tokenStorage.getAuthorities();
    this.getDepartamnet(1);
    this.searchDepartamnet;
    
    this.getDocumentsTypes();
    if (this.tokenStorage.getAuthorities() === 'ROLE_SUPER') {
      this.configSelectPlan();
    }
    console.log(this.userInfoUpdate.userAux.city);
    this.user = this.userForm.group({
      id: this.userInfoUpdate.userAux.id,
      completeName: this.userInfoUpdate.userAux.completeName,
      email: this.userInfoUpdate.userAux.email,
      username: this.userInfoUpdate.userAux.username,
      address: this.userInfoUpdate.userAux.address,
      role: this.userInfoUpdate.userAux.role,
      phone: this.userInfoUpdate.userAux.phone,
      documentTypeId: this.userInfoUpdate.userAux.documentTypeId.id,
      documentNumber: this.userInfoUpdate.userAux.documentNumber,
      nameCompany: this.userInfoUpdate.nameCompany,
      city:this.userInfoUpdate.userAux.city,
      department:this.userInfoUpdate.userAux.department,
      activatedPlanId: this.userInfoUpdate.idActivatedPlan,
      type: this.userInfoUpdate.userAux.type
    });
    
    this.getCity();
    
  }
  formatter = (x: { name: string }) => x.name;
  getDocumentsTypes() {
    const pathListDOcumentTypesUrl = 'tipos-de-documento/ver-por-objetivo?objective=Personal';
    this.listDocumentTypes = new Array();
    this.crudService.getModel(pathListDOcumentTypesUrl).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          const listDocumentTypesAux = genericResponse.answerList;
          listDocumentTypesAux.forEach(documentType => {
            this.listDocumentTypes.push(documentType);
          });
          this.user.documentTypeId = this.listDocumentTypes[0];
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar los tipos de documentos del sistema',
            'Problema consultando los tipos de documentos del sistema');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar los tipos de documentos del sistema ' + JSON.stringify(error))
      }
    );
  }

  passBack() {    
    let formValue = this.user.value;
    
    console.log(formValue);
    this.passEntry.emit(formValue);
  }

  configSelectPlan() {
    this.crudService.getModel('plan/listar-todas').subscribe(
      genericResponse => {
        if (genericResponse.code === 200) {
          this.planInfo = genericResponse.answerList;
        }
        if (genericResponse.code === 400) {
          this.messageService.getInfoMessagePersonalized('warning', genericResponse.answer, 'No se encontraron planes')
        }
      },
      error => {
        this.errorMessage = error.error.message;
        console.error(error);
        this.messageService.getInfoMessageBadInternet();
      }
    );
  }

  changePassword() {
    const userInfo = {
      'idUser': this.userInfoUpdate.userAux.id,
      'name': this.userInfoUpdate.userAux.name
    };
    const modalRef = this.modalService.open(ChangePasswordByComponent, {
      windowClass: 'modal', size: 'lg'
    });
    modalRef.componentInstance.userInfo = userInfo;
  }

  closeWindow() {
    this.modalService.dismissAll();
  }
  getDepartamnet(countriId) {
    const pathListDepartament =
      'departamento/departamentos-por-pais?countryId=' + countriId;
    this.listDepartaments = new Array();
    this.crudService.getModel(pathListDepartament).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          const listDeparta = genericResponse['answerList'];
          listDeparta.forEach(departamnet => {
            this.listDepartaments.push(departamnet);
          });
        } else {
          this.messageService.getInfoMessageError();
        }
      },
      error => {
        this.messageService.getInfoMessagePersonalized(
          'error',
          'No se pudo Listar Departamento',
          'Error'
        );
        console.error('El error es ', JSON.stringify(error.message));
      }
    );
  }
  getCity() {
    let departmentId: any;
    
    this.listDepartaments.forEach(departamnet => {
      


      if (this.user.get('department').value.name === departamnet.name) {
        departmentId = departamnet.id;
        
      }
    });
    if (departmentId) {
      const pathListCities =
        'ciudad/ciudades-por-departamento?departmentId=' + departmentId;
      this.listCities = new Array();
      this.crudService.getModel(pathListCities).subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.listCities = genericResponse.answerList;
            
              this.user.city = genericResponse.answerList[0];
            
          } else {
            this.messageService.getInfoMessageError();
          }
        },
        error => {
          this.messageService.getInfoMessagePersonalized(
            'error',
            'No se pudo Listar Departamento',
            'Error'
          );
          console.error('El error es ', JSON.stringify(error.message));
        }
      );
    }
  }
  searchDepartamnet = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    merge(this.focusDeparment$),
    merge(this.clickDeparment$.pipe(filter(() => !this.instanceClient.isPopupOpen()))),
    map(search => (search === '' ? this.listDepartaments
      : this.listDepartaments.filter(department => department.name.toLowerCase().indexOf(search.toLowerCase()) > -1)).slice(0, 10))
    
  )
  ;
  
  searchCities = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    merge(this.focusCity$),
    merge(this.clickCity$.pipe(filter(() => !this.instanceClient.isPopupOpen()))),
    map(search => (search === '' ? this.listCities
      : this.listCities.filter(city => city.name.toLowerCase().indexOf(search.toLowerCase()) > -1)).slice(0, 10))
  );


}
