import { Component, OnInit, ViewChild } from '@angular/core';
import { SearchPolicyModel } from 'models/utilities/search-policy-model';
import { ListPoliciesComponent } from '../list-policies/list-policies.component';


@Component({
  selector: 'app-admin-cru-list-policies',
  templateUrl: './admin-cru-list-policies.component.html',
  styleUrls: ['./admin-cru-list-policies.component.scss']
})
export class AdminCruListPoliciesComponent implements OnInit {

  searchModel: SearchPolicyModel = new SearchPolicyModel();
  @ViewChild('listPoliciesComponent') listPolicy: ListPoliciesComponent;

  constructor(
  ) { }

  ngOnInit() {
  }

  newSearchReport(newSearch) {
    this.searchModel = { ...newSearch };
    this.listPolicy.searchInPolicy(0);
  }

  cleanSearch() {
    this.listPolicy.isSearching = false;
    this.listPolicy.ngOnInit();
  }

}
