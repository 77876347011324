import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { PageDataModel } from 'models/utilities/page-data-model';
import { NgbModal, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { AdminPrincipalRuPoliciesComponent } from 'app/policies/admin-principal-ru-policies/admin-principal-ru-policies.component';
import { formatDate } from '@angular/common';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { ExportExcelService } from 'app/shared/excel/export-excel.service';
import { SearchSinisterModel } from 'models/types/search-sinister-model';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, merge, map, filter } from 'rxjs/operators';
import { InsurerModel } from 'models/entities/insurer-model';
import { ClassOfInsuranceModel } from 'models/entities/class-of-insurance.model';
import { ClientModel } from 'models/entities/client-model';
import { UserModel } from 'models/entities/user-model';
import { SharedService } from 'app/shared/shared.service';
import { BeneficiaryModel } from 'models/entities/beneficiary-model';



@Component({
  selector: 'app-expiration-policy-report',
  templateUrl: './expiration-policy-report.component.html',
  styleUrls: ['./expiration-policy-report.component.scss']
})
export class ExpirationPolicyReportComponent implements OnInit {

  @ViewChild('instanceSinister') instanceSinister: NgbTypeahead;
  @Input() viewReport = true;

  page: PageDataModel = new PageDataModel();
  isSearching = false;

  listPolicies = new Array();
  columns = [];
  row = new Array();
  modelSearchPolicy = new SearchSinisterModel();

  focusInsured$ = new Subject<string>();
  clickInsured$ = new Subject<string>();

  focusSeller$ = new Subject<string>();
  clickSeller$ = new Subject<string>();
  focusSinister$ = new Subject<string>();
  clickSinister$ = new Subject<string>();
  focusInsurer$ = new Subject<string>();
  clickInsurer$ = new Subject<string>();
  listBeneficiary: Array<BeneficiaryModel> = new Array();
  listClients: Array<ClientModel> = new Array();
  listClassOfInsurance: Array<ClassOfInsuranceModel> = new Array();
  listInsurer: Array<InsurerModel> = new Array();
  listSeller: any[];
  listPolicyFilter: any[];
  attachment: any;
  budgets: any[];

  numberPolicies = 0;
  isSeller = false;

  dropdownList = ["RENOVATED", "CURRENT", "DELETED", "DRAFT"];
  selectedItems = ["CURRENT"];
  dropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };
  constructor(private crudService: CrudServiceService,
    private messageService: InfoMessagesService,
    private sharedService: SharedService,
    private modalService: NgbModal,
    private tokenStorage: TokenStorageService,
    private excelService: ExportExcelService)
     {
      this.page.pageNumber = 0;
      this.page.size = 10;
      if (this.tokenStorage.getAuthorities() === 'ROLE_SELLER') {
        this.isSeller = true;
        this.modelSearchPolicy.seller.id = this.tokenStorage.getId();
        this.modelSearchPolicy.seller.completeName = this.tokenStorage.getCompleteName();
      }
    }

  ngOnInit() {
    
    this.getClient();
    this.getClassOfInsurance();

    this.getInsurer();
    if (!this.isSeller) {
      this.getSeller();
    }
    this.setPage({ offset: 0 });
  }

  

  setPage(pageInfo) {
    if (!this.isSearching) {
      this.getPolicies(pageInfo);

    } 
    else {
      this.getPolicies(pageInfo.offset);
    }
  }
  getBeneficiaries() {
    
      this.crudService.putModel('beneficiario/beneficiarios-por-polizas' , this.obtenerIdsPolizas()).subscribe(
        (genericResponse: GenericResponseModel) => {
          if (genericResponse.code === 200) {
            this.listBeneficiary=genericResponse.answerList;
            console.log(this.listBeneficiary);
              
            
          } else {
            this.messageService.getInfoMessageError();
          }
        },
        error => {
          this.messageService.getInfoMessageError();
        }
      );
   
  }
  getAttachtments() {
    
    
    const pathBudgetModelUrl = 'anexo/anexos-polizas' ;
    
    this.crudService.putModel(pathBudgetModelUrl,this.obtenerIdsPolizas()).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          if (genericResponse.answerList !== null) {
            this.attachment = genericResponse.answerList;
            console.log(this.attachment);
          }
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudo obtener el presupuesto',
            'Problema consultando el presupuesto');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar el presupuesto: ' + JSON.stringify(error))
      }
    );
  }
  

  // obtener las pólizas que pertenecen a la empresa o vendedor del usuario con sesión inicada
  getPolicies(page) {
    let url = 'poliza/listar-polizas-expirando-por-empresa';
    if (this.tokenStorage.getAuthorities() === 'ROLE_SELLER') {
      url = 'poliza/listar-polizas-expirando-por-vendedor';
    }
    const listPoliciesAux = new Array();
    this.crudService.getModel(url).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          if (genericResponse.code === 200 && genericResponse.answerList.length > 0){
            this.listPolicies= genericResponse.answerList;
            this.listPolicyFilter = genericResponse.answerList;
            console.log(this.listPolicies);
            this.numberPolicies = this.listPolicies.length;
            this.sharedService.setNumberPolicies(this.numberPolicies);
            this.page.totalElements = genericResponse.variable;
            this.page.pageNumber = page;
            this.getAttachtments();
            this.getBeneficiaries();  

           
          } else {
            if (this.viewReport) {
              this.messageService.getInfoMessagePersonalized(
                'warning',
                '',
                'No hay Pólizas próximas a vencerse'
              );
            }
          }
        } else {
          this.messageService.getInfoMessageError();
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('EL ERROR ES: ' + JSON.stringify(error));
      }
    );
  }

  /**
   * Método que toma la fecha de venciemto de la poliza y
   * le agrega una clase CSS según el rango al que pertenece
   * <= 0 dias = vencida clase CSS azul
   * 1 a 14 días clase CSS rojo
   * 15 a 29 días clase CSS amarillo
   * 30 a 45 días clase CSS verde
   */
  getRowClass(row) {
    const parts = row.validUntil.split('-');
    const dateValidUntul = new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2])).getTime();

    const fechaActual = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    const parts2 = fechaActual.split('-');
    const currentDate = new Date(parseInt(parts2[0]), parseInt(parts2[1]) - 1, parseInt(parts2[2])).getTime();

    // se multiplica para obtener la diferencia en días
    const diff = Math.trunc(((dateValidUntul - currentDate) / (1000 * 60 * 60 * 24)));

    // segun la diferencia en días se le agrega una clase por css
    if (diff <= 45 && diff >= 30) {
      return 'row-color-green';
    } else if (diff <= 29 && diff >= 15) {
      return 'row-color-yellow';
    } else if (diff <= 14 && diff >= 1) {
      return 'row-color-red'
    } else if (diff < 1) {
      return 'row-color-blue'
    }
  }


  // obtine los cliente del usuario con sesión iniciada
  getClient() {
    const pathClientPoloceActive = 'cliente/listar-clientes-polizas-activas';
    this.listClients = [];
    this.crudService.getModel(pathClientPoloceActive).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.listClients = genericResponse.answerList;
        } else {
          this.messageService.getInfoMessageError();
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('EL ERROR ES: ' + JSON.stringify(error));
      }
    );
  }


  // obtiene los ramos del usuario con sesión iniciada
  getClassOfInsurance() {
    const pathClassOfInsurance = 'ramos/listar-ramos';
    this.listClassOfInsurance = [];
    this.crudService.getModel(pathClassOfInsurance).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.listClassOfInsurance = genericResponse.answerList;
        } else {
          this.messageService.getInfoMessageError();
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('EL ERROR ES: ' + JSON.stringify(error));
      }
    );
  }


  // obtiene las aseguradoras del usuario con sesión iniciada
  getInsurer() {
    const pathInsurer = 'empresa/aseguradora/listar-aseguradoras';
    this.listInsurer = [];
    this.crudService.getModel(pathInsurer).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.listInsurer = genericResponse.answerList;
        } else {
          this.messageService.getInfoMessageError();
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('EL ERROR ES: ' + JSON.stringify(error));
      }
    );
  }


  // obtiene los usuarios con rol vendedor del usuario con sesión iniciada
  getSeller() {
    const path = 'usuarios/listar-vendedores-por-empresa';
    this.listSeller = [];
    this.crudService.getModel(path).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.listSeller = genericResponse.answerList;
        } else {
          this.messageService.getInfoMessageError();
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('EL ERROR ES: ' + JSON.stringify(error));
      }
    );
  }


  // vizualizar la póliza
  viewPolicy(policy) {
    const modal = this.modalService.open(AdminPrincipalRuPoliciesComponent, {
      windowClass: 'mediumModal', size: 'lg', backdrop: 'static'
    });
    modal.componentInstance.policyModel = policy;
    modal.componentInstance.passEntry.subscribe(result => {
      if (result !== null) {
        modal.dismiss();
      } else {
        modal.dismiss();
      }
    });
  }
  
  getBudgetOfCreate() {
    const pathBudgetModelUrl = 'presupuesto/ver-presupuesto-de-creacion-por-polizas' ;
    
    this.crudService.putModel(pathBudgetModelUrl,this.obtenerIdsPolizas()).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          if (genericResponse.genericObject !== null) {
            this.budgets = genericResponse.genericObject;
            console.log(this.budgets);
          }
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudo obtener el presupuesto',
            'Problema consultando el presupuesto');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar el presupuesto: ' + JSON.stringify(error))
      }
    );
  }
  obtenerIdsPolizas() {
    const listIdPolicy = [];
    this.listPolicyFilter.forEach(policy => {
      listIdPolicy.push(policy.id);
    })
    return listIdPolicy;
    }

  // obtiene las pólizas según los filtros seleccionados
  submitForm() {
    this.listPolicyFilter = [];
    const path =
      'poliza/ver-poliza-filtro?finalDate=' +
      this.modelSearchPolicy.finalDate +
      '&startDate=' +
      this.modelSearchPolicy.initialDate +
      '&clientName=' +
      this.modelSearchPolicy.client.nameOrBusinessName +
      '&classOfInsuranceId=' +
      this.modelSearchPolicy.sinister.name +
      '&insurerName=' +
      this.modelSearchPolicy.insurer.name +
      '&sellerName=' +
      this.modelSearchPolicy.seller.completeName+
      '&state='+this.selectedItems; 

    this.crudService.getModel(path).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.listPolicyFilter = genericResponse.answerList;
          this.getAttachtments();
          this.getBeneficiaries();  
          if (this.listPolicyFilter.length <= 0) {
            this.messageService.getInfoMessagePersonalized(
              'warning',
              '',
              'No hay coincidencias con la búsqueda'
            );
          }
        } else {
          this.messageService.getInfoMessageError();
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('EL ERROR ES: ' + JSON.stringify(error));
      }
    );
  }


  // Borra el contenido de los input del formulario por id
  cleanFilters() {
    $('#initialDate').val('');
    $('#finalDate').val('');
    $('#client').val('');
    $('#sinister').val('');
    $('#insurance').val('');

    this.listClients = [];
    this.modelSearchPolicy = new SearchSinisterModel();
    this.listPolicyFilter = this.listPolicies;
  }
  obtenerValorPoliza(id){
    let valorPoliza = 0;
    let maxId=0;
    
    this.attachment.forEach(attachment => {
      if(attachment.policyId.id === id){
        if(attachment.budgetId.id>maxId){
          maxId=attachment.budgetId.id;
        valorPoliza = Math.round(attachment.budgetId.totalWithIva);
        }

      }
    });
    return valorPoliza;
  }


  // generar documento en formato XLSX ("Excel") Hoja de cálculo
  exportAsXLSX(): void {
    
    const policyAux: any = [];
    let poli: any = [];  
    poli = this.listPolicyFilter;
    poli.forEach(policy => {

      policyAux.push({
        'Nombre Vendedor': policy.sellerUserId.completeName,
        'Aseguradora': policy.insurerId.name,
        'Vigencia Hasta': policy.validUntil,
        'Número Póliza': policy.policy,
        'Ramo': policy.classOfInsuranceId.name,
        'Asegurado': policy.insuredId.nameOrBusinessName,
        'Cédula Asegurado': policy.insuredId.documentNumber || '',
        'Teléfono Asegurado': policy.insuredId.cellphone || '',
        'Fechca Nacimiento Asegurado': policy.insuredId.birthDate? policy.insuredId.birthDate.split('T')[0]: 'N/A' || '',
        'Tomador': policy.clientId.nameOrBusinessName || '',
        'Cédula Tomador': policy.clientId.documentNumber || '',
        'Teléfono Tomador': policy.clientId.cellphone || '',
        'Placa': policy.plate?  policy.plate : 'N/A'|| '',
        'Beneficiarios':  this.hasBeneficiaries(policy.id) || '',
        'Prima': this.obtenerValorPoliza(policy.id)|| '',
      });
    });
    this.excelService.exportAsExcelFile(policyAux, 'Vencimiento_Poliza');
  }
  hasBeneficiaries(id) : string{
    let hasBeneficiaries = '';
    let existe = false;
    this.listBeneficiary.forEach(beneficiary => {
      if(beneficiary.policyId.id === id){
        existe = true;
        hasBeneficiaries+= beneficiary.name+'- ';
      }
    }
    );
    if(existe){
    return hasBeneficiaries;
    }else{
      return 'N/A';
    }
  }



  searchClient = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focusInsured$),
      merge(this.clickInsured$.pipe(filter(() => !this.instanceSinister.isPopupOpen()))),
      map(search => (search === '' ? this.listClients
        : this.listClients.filter(clientPolicyActive =>
          clientPolicyActive.nameOrBusinessName.toLowerCase().indexOf(search.toLowerCase()) > -1)).slice(0, 10)
      )
    );

  searchSeller = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focusSeller$),
      merge(this.clickSeller$.pipe(filter(() => !this.instanceSinister.isPopupOpen()))),
      map(search => (search === '' ? this.listSeller
        : this.listSeller.filter(name => name.completeName.toLowerCase().indexOf(search.toLowerCase()) > -1)).slice(0, 10)
      )
    );

  searchSinister = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focusSinister$),
      merge(this.clickSinister$.pipe(filter(() => !this.instanceSinister.isPopupOpen()))),
      map(search => (search === '' ? this.listClassOfInsurance
        : this.listClassOfInsurance.filter(coi => coi.name.toLowerCase().indexOf(search.toLowerCase()) > -1)).slice(0, 10)
      )
    );

  searchInsurer = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focusInsurer$),
      merge(this.clickInsurer$.pipe(filter(() => !this.instanceSinister.isPopupOpen()))),
      map(search => (search === '' ? this.listInsurer
        : this.listInsurer.filter(i => i.name.toLowerCase().indexOf(search.toLowerCase()) > -1)).slice(0, 10)
      )
    );

  formatter = (x: { nameOrBusinessName: string }) => x.nameOrBusinessName;
  formatter2 = (x: { completeName: string }) => x.completeName;
  sinisterFormatter = (x: { name: string }) => x.name;
  insurerFormatter = (x: { name: string }) => x.name;
  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
}
