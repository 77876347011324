import { Component, OnInit, ViewChild } from '@angular/core';
import { WizardComponent } from 'angular-archwizard';
import { RelationalFileModel } from 'models/utilities/relational-type-model';
import { PolicyModel } from 'models/entities/policy-model';
import { ListFilesComponent } from 'app/shared/components/list-files/list-files.component';
import { AdminCruListRisksComponent } from '../admin-cru-list-risks/admin-cru-list-risks.component';
import { Router } from '@angular/router';
import { BudgetModel } from 'models/entities/budget-model';
import { AttachedInfoModel } from 'models/entities/attached-info-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CruAttachedComponent } from '../cru-attached/cru-attached.component';

@Component({
  selector: 'app-admin-principal-create-policies',
  templateUrl: './admin-principal-create-policies.component.html',
  styleUrls: ['./admin-principal-create-policies.component.scss']
})
export class AdminPrincipalCreatePoliciesComponent implements OnInit {

  @ViewChild('wizard') wizard: WizardComponent;
  public relationalModel: RelationalFileModel = new RelationalFileModel();
  public policyModel: PolicyModel = new PolicyModel();
  public policyModelClean: PolicyModel = new PolicyModel();
  public budgetModel: BudgetModel = new BudgetModel();
  public changesDetected: Array<any> = new Array('Cambio 1', 'Cambio 2', 'Cambio 3');
  @ViewChild(ListFilesComponent) listFileComponente: ListFilesComponent;
  @ViewChild(AdminCruListRisksComponent) adminCruListRisksComponent: AdminCruListRisksComponent;
  actuallyIndex = 0;

  constructor(
    private modalService: NgbModal,
    private routerService: Router
  ) {
  }

  ngOnInit() {
    this.relationalModel.relationalId = this.policyModel.id;
    this.relationalModel.relationalType = 'DOCUMENTS_POLICY_FILE'
    this.relationalModel.relationalObjetive = 'adjunto para póliza';
    this.relationalModel.relationalFileType = 'ADJUNTO';
    this.relationalModel.relationalRestrictions = new Array();
  }

  goToNextStep() {
    this.actuallyIndex++;
    this.wizard.navigation.goToNextStep();
  }

  goToPreviusStep() {
    this.actuallyIndex--;
    this.wizard.navigation.goToPreviousStep();
  }

  infoCruEntry(infoCruEntry: any) {
    this.policyModel = infoCruEntry.policyModel;
    this.policyModelClean = { ...infoCruEntry.policyModel }
    this.budgetModel = infoCruEntry.budgetModel;
    this.relationalModel.relationalId = this.policyModel.id;
    this.listFileComponente.configFiles();
    this.adminCruListRisksComponent.configRiskWithPolicy();
    this.goToNextStep();
  }

  generateAttached() {
    const modal = this.modalService.open(CruAttachedComponent, {
      windowClass: 'mediumModal', size: 'lg', backdrop: 'static'
    });
    const attachedModelToSend: AttachedInfoModel = new AttachedInfoModel();
    attachedModelToSend.valuable = true;
    attachedModelToSend.type = 'CREATE';
    attachedModelToSend.policyId = this.policyModelClean;
    modal.componentInstance.attachedModel = attachedModelToSend;
    modal.componentInstance.budgetModel = this.budgetModel;
    modal.componentInstance.policyModel = this.policyModelClean;
    modal.componentInstance.passEntry.subscribe(result => {
      if (result !== null) {
        modal.dismiss();
        this.goToList()
      } else {
        modal.dismiss();
      }
    });
  }

  goToList() {
    this.routerService.navigateByUrl('polizas/listar-polizas');
  }

}
