import { ComissionsByInsurer } from './../../../models/response/ComissionsByInsurerResponse';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, merge, map, filter } from 'rxjs/operators';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { InsurerModel } from 'models/entities/insurer-model';
import { ExportExcelService } from 'app/shared/excel/export-excel.service';
import { CompanyModel } from 'models/entities/company-model';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { SharedService } from 'app/shared/shared.service';
import { Observable, Observer, Subject } from 'rxjs';
import { SearchCommissionReportModel } from 'models/utilities/search-commission-report-model';
import { Component, OnInit, ViewChild } from '@angular/core';
import * as jsPDF from 'jspdf';

@Component({
  selector: 'app-commissions-by-insurer',
  templateUrl: './commissions-by-insurer.component.html',
  styleUrls: ['./commissions-by-insurer.component.scss']
})
export class CommissionsByInsurerComponent implements OnInit {

  focusInsurer$ = new Subject<string>();
  clickInsurer$ = new Subject<string>();
  listInsurer: Array<InsurerModel> = new Array();
  @ViewChild('instanceInsurer') instanceInsurer: NgbTypeahead;

  public canShowLoading = false;
  public searchCommissionReportModel: SearchCommissionReportModel = new SearchCommissionReportModel();
  public base64Logo: any = '';

  public reportInformation: Array<ComissionsByInsurer> = new Array();

  public reportInformationsColumns = [
    { prop: 'assesorName', width: 300, name: 'Nombre del asesor' },
    { prop: 'assesorDocumentNumber', width: 300, name: 'Documento del asesor' },
    { prop: 'policyNumber', width: 300, name: 'Número de póliza' },
    { prop: 'insuredName', width: 300, name: 'Nombre del aasegurado' },
    { prop: 'classOfInsurance', width: 300, name: 'Ramo' },
    { prop: 'insurerName', width: 300, name: 'Aseguradoraa' },
    { prop: 'premium', width: 300, name: 'Prima póliza' },
    { prop: 'commissionPorcentage', width: 300, name: 'Porcentaje de comisión' },
    { prop: 'comissionAmount', width: 300, name: 'Monto de comisión' },
    { prop: 'installmentAmount', width: 300, name: 'Monto de la cuota' },
    { prop: 'installmentDate', width: 300, name: 'Fecha de la cuota' },
    { prop: 'retef', width: 300, name: 'Retefuente' },
    { prop: 'ica', width: 300, name: 'ICA' },
    { prop: 'cree', width: 300, name: 'CREE' },
    { prop: 'administrationCalculatedAmount', width: 300, name: 'Monto de administración' },
    { prop: 'subtotal', width: 300, name: 'Subtotal' }
  ];

  private myCompanyModel = new CompanyModel();

  constructor(
    private crudService: CrudServiceService,
    private messageService: InfoMessagesService,
    private tokenStorage: TokenStorageService,
    private sharedService: SharedService,
    private excelService: ExportExcelService
  ) { }

  ngOnInit() {
    this.getInsurers()
    this.getCompany();
    setTimeout(() => {
      this.imageLoad();
    }, 2000);
  }

  /***
   * Búsqueda de aseguradora desde el campo seleccionado
   */
  searchByNameInsurer = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focusInsurer$),
      merge(this.clickInsurer$.pipe(filter(() => !this.instanceInsurer.isPopupOpen()))),
      map(search => (search === '' ? this.listInsurer
        : this.listInsurer.filter(insurer => insurer.name.toLowerCase().indexOf(search.toLowerCase()) > -1)).slice(0, 10))
    );

  /***
   * Formato para campo de búsqueda de aseguradora
   */
  formatter = (object: { name: string }) => object.name;

  /***
     * Función para eliminar lo que haya registrado en el campo de búsqueda de aseguradora
     */
  rebuildInsurer() {
    this.searchCommissionReportModel.insurerId.id = undefined;
  }

  /***
     * Consulta de las aseguradoras en el sistema para realizar consulta del reporte
     */
  getInsurers() {
    const pathListInsurerUrl = 'empresa/aseguradora/listar-aseguradoras';
    this.listInsurer = new Array();
    this.crudService.getModel(pathListInsurerUrl).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200 && genericResponse.answerList.length > 0) {
          const listInsurerAux = genericResponse.answerList;
          listInsurerAux.forEach(insurer => {
            this.listInsurer.push(insurer);
          });
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se encontraron aseguradores',
            'Problema consultando aseguradoras');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar las aseguradoras: ' + JSON.stringify(error))
      }
    );

  }

  /***
     * Ejecución de la descarga del pdf para la tabla consulta en la vista actual
     */
  async downloadPDF() {
    const rowsToPrint = new Array();
    for (let i = await 0; await i < this.reportInformation.length; await i++) {
      await rowsToPrint.push(
        [
          this.reportInformation[i].assesorName,
          this.reportInformation[i].assesorDocumentNumber,
          this.reportInformation[i].policyNumber,
          this.reportInformation[i].insuredName,
          this.reportInformation[i].classOfInsurance,
          this.reportInformation[i].insurerName,
          this.reportInformation[i].premium,
          this.reportInformation[i].commissionPorcentage,
          this.reportInformation[i].comissionAmount,
          this.reportInformation[i].installmentAmount,
          this.reportInformation[i].installmentDate,
          this.reportInformation[i].retef,
          this.reportInformation[i].ica,
          this.reportInformation[i].cree,
          this.reportInformation[i].administrationCalculatedAmount,
          this.reportInformation[i].subtotal
        ]
      );
    }

    const doc = new jsPDF('l', 'pt');
    const headers = ['Nombre del vendedor', 'Documento del vendedor',
      'Número de póliza', 'Nombre del asegurado', 'Ramo',
      'Aseguradora', 'Prima', 'Porcentaje de comisión',
      'Monto de comisión', 'Monto de la cuota',
      'Fecha de la cuota', 'Retefuente',
      'ICA', 'CREE', 'Monto de administración',
      'Monto total de comisión'];
    doc.setFontSize(6)
    doc.setFontType('bold')
    doc.text(35, 30, 'INFORME DE COMISIONES POR ASEGURADO');
    doc.addImage(this.base64Logo, 'JPG', 35, 40, 80, 80);
    doc.autoTable(headers, rowsToPrint, { startY: 200, styles: { fontSize: 8 } });
    doc.save('Reporte de comisiones por vendedor - ' + this.myCompanyModel.name);
  }

  /***
   * Exportación a excel de la a información consultada en el reporte
   */
  async exportToExcel() {
    this.reportInformation.forEach(
      (async reportInformationItem => {
        reportInformationItem.premium = Math.round(Number(reportInformationItem.premium));
        reportInformationItem.comissionAmount = Math.round(Number(reportInformationItem.comissionAmount));
        reportInformationItem.installmentAmount = Math.round(Number(reportInformationItem.installmentAmount));
        reportInformationItem.retef = Math.round(Number(reportInformationItem.retef));
        reportInformationItem.ica = Math.round(Number(reportInformationItem.ica));
        reportInformationItem.cree = Math.round(Number(reportInformationItem.cree));
        reportInformationItem.administrationCalculatedAmount = Math.round(Number(reportInformationItem.administrationCalculatedAmount));
        reportInformationItem.subtotal = Math.round(Number(reportInformationItem.subtotal));
      })
    )
    this.excelService.exportAsExcelFile(this.reportInformation, 'Reporte de comisiones por vendedor - ' + this.myCompanyModel.name);
  }

  /***
   * Búsqueda de totales en comisiones por vendedores
   */
  searchCommissionReport() {

    this.reportInformation = new Array();

    const path = 'comisiones/ver-comisiones-por-aseguradora-entre-fechas?insurerId=' +
      this.searchCommissionReportModel.insurerId.id + '&initialDate=' +
      this.searchCommissionReportModel.initialDate + '&finalDate=' +
      this.searchCommissionReportModel.finalDate;
    this.crudService.getModel(path).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200 && genericResponse.answerList.length > 0) {
          this.reportInformation = genericResponse.answerList;
        } else {
          this.messageService.getInfoMessagePersonalized(
            'warning',
            'No se encontraron comisiones',
            'Aviso'
          );
        }
      },
      error => {
        this.messageService.getInfoMessageBadInternet();
        console.error('El error es ', JSON.stringify(error.message));
      }
    );
  }

  /***
   * Obtiene la empresa del usuario actual para generar la impresión del pdf para el usuario
   */
  getCompany() {
    const pathCompanyUrl = 'empresa/compania/ver-compania?id=' + this.tokenStorage.getCompanyId();
    this.crudService.getModel(pathCompanyUrl).subscribe(
      data => {
        this.myCompanyModel = data.genericObject;
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error(error);
      }
    );
  }

  /***
   * Descarga el logo de firebase para convertirlo a BASE64
   */
  imageLoad() {
    this.getBase64ImageFromURL(this.sharedService.getLogoUrlCompany()).subscribe(base64data => {
      this.base64Logo = 'data:image/jpg;base64,' + base64data;
    });
  }

  /***
   * Observable de la descarga del logo que obtiene y ejecuta la conversión de la imagen a base 64
   */
  getBase64ImageFromURL(url: string) {
    return Observable.create((observer: Observer<string>) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      if (!img.complete) {
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = (err) => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }

  /***
    * Método que ejecuta la conversión de una imagen a su base 64.
    */
  getBase64Image(img: HTMLImageElement) {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    const dataURL = canvas.toDataURL('image/png');
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
  }

}
