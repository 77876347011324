import { ActivityStateModel } from 'models/entities/activity-state.model';
import { ViewActivityComponent } from './../view-activity/view-activity.component';
import { CreateActivityManagmentComponent } from './../create-activity-managment/create-activity-managment.component';
import { ClientModel } from 'models/entities/client-model';
import {Component, OnInit, ViewChild, Input, Output, EventEmitter} from '@angular/core';
import { ActivityModel } from 'models/entities/activity';
import { NgbTypeahead, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { CreateActivityComponent } from '../create-activity/create-activity.component';
import swal from 'sweetalert2';
import {PageDataModel} from "../../../models/utilities/page-data-model";
import {ActivitiesService} from "../activities-service/activities.service";

@Component({
  selector: 'app-list-activitys',
  templateUrl: './list-activitys.component.html',
  styleUrls: ['./list-activitys.component.scss']
})
export class ListActivitysComponent implements OnInit {

  public client=new ClientModel();
  @Input() listActivities: Array<ActivityModel>;
  @Input() typeSearch;
  @Input() idEntity;
  temp: any;
  activity:ActivityModel;
  @Input() page;
  isSearching = false;

  constructor(
    private messageCtrl:InfoMessagesService,
    private serviceCtrl:CrudServiceService,
    private tokenServiceCtrl:TokenStorageService,
    private modalService:NgbModal,
    private activitiesService: ActivitiesService
  ) {

  }

  ngOnInit() {
  }

  getActivitysForClient(){
    let path='actividad/actividades-por-cliente?relationalType=CLIENTE&&relationalId='+this.idEntity;
    this.serviceCtrl.getModel(path).subscribe(
      result=>{
       
        if(result.code===200)
        {
           this.listActivities=result.answerList;
        }
      },error => {
        console.log(error);
        this.messageCtrl.getInfoMessageError();
      }
    )
  }

  getActivitysForResponsable(){
    let path='actividad/actividades-por-responsable?idResponsable='+this.idEntity;
    this.serviceCtrl.getModel(path).subscribe(
      result=>{
       
        if(result.code===200)
        {
           this.listActivities=result.answerList;
        }
      },error => {
        console.log(error);
        this.messageCtrl.getInfoMessageError();
      }
    )
  }

  createComent(activity) {
    const modal = this.modalService.open(CreateActivityManagmentComponent, {
      windowClass: '',
      size: 'lg',
      backdrop: 'static'
    });
    modal.componentInstance.activity=activity;
    modal.componentInstance.passEntry.subscribe(result => {
      if(result === 'created'){
        this.activitiesService.setActivitiesListStatus();
        modal.dismiss();
      }
    });
  }

  viewActivity(activity) {
    const modal = this.modalService.open(ViewActivityComponent, {
      windowClass: '',
      size: 'lg',
      backdrop: 'static'
    });
    modal.componentInstance.activity=activity;
    modal.componentInstance.passEntry.subscribe(result=> {
      if(result === 'close'){
        modal.dismiss();
        this.activitiesService.setActivitiesListStatus();
      }
    });
  }

  editActivity(activity:ActivityModel) {
    const modal = this.modalService.open(CreateActivityComponent, {
      windowClass: '',
      size: 'lg',
      backdrop: 'static'
    });
    modal.componentInstance.activityModel=activity;
    modal.componentInstance.isModal = true;
    modal.componentInstance.isEdit = true;
    modal.componentInstance.passEntry.subscribe(result => {
      if(result === 'close'){
        this.activitiesService.setActivitiesListStatus();
        modal.dismiss();
      }
    });
  }


  getActivities(page){
    let path='actividad/actividades-por-compania?companyId=' + this.tokenServiceCtrl.getCompanyId() + '&page=' + page;
    this.serviceCtrl.getModel(path).subscribe(
      result => {
        if(result.code === 200) {
           this.listActivities=result.answerList;
        }
      },error => {
        console.log(error);
        this.messageCtrl.getInfoMessageError();
      }
    )
  }

  deleteActivity(activity){
    swal({
      title: 'Esta seguro que desea eliminar esta actividad?',
      text: "No puedes revertir esta acción despues!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        let path='actividad/eliminar'
        this.serviceCtrl.createModel(path,activity).subscribe(
          result => {
            if(result.code === 200) {
              swal(
                'Actividad eliminada!',
                'La actividad ha sido eliminada.',
                'success'
              );
              this.activitiesService.setActivitiesListStatus();
            } else {
              this.messageCtrl.getInfoMessageError();
            }
          },error => {
            console.log(error);
            this.messageCtrl.getInfoMessageError();
          }
        )
      }
    })
  }

  setPage(pageInfo) {
    this.activitiesService.setActivitiesPage(pageInfo);
  }
}
