export class PlansModel {
    id: string;
    name: string;
    value: string;
    description: string;
    createdDate: string;
    lastUpdateDate: string;

    public constructor() {
        this.id = '';
        this.name = '';
        this.value = '';
        this.description = '';
        this.createdDate = '';
        this.lastUpdateDate = '';
    }
}
