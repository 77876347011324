import { PayReportComponent } from './../../printing/pay-report/pay-report.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input } from '@angular/core';
import { PolicyModel } from 'models/entities/policy-model';
import { InstallmentModel } from 'models/entities/installment-model';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { ThrowStmt } from '@angular/compiler';
import { RemissionComponent } from 'app/printing/remission/remission.component';
import {TokenStorageService} from "../../shared/storage-services/token-storage.service";
import {RemissionModel} from "../../../models/entities/remission-model";
import * as moment from 'moment';

@Component({
  selector: 'app-installments-by-policy',
  templateUrl: './installments-by-policy.component.html',
  styleUrls: ['./installments-by-policy.component.scss']
})
export class InstallmentsByPolicyComponent implements OnInit {

  @Input() policyModel: PolicyModel = new PolicyModel();
  collapseList: Array<boolean> = new Array();
  installmentsList: Array<any> = new Array();
  installmentsColumn = [
    { prop: 'number' },
    { prop: 'expirationDate' },
    { prop: 'premium' },
    { prop: 'iva' },
    { prop: 'interest' },
    { prop: 'spendings' },
    { prop: 'total' },
    { prop: 'state' }
  ]

  isSeller = false;

  constructor(
    private crudService: CrudServiceService,
    private messageService: InfoMessagesService,
    private modalService: NgbModal,
    private tokenStorage: TokenStorageService
  ) { }

  ngOnInit() {
    if (this.tokenStorage.getAuthorities() === 'ROLE_SELLER') {
      this.isSeller = true;
    }
    this.getInstallments();
  }

  getInstallments() {
    const pathListInstallmentsUrl = 'cuotas/listar-cuotas-por-poliza?PolicyId=' + this.policyModel.id;
    this.installmentsList = new Array();
    this.crudService.getModel(pathListInstallmentsUrl).subscribe(
      (genericResponse: GenericResponseModel) => {
        console.log(genericResponse);
        if (genericResponse.code === 200) {
          this.installmentsList = genericResponse.answerList;
          for (let i = 0; i < this.installmentsList.length; i++) {
            this.collapseList.push(false);
          }
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudieron listar las cuotas generadas en esta póliza',
            'Problema consultando cuotas');
        }
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al consultar cuotas: ' + JSON.stringify(error))
      }
    );
  }

  payInstallment(installmentToPay: InstallmentModel) {
    const modal = this.modalService.open(PayReportComponent, {
      windowClass: '', size: 'lg', backdrop: 'static'
    });
    modal.componentInstance.policyModel = this.policyModel;
    modal.componentInstance.installmentModel = installmentToPay;
    modal.componentInstance.passEntry.subscribe(retorno => {
      if (retorno === 1) {
        this.getInstallments();
      }
      modal.dismiss();
    });
  }

  printPayInstallmentReport(installmentToPay: InstallmentModel) {
    const modal = this.modalService.open(PayReportComponent, {
      windowClass: 'printingModal', size: 'lg', backdrop: 'static'
    });
    modal.componentInstance.policyModel = this.policyModel;
    modal.componentInstance.installmentModel = installmentToPay;
    modal.componentInstance.isPrinting = true;
    modal.componentInstance.passEntry.subscribe(() => {
      modal.dismiss();
    });
  }

  printInstallment(installmentToPay: InstallmentModel) {
    const modal = this.modalService.open(RemissionComponent, {
      windowClass: 'printingModal', size: 'lg', backdrop: 'static'
    });
    modal.componentInstance.policyId = this.policyModel;
    modal.componentInstance.installmentModel = installmentToPay;
    const remission = new RemissionModel();
    remission.expeditionDate = moment().format('yyyy-MM-DD');
    modal.componentInstance.remissionModel = remission;
    modal.componentInstance.passEntry.subscribe(retorno => {
      if (retorno === 1) {
        this.getInstallments();
      }
      modal.dismiss();
    });
  }

}
