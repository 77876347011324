export class AviationRiskModel {
    id: string;
    policyId: any;
    clientId: any;
    state: any;
    brand: string;
    model: string;
    serialNumber: string;
    registration: string;
    numberPassengerSeats: string;
    numberPassengerCrew: string;
    yearProduction: string;
    insuredValue: string;
    validSince: string;
    validUntil: string;
    classOfInsuranceId: any;
    creatorId: any;
    lastUpdateId: any;
    createdDate: string;
    lastUpdateDate: string;

    public constructor() {
        this.id = null;
        this.policyId = '';
        this.clientId = '';
        this.state = '';
        this.brand = '';
        this.model = '';
        this.serialNumber = '';
        this.registration = '';
        this.numberPassengerSeats = '';
        this.numberPassengerCrew = '';
        this.yearProduction = '';
        this.insuredValue = ''
        this.validSince = '';
        this.validUntil = '';
        this.classOfInsuranceId = '';
        this.creatorId = '';
        this.lastUpdateId = '';
        this.createdDate = '';
        this.lastUpdateDate = '';
    }
}
