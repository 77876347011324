import { Component, OnInit } from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ClientRecordComponent} from "../client-record/client-record.component";

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss']
})
export class ContainerComponent implements OnInit {

  constructor(
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.modalService.open(ClientRecordComponent, {
      windowClass: 'mediumModal', backdrop: 'static', size: 'lg'
    });
  }

  ngOnDestroy() {
    this.modalService.dismissAll();
  }
}
