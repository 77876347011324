import { NgxLoadingModule } from 'ngx-loading';
import { PrintingModule } from './../printing/printing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';

import { PoliciesRoutingModule } from './policies-routing.module';
import { ListPoliciesComponent } from './list-policies/list-policies.component';
import { AdminPrincipalCreatePoliciesComponent } from './admin-principal-create-policies/admin-principal-create-policies.component';
import { CruPersonalInfoComponent } from './cru-personal-info/cru-personal-info.component';
import { AdminCruListPoliciesComponent } from './admin-cru-list-policies/admin-cru-list-policies.component';
import { AdminCruListRisksComponent } from './admin-cru-list-risks/admin-cru-list-risks.component';
import { CruAttachedComponent } from './cru-attached/cru-attached.component';
import { ListAttachedComponent } from './list-attached/list-attached.component';
import { UpdateAttachedComponent} from './update-attached/update-attached.component';
import { HealthRiskListComponent } from './health-risk-list/health-risk-list.component';
import { HealthRiskCruComponent } from './health-risk-cru/health-risk-cru.component';
import { FormsModule } from '@angular/forms';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CreateBeneficiaryComponent } from './create-beneficiary/create-beneficiary.component';
import { VehicleRiskCruComponent } from './vehicle-risk-cru/vehicle-risk-cru.component';
import { VehicleRiskListComponent } from './vehicle-risk-list/vehicle-risk-list.component';
import { CoownershipRiskCruComponent } from './coownership-risk-cru/coownership-risk-cru.component';
import { CoownershipRiskListComponent } from './coownership-risk-list/coownership-risk-list.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ArchwizardModule } from 'angular-archwizard';
import { SharedComponentsModule } from 'app/shared/components/shared-components.module';
import { RcProfessionalRiskCruComponent } from './rc-professional-risk-cru/rc-professional-risk-cru.component';
import { RcProfessionalRiskListComponent } from './rc-professional-risk-list/rc-professional-risk-list.component';
import { PymeRiskCruComponent } from './pyme-risk-cru/pyme-risk-cru.component';
import { PymeRiskListComponent } from './pyme-risk-list/pyme-risk-list.component';
import { LifeRiskCruComponent } from './life-risk-cru/life-risk-cru.component';
import { LifeRiskListComponent } from './life-risk-list/life-risk-list.component';
import { PersonalAccidentRiskCruComponent } from './personal-accident-risk-cru/personal-accident-risk-cru.component';
import { PersonalAccidentRiskListComponent } from './personal-accident-risk-list/personal-accident-risk-list.component';
import { FulfillmentRiskCruComponent } from './fulfillment-risk-cru/fulfillment-risk-cru.component';
import { FulfillmentRiskListComponent } from './fulfillment-risk-list/fulfillment-risk-list.component';
import { HomeRiskCruComponent } from './home-risk-cru/home-risk-cru.component';
import { HomeRiskListComponent } from './home-risk-list/home-risk-list.component';
import { CapitalizationRiskCruComponent } from './capitalization-risk-cru/capitalization-risk-cru.component';
import { CapitalizationRiskListComponent } from './capitalization-risk-list/capitalization-risk-list.component';
import { SoatRiskCruComponent } from './soat-risk-cru/soat-risk-cru.component';
import { SoatRiskListComponent } from './soat-risk-list/soat-risk-list.component';
import { RceRiskCruComponent } from './rce-risk-cru/rce-risk-cru.component';
import { RceRiskListComponent } from './rce-risk-list/rce-risk-list.component';
import { RccVehicleRiskCruComponent } from './rcc-vehicle-risk-cru/rcc-vehicle-risk-cru.component';
import { RccVehicleRiskListComponent } from './rcc-vehicle-risk-list/rcc-vehicle-risk-list.component';
import { MachineryBreakdownRiskCruComponent } from './machinery-breakdown-risk-cru/machinery-breakdown-risk-cru.component';
import { MachineryBreakdownRiskListComponent } from './machinery-breakdown-risk-list/machinery-breakdown-risk-list.component';
import { MultiRiskCruComponent } from './multi-risk-cru/multi-risk-cru.component';
import { MultiRiskListComponent } from './multi-risk-list/multi-risk-list.component';
import { RcCyberRiskCruComponent } from './rc-cyber-risk-cru/rc-cyber-risk-cru.component';
import { RcCyberRiskListComponent } from './rc-cyber-risk-list/rc-cyber-risk-list.component';
import { MaritimeTransportRiskCruComponent } from './maritime-transport-risk-cru/maritime-transport-risk-cru.component';
import { MaritimeTransportRiskListComponent } from './maritime-transport-risk-list/maritime-transport-risk-list.component';
import { AdminPrincipalRuPoliciesComponent } from './admin-principal-ru-policies/admin-principal-ru-policies.component';
import { InstallmentsByPolicyComponent } from './installments-by-policy/installments-by-policy.component';
import { BudgetInPolicyComponent } from './budget-in-policy/budget-in-policy.component';
import { AllRiskConstructionRiskCruComponent } from './all-risk-construction-risk-cru/all-risk-construction-risk-cru.component';
import { AllRiskConstructionRiskListComponent } from './all-risk-construction-risk-list/all-risk-construction-risk-list.component';
import { ListRemissionsComponent } from './list-remissions/list-remissions.component';
import { SearchPoliciesComponent } from './search-policies/search-policies.component';
// tslint:disable-next-line:max-line-length
import { CivilLiabilityHydrocarbonsShipmentRiskCruComponent } from './civil-liability-hydrocarbons-shipment-risk-cru/civil-liability-hydrocarbons-shipment-risk-cru.component';
// tslint:disable-next-line:max-line-length
import { CivilLiabilityHydrocarbonsShipmentRiskListComponent } from './civil-liability-hydrocarbons-shipment-risk-list/civil-liability-hydrocarbons-shipment-risk-list.component';
import { NonContractualCivilLiabilityShipmentRiskCruComponent } from './non-contractual-civil-liability-shipment-risk-cru/non-contractual-civil-liability-shipment-risk-cru.component';
import { NonContractualCivilLiabilityShipmentRiskListComponent } from './non-contractual-civil-liability-shipment-risk-list/non-contractual-civil-liability-shipment-risk-list.component';
import { ContractualCivilLiabilityShipmentRiskCruComponent } from './contractual-civil-liability-shipment-risk-cru/contractual-civil-liability-shipment-risk-cru.component';
import { ContractualCivilLiabilityShipmentRiskListComponent } from './contractual-civil-liability-shipment-risk-list/contractual-civil-liability-shipment-risk-list.component';
import { NonContractualLiabilityPassengerRiskCruComponent } from './non-contractual-liability-passenger-risk-cru/non-contractual-liability-passenger-risk-cru.component';
import { ContractualCivilLiabilityPassengerRiskCruComponent } from './contractual-civil-liability-passenger-risk-cru/contractual-civil-liability-passenger-risk-cru.component';
import { ContractualCivilLiabilityPassengerRiskListComponent } from './contractual-civil-liability-passenger-risk-list/contractual-civil-liability-passenger-risk-list.component';
import { NonContractualLiabilityPassengersRiskListComponent } from './non-contractual-liability-passengers-risk-list/non-contractual-liability-passengers-risk-list.component';
import { ContractualCivilLiabilityPassengersRiskListComponent } from './contractual-civil-liability-passengers-risk-list/contractual-civil-liability-passengers-risk-list.component';
import { LifeDebtorsRiskCruComponent } from './life-debtors-risk-cru/life-debtors-risk-cru.component';
import { LifeDebtorsRiskListComponent } from './life-debtors-risk-list/life-debtors-risk-list.component';
import { ComplementaryPlanRiskListComponent } from './complementary-plan-risk-list/complementary-plan-risk-list.component';
import { ComplementaryPlanRiskCruComponent } from './complementary-plan-risk-cru/complementary-plan-risk-cru.component';
import { RcFulfillmentRiskCruComponent } from './rc-fulfillment-risk-cru/rc-fulfillment-risk-cru.component';
import { RcFulfillmentRiskListComponent } from './rc-fulfillment-risk-list/rc-fulfillment-risk-list.component';
import { AviationRiskCruComponent } from './aviation-risk-cru/aviation-risk-cru.component';
import { AviationRiskListComponent } from './aviation-risk-list/aviation-risk-list.component';
import { FuneralRiskCruComponent } from './funeral-risk-cru/funeral-risk-cru.component';
import { FuneralRiskListComponent } from './funeral-risk-list/funeral-risk-list.component';
import { AgriculturalCropsRiskCruComponent } from './agricultural-crops-risk-cru/agricultural-crops-risk-cru.component';
import { AgriculturalCropsRiskListComponent } from './agricultural-crops-risk-list/agricultural-crops-risk-list.component';
import { ArlRiskCruComponent } from './arl-risk-cru/arl-risk-cru.component';
import { ArlRiskListComponent } from './arl-risk-list/arl-risk-list.component';
import { LeaseRiskCruComponent } from './lease-risk-cru/lease-risk-cru.component';
import { LeaseRiskListComponent } from './lease-risk-list/lease-risk-list.component';
import { HullBoatRiskCruComponent } from './hull-boat-risk-cru/hull-boat-risk-cru.component';
import { HullBoatRiskListComponent } from './hull-boat-risk-list/hull-boat-risk-list.component';
import { DirectorsAdministratorsRiskCruComponent } from './directors-administrators-risk-cru/directors-administrators-risk-cru.component';
import { DirectorsAdministratorsRiskListComponent } from './directors-administrators-risk-list/directors-administrators-risk-list.component';
import { InfidelityFinancialRiskCruComponent } from './infidelity-financial-risk-cru/infidelity-financial-risk-cru.component';
import { InfidelityFinancialRiskListComponent } from './infidelity-financial-risk-list/infidelity-financial-risk-list.component';
import { MachineryEquipmentRiskCruComponent } from './machinery-equipment-risk-cru/machinery-equipment-risk-cru.component';
import { MachineryEquipmentRiskListComponent } from './machinery-equipment-risk-list/machinery-equipment-risk-list.component';
import { RcClinicHospitalsRiskCruComponent } from './rc-clinic-hospitals-risk-cru/rc-clinic-hospitals-risk-cru.component';
import { RcClinicHospitalsRiskListComponent } from './rc-clinic-hospitals-risk-list/rc-clinic-hospitals-risk-list.component';
import { RcParkingWorkshopsRiskCruComponent } from './rc-parking-workshops-risk-cru/rc-parking-workshops-risk-cru.component';
import { RcParkingWorkshopsRiskListComponent } from './rc-parking-workshops-risk-list/rc-parking-workshops-risk-list.component';
import { NonContractualCivilLiabilityRiskCruComponent } from './non-contractual-civil-liability-risk-cru/non-contractual-civil-liability-risk-cru.component';
import { NonContractualCivilLiabilityRiskListComponent } from './non-contractual-civil-liability-risk-list/non-contractual-civil-liability-risk-list.component';
import { TransportValuablesRiskCruComponent } from './transport-valuables-risk-cru/transport-valuables-risk-cru.component';
import { TransportValuablesRiskListComponent } from './transport-valuables-risk-list/transport-valuables-risk-list.component';
import { TransportMerchandiseRiskCruComponent } from './transport-merchandise-risk-cru/transport-merchandise-risk-cru.component';
import { TransportMerchandiseRiskListComponent } from './transport-merchandise-risk-list/transport-merchandise-risk-list.component';
import { GlobalCommercialManagementRiskListComponent } from './global-commercial-management-risk-list/global-commercial-management-risk-list.component';
import { GlobalCommercialManagementRiskCruComponent } from './global-commercial-management-risk-cru/global-commercial-management-risk-cru.component';
import { CustomPayStyleModalComponent } from './custom-pay-style-modal/custom-pay-style-modal.component';
import { GroupLifeRiskCruComponent } from './group-life-risk-cru/group-life-risk-cru.component';
import { GroupLifeRiskListComponent } from './group-life-risk-list/group-life-risk-list.component';
import { SeriousDeseasesRiskCruComponent } from './serious-deseases-risk-cru/serious-deseases-risk-cru.component';
import { SeriousDeseasesRiskListComponent } from './serious-deseases-risk-list/serious-deseases-risk-list.component';

@NgModule({
  declarations: [
    ListPoliciesComponent,
    AdminPrincipalCreatePoliciesComponent,
    CruPersonalInfoComponent,
    AdminCruListPoliciesComponent,
    AdminCruListRisksComponent,
    CruAttachedComponent,
    ListAttachedComponent,
    UpdateAttachedComponent,
    HealthRiskListComponent,
    HealthRiskCruComponent,
    VehicleRiskCruComponent,
    VehicleRiskListComponent,
    CreateBeneficiaryComponent,
    CoownershipRiskCruComponent,
    CoownershipRiskListComponent,
    RcProfessionalRiskCruComponent,
    RcProfessionalRiskListComponent,
    PymeRiskCruComponent,
    PymeRiskListComponent,
    LifeRiskCruComponent,
    LifeRiskListComponent,
    PersonalAccidentRiskCruComponent,
    PersonalAccidentRiskListComponent,
    FulfillmentRiskCruComponent,
    FulfillmentRiskListComponent,
    HomeRiskCruComponent,
    HomeRiskListComponent,
    CapitalizationRiskCruComponent,
    CapitalizationRiskListComponent,
    SoatRiskCruComponent,
    SoatRiskListComponent,
    RceRiskCruComponent,
    RceRiskListComponent,
    RccVehicleRiskCruComponent,
    RccVehicleRiskListComponent,
    MachineryBreakdownRiskCruComponent,
    MachineryBreakdownRiskListComponent,
    MultiRiskCruComponent,
    MultiRiskListComponent,
    RcCyberRiskCruComponent,
    RcCyberRiskListComponent,
    MaritimeTransportRiskCruComponent,
    MaritimeTransportRiskListComponent,
    AdminPrincipalRuPoliciesComponent,
    InstallmentsByPolicyComponent,
    BudgetInPolicyComponent,
    AllRiskConstructionRiskCruComponent,
    AllRiskConstructionRiskListComponent,
    ListRemissionsComponent,
    SearchPoliciesComponent,
    CivilLiabilityHydrocarbonsShipmentRiskCruComponent,
    CivilLiabilityHydrocarbonsShipmentRiskListComponent,
    NonContractualCivilLiabilityShipmentRiskCruComponent,
    NonContractualCivilLiabilityShipmentRiskListComponent,
    ContractualCivilLiabilityShipmentRiskCruComponent,
    ContractualCivilLiabilityShipmentRiskListComponent,
    NonContractualLiabilityPassengerRiskCruComponent,
    ContractualCivilLiabilityPassengerRiskCruComponent,
    ContractualCivilLiabilityPassengerRiskListComponent,
    NonContractualLiabilityPassengersRiskListComponent,
    ContractualCivilLiabilityPassengersRiskListComponent,
    LifeDebtorsRiskCruComponent,
    LifeDebtorsRiskListComponent,
    ComplementaryPlanRiskCruComponent,
    ComplementaryPlanRiskListComponent,
    RcFulfillmentRiskCruComponent,
    RcFulfillmentRiskListComponent,
    AviationRiskCruComponent,
    AviationRiskListComponent,
    FuneralRiskCruComponent,
    FuneralRiskListComponent,
    AgriculturalCropsRiskCruComponent,
    AgriculturalCropsRiskListComponent,
    ArlRiskCruComponent,
    ArlRiskListComponent,
    LeaseRiskCruComponent,
    LeaseRiskListComponent,
    HullBoatRiskCruComponent,
    HullBoatRiskListComponent,
    DirectorsAdministratorsRiskCruComponent,
    DirectorsAdministratorsRiskListComponent,
    InfidelityFinancialRiskCruComponent,
    InfidelityFinancialRiskListComponent,
    MachineryEquipmentRiskCruComponent,
    MachineryEquipmentRiskListComponent,
    RcClinicHospitalsRiskCruComponent,
    RcClinicHospitalsRiskListComponent,
    RcParkingWorkshopsRiskCruComponent,
    RcParkingWorkshopsRiskListComponent,
    NonContractualCivilLiabilityRiskCruComponent,
    NonContractualCivilLiabilityRiskListComponent,
    TransportValuablesRiskCruComponent,
    TransportValuablesRiskListComponent,
    TransportMerchandiseRiskCruComponent,
    TransportMerchandiseRiskListComponent,
    GlobalCommercialManagementRiskListComponent,
    GlobalCommercialManagementRiskCruComponent,
    CustomPayStyleModalComponent,
    GroupLifeRiskCruComponent,
    GroupLifeRiskListComponent,
    SeriousDeseasesRiskCruComponent,
    SeriousDeseasesRiskListComponent
  ],
  imports: [
    CommonModule,
    PoliciesRoutingModule,
    FormsModule,
    NgbModalModule,
    NgbModule,
    NgxDatatableModule,
    ArchwizardModule,
    SharedComponentsModule,
    PrintingModule,
    NgxLoadingModule,
    PDFExportModule
  ],
  exports: [
    ListPoliciesComponent,
    CruPersonalInfoComponent,
    AdminCruListPoliciesComponent,
    AdminCruListRisksComponent,
    CruAttachedComponent,
    ListAttachedComponent,
    UpdateAttachedComponent,
    HealthRiskListComponent,
    HealthRiskCruComponent,
    VehicleRiskCruComponent,
    VehicleRiskListComponent,
    CreateBeneficiaryComponent,
    CoownershipRiskCruComponent,
    CoownershipRiskListComponent,
    RcProfessionalRiskCruComponent,
    RcProfessionalRiskListComponent,
    PymeRiskCruComponent,
    PymeRiskListComponent,
    LifeRiskCruComponent,
    LifeRiskListComponent,
    PersonalAccidentRiskCruComponent,
    PersonalAccidentRiskListComponent,
    FulfillmentRiskCruComponent,
    FulfillmentRiskListComponent,
    HomeRiskCruComponent,
    HomeRiskListComponent,
    CapitalizationRiskCruComponent,
    CapitalizationRiskListComponent,
    SoatRiskCruComponent,
    SoatRiskListComponent,
    RceRiskCruComponent,
    RceRiskListComponent,
    RccVehicleRiskCruComponent,
    RccVehicleRiskListComponent,
    MachineryBreakdownRiskCruComponent,
    MachineryBreakdownRiskListComponent,
    MultiRiskCruComponent,
    MultiRiskListComponent,
    RcCyberRiskCruComponent,
    RcCyberRiskListComponent,
    MaritimeTransportRiskCruComponent,
    MaritimeTransportRiskListComponent,
    AdminPrincipalRuPoliciesComponent,
    InstallmentsByPolicyComponent,
    BudgetInPolicyComponent,
    AllRiskConstructionRiskCruComponent,
    AllRiskConstructionRiskListComponent,
    ListRemissionsComponent,
    SearchPoliciesComponent,
    CivilLiabilityHydrocarbonsShipmentRiskCruComponent,
    CivilLiabilityHydrocarbonsShipmentRiskListComponent,
    NonContractualCivilLiabilityShipmentRiskCruComponent,
    NonContractualCivilLiabilityShipmentRiskListComponent,
    ContractualCivilLiabilityShipmentRiskCruComponent,
    ContractualCivilLiabilityShipmentRiskListComponent,
    NonContractualLiabilityPassengerRiskCruComponent,
    ContractualCivilLiabilityPassengerRiskCruComponent,
    ContractualCivilLiabilityPassengerRiskListComponent,
    NonContractualLiabilityPassengersRiskListComponent,
    ContractualCivilLiabilityPassengersRiskListComponent,
    LifeDebtorsRiskCruComponent,
    LifeDebtorsRiskListComponent,
    ComplementaryPlanRiskListComponent,
    ComplementaryPlanRiskCruComponent,
    RcFulfillmentRiskCruComponent,
    RcFulfillmentRiskListComponent,
    AviationRiskCruComponent,
    AviationRiskListComponent,
    FuneralRiskCruComponent,
    FuneralRiskListComponent,
    AgriculturalCropsRiskCruComponent,
    AgriculturalCropsRiskListComponent,
    ArlRiskCruComponent,
    ArlRiskListComponent,
    LeaseRiskCruComponent,
    LeaseRiskListComponent,
    HullBoatRiskCruComponent,
    HullBoatRiskListComponent,
    DirectorsAdministratorsRiskCruComponent,
    DirectorsAdministratorsRiskListComponent,
    InfidelityFinancialRiskCruComponent,
    InfidelityFinancialRiskListComponent,
    MachineryEquipmentRiskCruComponent,
    MachineryEquipmentRiskListComponent,
    RcClinicHospitalsRiskCruComponent,
    RcClinicHospitalsRiskListComponent,
    RcParkingWorkshopsRiskCruComponent,
    RcParkingWorkshopsRiskListComponent,
    NonContractualCivilLiabilityRiskCruComponent,
    NonContractualCivilLiabilityRiskListComponent,
    TransportValuablesRiskCruComponent,
    TransportValuablesRiskListComponent,
    TransportMerchandiseRiskCruComponent,
    TransportMerchandiseRiskListComponent,
    GlobalCommercialManagementRiskListComponent,
    GlobalCommercialManagementRiskCruComponent
  ],
  entryComponents: [CustomPayStyleModalComponent, BudgetInPolicyComponent]
})
export class PoliciesModule { }
