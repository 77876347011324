import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal, NgbTypeahead} from "@ng-bootstrap/ng-bootstrap";
import {UserModel} from "../../../models/entities/user-model";
import {CrudServiceService} from "../../shared/backend/cruds/crud-service.service";
import {GenericResponseModel} from "../../../models/utilities/generic.response.model";
import {BillComponent} from "../bill/bill.component";
import {InfoMessagesService} from "../../shared/messages/info-messages.service";
import {TokenStorageService} from "../../shared/storage-services/token-storage.service";
import {Observable, Subject} from "rxjs";
import {debounceTime, distinctUntilChanged, map, merge, filter} from "rxjs/operators";
import {SearchComponent} from "../../shared/search/search.component";
import * as moment from "moment";
import {CommissionBillModel} from "../../../models/entities/commission-bill-model";
import {CompanyModel} from "../../../models/entities/company-model";
import swal from "sweetalert2";

@Component({
  selector: 'app-register-bill',
  templateUrl: './register-bill.component.html',
  styleUrls: ['./register-bill.component.scss']
})
export class RegisterBillComponent implements OnInit {

  @Input() seller: UserModel;
  @Input() amount: number;
  @Output() emitter: EventEmitter<any> = new EventEmitter<any>();
  details: any[] = [];
  @Input() searcher: boolean = false;
  @Input() sellersList: UserModel[];

  constructor(
      private modalService: NgbModal,
      public activeModal: NgbActiveModal,
      private crudService: CrudServiceService,
      private messagesService: InfoMessagesService,
      private tokenStorage: TokenStorageService
  ) { }

  ngOnInit() {
    let tmpDetail = {
      quantity: 1,
      details: '',
      unitAmount: 0
    };
    if (!this.searcher) {
      tmpDetail = {
        quantity: 1,
        details: 'COMISIONES PAGADAS EL ' + moment().format('DD/MM/yyyy'),
        unitAmount: this.amount
      };
    }
    this.details = [tmpDetail];
  }

  addDetailsRow() {
    if (!this.validateDetails()) {
      return;
    }
    const tmpDetail = {
      quantity: 1,
      detail: '',
      unitAmount: ''
    };
    this.details.push(tmpDetail)
  }

  registerBill() {
    if (!this.validateDetails()) {
      return;
    }
    const bill = new CommissionBillModel();
    const company = new CompanyModel();
    company.id = this.tokenStorage.getCompanyId();
    bill.company = company;
    bill.seller = this.seller;
    bill.amount = this.getTotalBilling();
    bill.details = this.details;
    const path = 'facturacion-comisiones/crear-factura';
    this.crudService.createModel(path, bill).toPromise().then((response: GenericResponseModel) => {
      this.emitter.emit(response.genericObject);
      this.activeModal.dismiss();
    }).catch(error => {
      console.log(error);
      this.messagesService.getInfoMessagePersonalized(
          'error',
          'No se ha podido generar la factura',
          'Error generando factura'
      );
    })
  }

  getTotalValue(detail) {
    return Number(detail.quantity) * Number(detail.unitAmount);
  }

  validateDetails() {
    if (!this.seller) {
      this.messagesService.getInfoMessagePersonalized(
          'warning',
          'Debe seleccionar vendedor',
          'Error en los datos ingresados'
      );
      return false;
    }
    for (let d of this.details) {
      if (!d.quantity || !d.details || !d.unitAmount) {
        this.messagesService.getInfoMessagePersonalized(
            'warning',
            'Debe llenar todos los campos',
            'Error en los datos ingresados'
        );
        return false;
      }
    }
    return true;
  }

  getTotalBilling() {
    let total = 0;
    for (let d of this.details) {
      total += this.getTotalValue(d);
    }
    return total;
  }

  handleSubmit() {
    swal({
      title: 'Está seguro que desea generar la factura?',
      text: "No puede revertir esta acción después!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Generar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this.registerBill();
      }
    });
  }

  openSearcher() {
    const modal = this.modalService.open(SearchComponent, {
      windowClass: 'smallModal', size: 'lg', backdrop: 'static'
    });
    modal.componentInstance.elementsList = this.sellersList;
    modal.componentInstance.title = 'Buscar Vendedor';
    modal.componentInstance.filterFunction = (list, query) => list.filter(el => el.completeName.toLowerCase().includes(query.toLowerCase()));
    modal.componentInstance.displayFunction = el => el.completeName;
    modal.componentInstance.emitter.subscribe(result => {
      this.seller = result.element;
      modal.componentInstance.emitter.unsubscribe();
    });
  }
}
