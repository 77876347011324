export class CountryModel {
    id: string;
    name: string;
    indicative: string;
    domain:  String;

    public constructor() {
        this.id = '';
        this.name = '';
        this.domain = '';
        this.indicative = '';
    }
}
