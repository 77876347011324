import { PdfService } from './../../shared/pdf/pdf.service';
import { BankAccountModel } from 'models/entities/bank-account-model';
import { PolicyModel } from 'models/entities/policy-model';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { CrudServiceService } from './../../shared/backend/cruds/crud-service.service';
import { CompanyModel } from './../../../models/entities/company-model';
import { ViewChild, ElementRef } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { InstallmentModel } from './../../../models/entities/installment-model';
import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { RemissionModel } from 'models/entities/remission-model';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { NumberToTextService } from 'app/shared/number-to-text/number-to-text.service';
import { Observable, Observer } from 'rxjs';
import { SharedService } from 'app/shared/shared.service';

import * as moment from "moment";
import * as jspdf from 'jspdf';
import 'jspdf-autotable'

@Component({
  selector: 'app-remission',
  templateUrl: './remission.component.html',
  styleUrls: ['./remission.component.scss']
})
export class RemissionComponent implements OnInit {
  @Input() installmentModel: InstallmentModel = new InstallmentModel();
  @Input() policyId: PolicyModel = new PolicyModel();
  @Input() remissionModel: RemissionModel = new RemissionModel();
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  companyModel: CompanyModel = new CompanyModel();
  bankAccountList: Array<BankAccountModel> = new Array();
  @Input() isPrinting = false;
  justRead = false;
  @ViewChild('contentToConvert') contentDiv: ElementRef;
  logo: any = '';

  constructor(
    private crudService: CrudServiceService,
    public tokenStorage: TokenStorageService,
    private messageService: InfoMessagesService,
    private pdfService: PdfService,
    private castService: NumberToTextService,
    private sharedService: SharedService
  ) {
    setTimeout(() => {
      this.imageLoad();
    }, 300);
  }

  ngOnInit() {
    this.remissionModel.policyId = { id: this.policyId.id };
    this.getCompany();
    this.getBankAccounts();
    if (this.remissionModel.expeditionDate) {
      this.changeDate();
    }
  }

  validateClassOfInsurance() {

  }

  closeModal() {
    this.passEntry.emit(0);
  }

  onSubmit() {
    this.remissionModel.policyId = { id: this.policyId.id };
    this.remissionModel.creatorId = { id: this.tokenStorage.getId() };
    this.remissionModel.createdDate = new Date();
    this.remissionModel.state = 'CURRENT'
    this.crudService.createModel('remision/crear-remision', this.remissionModel).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.remissionModel = genericResponse.genericObject;
          this.isPrinting = true;
        } else {
          this.messageService.getInfoMessagePersonalized('warning', 'No se pudo guardar el recaudo con exito',
            'Problema creando el recaudo');
        }
      }, (error) => {
        this.messageService.getInfoMessageError();
        console.error('Error, no se pudo guardar el recaudo con exito: ' + JSON.stringify(error))
      }
    )
  }

  changeDate() {
    const selectedExpeditionDate = this.remissionModel.expeditionDate.split('-');
    const momentaryDate = new Date(selectedExpeditionDate[0],
      selectedExpeditionDate[1], selectedExpeditionDate[2]);
    momentaryDate.setTime(momentaryDate.getTime() + (24 * 60 * 60 * 1000 * 30));
    this.remissionModel.dueDate = momentaryDate.getFullYear() + '-'
      + this.remissionModel.validateCero(momentaryDate.getMonth()) + '-'
      + this.remissionModel.validateCero(momentaryDate.getDate());
  }

  calculateTotal() {
    this.remissionModel.totalValue = (
      Number(this.remissionModel.premium) + Number(this.remissionModel.spendings) +
      ((Number(this.remissionModel.premium) + Number(this.remissionModel.spendings)) * (Number(this.remissionModel.iva) / 100))) + '';
  }

  getBankAccounts() {
    const pathCompanyUrl = 'empresa/cuenta-bancaria/listar-cuentas-bancarias';
    this.crudService.getModel(pathCompanyUrl).subscribe(
      (genericResponse: GenericResponseModel) => {
        this.bankAccountList = genericResponse.answerList;
        this.remissionModel.bankAccountId = genericResponse.answerList[0];
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar cuentas bancarias: ' + error)
      }
    );
  }

  getCompany() {
    const pathCompanyUrl = 'empresa/compania/ver-compania?id=' + this.tokenStorage.getCompanyId();
    this.crudService.getModel(pathCompanyUrl).subscribe(
      (genericResponse: GenericResponseModel) => {
        this.companyModel = genericResponse.genericObject;
      },
      error => {
        this.messageService.getInfoMessageError();
        console.error('Error al cargar la compañia: ' + error)
      }
    );
  }

  getLyricsValue(): any {
    return this.castService.NumeroALetras(this.remissionModel.totalValue);
  }

  captureScreen() {
    this.justRead = true;
    setTimeout(() => {
      const dataToConvert = this.contentDiv.nativeElement;
      this.pdfService.captureScreen(dataToConvert, 295, 'landscape').then(
        () => {
          this.passEntry.emit(1);
        }
      )
    }, 2000);
  }

  imageLoad() {
    this.getBase64ImageFromURL(this.sharedService.getLogoUrlCompany()).subscribe(base64data => {
      // this is the image as dataUrl
      this.logo = 'data:image/jpg;base64,' + base64data;
    });
  }

  getBase64ImageFromURL(url: string) {

    return Observable.create((observer: Observer<string>) => {
      // create an image object
      let img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      if (!img.complete) {
        // This will call another method that will create image from url
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = (err) => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }


  getBase64Image(img: HTMLImageElement) {
    // We create a HTML canvas object that will create a 2d image

    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    // This will draw image    
    ctx.drawImage(img, 0, 0);
    // Convert the drawn image to Data URL
    var dataURL = canvas.toDataURL("image/png");
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }


  removeAccents(str) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  createPdf() {
    const company = this.companyModel;
    const {
      sequence,
      expeditionDate,
      dueDate
    } = this.remissionModel;

    const doc = new jspdf('portrait', 'pt', 'letter');
    doc.setTextColor('black');
    doc.setFontSize(17);
    doc.setFontType('bold');

    const maxX = 592;
    const maxY = 772;
    let posY = 30;
    let posX = 20;

    // Crear header
    doc.setFontSize(14);
    doc.setFontType('bold');
    doc.text(posX, posY, company.name);
    doc.setFontSize(8);
    doc.setFontType('normal');
    doc.setTextColor('gray');
    const texts = [ company.documentNumber, company.address];
    if (company.phone) {
      texts.push(company.phone);
    }
    if (company.cellphone) {
      texts.push(company.cellphone);
    }
    texts.forEach((t, i) => {
      posY += 12;
      doc.text(posX, posY, t);
    });

    doc.addImage(this.logo, 'JPG', maxX - (posY - 30), 30, (posY - 30), (posY - 30));

    doc.setTextColor('black');
    posY += 20;
    doc.setFontSize(10);

    doc.setFillColor(210, 210, 210);
    doc.rect(posX, posY, maxX - posX, 30, 'FD');
    let lastRectY = posY + 30;

    doc.setFillColor('white');

    posY += 19;
    posX = 40;
    doc.setFontType('bold');
    doc.text('Nº REMISIÓN:', posX, posY);
    doc.setFontType('normal');
    posX += 70;
    doc.text(`${sequence}`, posX, posY);

    posX += 60;
    doc.setFontType('bold');
    doc.text('FECHA DE EXPEDICIÓN:', posX, posY);
    doc.setFontType('normal');
    posX += 130;
    doc.text(moment(expeditionDate).format('yyyy-MM-DD'), posX, posY);

    posX += 80;
    doc.setFontType('bold');
    doc.text('FECHA LÍMITE DE PAGO:', posX, posY);
    doc.setFontType('normal');
    posX += 135;
    doc.text(moment(dueDate).format('yyyy-MM-DD'), posX, posY);

    posY += 30;
    posX = 40;
    doc.setFontType('bold');
    doc.text('ASUNTO: ', posX, posY);
    posX += 55;
    doc.setFontType('normal');
    const subject = `PÓLIZA ${this.policyId.classOfInsuranceId.name} # ${this.policyId.policy}`;
    doc.text(subject.toUpperCase(), posX, posY);

    posY += 20;
    posX = 40;
    doc.setFontType('bold');
    doc.text('TOMADOR:', posX, posY);
    posX += 60;
    doc.setFontType('normal');
    doc.text(this.policyId.clientId.nameOrBusinessName.toUpperCase(), posX, posY);

    posY += 20;
    posX = 40;
    doc.setFontType('bold');
    doc.text('CIUDAD:', posX, posY);
    posX += 50;
    doc.setFontType('normal');
    doc.text(this.policyId.clientId.cityId.name.toUpperCase(), posX, posY);

    posY += 20;
    posX = 40;
    doc.setFontType('normal');
    doc.setFontSize(8);
    doc.text(
        `Se adjunta documento relacionado a ${this.remissionModel.documentClass} de la póliza ${this.policyId.policy}`.toUpperCase(),
        posX,
        posY
    );
    posX = 20;
    doc.rect(posX, lastRectY, maxX - posX, 85);
    lastRectY += 85;

    doc.setFontSize(10);
    posY += 20;
    posX = 40;
    doc.setFontType('bold');
    doc.text('Nº PÓLIZA:', posX, posY);
    posX += 60;
    doc.setFontType('normal');
    doc.text(this.policyId.policy, posX, posY);

    posX += ((this.policyId.policy.length * 5) + 20);
    doc.setFontType('bold');
    doc.text('COMPAÑÍA:', posX, posY);
    posX += 65;
    doc.setFontType('normal');
    doc.text(this.policyId.insurerId.name.toUpperCase(), posX, posY);

    posX += ((this.policyId.insurerId.name.length * 5) + 30);
    doc.setFontType('bold');
    doc.text('RAMO:', posX, posY);
    posX += 40;
    doc.setFontType('normal');
    doc.text(this.policyId.classOfInsuranceId.name.toUpperCase(), posX, posY);

    posX = 20;
    doc.rect(posX, lastRectY, maxX - posX, 20);
    lastRectY += 20;

    posY += 20;
    posX = 40;
    doc.setFontType('bold');
    doc.text('CONCEPTO:', posX, posY);
    posX += 65;
    doc.setFontType('normal');
    doc.text(this.remissionModel.concept.toUpperCase(), posX, posY);

    posY += 20;
    posX = 40;
    doc.setFontType('bold');
    doc.text('TIPO DE DOCUMENTO:', posX, posY);
    posX += 120;
    doc.setFontType('normal');
    doc.text(this.remissionModel.documentClass.toUpperCase(), posX, posY);

    posY += 20;
    posX = 40;
    doc.setFontType('bold');
    doc.text('VALOR IVA:', posX, posY);
    posX += 65;
    doc.setFontType('normal');
    doc.text(Number(this.remissionModel.iva).toLocaleString('es-ES'), posX, posY);

    posX += 120;
    doc.setFontType('bold');
    doc.text('VALOR TOTAL:', posX, posY);
    posX += 80;
    doc.setFontType('normal');
    doc.text(Number(this.remissionModel.totalValue).toLocaleString('es-ES'), posX, posY);

    posY += 20;
    posX = 40;
    doc.setFontType('bold');
    doc.text('VALOR EN LETRAS:', posX, posY);
    posX += 100;
    doc.setFontType('normal');
    doc.text(this.getLyricsValue().toUpperCase(), posX, posY);

    posX = 20;
    doc.rect(posX, lastRectY, maxX - posX, 80);
    lastRectY += 80;

    posY += 20;
    posX = 40;
    doc.setFontType('bold');
    doc.text('FORMA DE PAGO:', posX, posY);
    posX += 105;
    doc.setFontType('normal');
    doc.text(this.remissionModel.bankAccountId.bankAccount.toUpperCase(), posX, posY);

    posX = 20;
    doc.rect(posX, lastRectY, maxX - posX, 20);
    lastRectY += 20;

    posY += 35;
    posX = 20;
    doc.setFontType('bold');
    doc.text('GRACIAS POR LA ATENCIÓN, CORDIALMENTE:', posX, posY);

    doc.setFontType('normal');
    doc.text(this.tokenStorage.getCompleteName().toUpperCase(), (maxX - 20) - (this.tokenStorage.getCompleteName().length * 6), posY);

    // doc.line(0, maxY / 2, maxX, maxY / 2);
    const filename = `Remisión # ${sequence}.pdf`;
    doc.save(filename);
  }
}
