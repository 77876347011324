import { CompanyModel } from './company-model';

export class ProfileModel {
    id: string;
    name: string;
    description: string;
    createdDate: string;
    lasUpdateDate: string;
    companyId: CompanyModel;

    public constructor() {
        this.id = '';
        this.name = '';
        this.description = '';
        this.createdDate = '';
        this.lasUpdateDate = '';
        this.companyId = new CompanyModel();
    }
}
