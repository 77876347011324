export class TransportValuablesRiskModel {
    id: string;
    policyId: any;
    clientId: any;
    state: string;
    annualBudget: string;
    limitMaximumDispatch: string;
    vrInsured: string;
    validSince: string;
    validUntil: string;
    classOfInsuranceId: any;
    creatorId: any;
    lastUpdateId: any;
    createdDate: string;
    lastUpdateDate: string;

    public constructor() {
        this.id = null;
        this.policyId = '';
        this.clientId = '';
        this.state = '';
        this.annualBudget = '';
        this.limitMaximumDispatch = '';
        this.vrInsured = '';
        this.validSince = '';
        this.validUntil = '';
        this.classOfInsuranceId = '';
        this.creatorId = '';
        this.lastUpdateId = '';
        this.createdDate = '';
        this.lastUpdateDate = '';
    }
}
