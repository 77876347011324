import { ActivityModel } from './../../../models/entities/activity';
import { Component, OnInit, ViewChild, Output, Input, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { CrudServiceService } from 'app/shared/backend/cruds/crud-service.service';
import { RelationalFileModel } from 'models/utilities/relational-type-model';
import { TokenStorageService } from 'app/shared/storage-services/token-storage.service';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, merge } from 'rxjs/operators';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { SearchClientModel } from 'models/types/search-client-model';
import { NgbTypeahead, NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ManageClientsComponent } from 'app/clients/manage-clients/manage-clients.component';
import { formatDate } from '@angular/common';
import { ModalInfo } from 'models/entities/modal-info';
import { ModalInformationModel } from 'models/utilities/modal-information-model';
import { Router } from '@angular/router';
import { CompanyModel } from "../../../models/entities/company-model";
import { UserModel } from "../../../models/entities/user-model";

@Component({
  selector: 'app-create-activity',
  templateUrl: './create-activity.component.html',
  styleUrls: ['./create-activity.component.scss']
})
export class CreateActivityComponent implements OnInit {

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Output() @Input() public activityModel = new ActivityModel();
  @ViewChild('f') activityForm: NgForm;
  @Output() public modelInfoClient: ModalInformationModel = new ModalInformationModel();
  public listTypes: any[];
  // public listStates: any [];
  public listUsers: any[];
  public isCreateCLient = false;
  public save = false; clientAux
  public relationalModel: RelationalFileModel = new RelationalFileModel();
  listSeacrhClient: any;
  listClients: any;
  temp: any;
  @ViewChild('instanceClient') instanceClient: NgbTypeahead;
  modelSearchClient: SearchClientModel = new SearchClientModel();
  focusClient$ = new Subject<string>();
  clickClient$ = new Subject<string>();
  focusClientIn$ = new Subject<string>();
  modalInforClientModel = new ModalInfo();
  flagClient = false;
  isModal = false;
  isEdit = false;
  focusUser$ = new Subject<string>();
  clickUser$ = new Subject<string>();
  focusUserIn$ = new Subject<string>();
  @ViewChild('instanceUser') instanceUser: NgbTypeahead;
  company: CompanyModel = new CompanyModel();
  saving = false;

  @Input() isSupport;
  @Input() disableTypes;
  supportConfig: any;

  constructor(
    private messageCtrl: InfoMessagesService,
    private serviceCtrl: CrudServiceService,
    private tokenServiceCtrl: TokenStorageService,
    private modalService: NgbModal,
    private router: Router,

  ) { }

  ngOnInit() {
    this.fetchCompany();
    this.getTypeActivity();
    this.getUsers();
    this.getClients();
    this.modelInfoClient.isUpdate = true;
    if (this.isSupport) {
      this.fetchSupportConfig();
    }
  }

  end() {
    if (this.isModal) {
      this.passEntry.emit('close');
    } else {
      this.router.navigate(['actividades/gestion-actividades']);
    }
    this.messageCtrl.getInfoMessagePersonalized(
        'success',
        'Por favor, guarde este número para posteriores consultas',
        'Se ha creado una nueva actividad con el número ' + this.activityModel.sequence
    );
  }

  changeClient(e) {
    if (this.isCreateCLient) {
      this.isCreateCLient = false;
    } else {
      this.isCreateCLient = true;
    }
  }

  getUsers() {
    const path = 'usuarios/usuarios-por-empresa?company=' + this.tokenServiceCtrl.getCompanyId();
    this.serviceCtrl.getModel(path).subscribe(
      result => {
        if (result.code === 200) {
          let allowedRoles = [];
          switch (this.tokenServiceCtrl.getAuthorities()) {
            case 'ROLE_ADMIN':
              allowedRoles = ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_SELLER'];
              break;
            case 'ROLE_USER':
              allowedRoles = ['ROLE_ADMIN', 'ROLE_SELLER'];
              break;
            case 'ROLE_SELLER':
              allowedRoles = ['ROLE_ADMIN'];
          }
          if (this.company.assignManagerActivities) {
            allowedRoles.push('ROLE_USER');
          }
          this.listUsers = [];
          for (let i = 0; i < result.answerList.length; i++) {
            const user = result.answerList[i];
            if (allowedRoles.includes(user.role) || `${user.id}` === `${this.tokenServiceCtrl.getId()}`) {
              this.listUsers.push(user);
            }
          }
        }
      },
      error => {
        this.messageCtrl.getInfoMessageError();
      }
    )
  }

  onSubmit() {
    if (!this.activityModel.responsibleUserId.id) {
      this.activityModel.responsibleUserId.id = this.tokenServiceCtrl.getId();
    }
    if (!this.activityModel.relationalEntityId) {
      this.activityModel.relationalEntityId = this.activityModel.responsibleUserId.id;
      this.activityModel.relationalEntityType = 'SIN ASIGNAR';
    }
    if (!this.activityModel.relationalEntityType) {
      this.activityModel.relationalEntityId = this.activityModel.relationalEntityId.id;
      this.activityModel.relationalEntityType = 'CLIENTE';
    }
    if (this.activityModel.expireDate === null) {
      this.activityModel.expireDate = new Date();
    }
    this.activityModel.creatorId.id = this.tokenServiceCtrl.getId();
    this.activityModel.lastUpdateId.id = this.tokenServiceCtrl.getId();
    this.activityModel.companyId.id = this.tokenServiceCtrl.getCompanyId();

    if (this.isSupport) {
      this.activityModel.isPrivate = true;
      this.activityModel.responsibleUserId = this.supportConfig.userResponse;
      this.activityModel.companyId.id = 1;
    }

    this.saving = true;
    const path = 'actividad/crear';
    this.serviceCtrl.createModel(path, this.activityModel).subscribe(
      result => {
        if (result.code === 200) {
          this.relationalModel.relationalId = result.genericObject.id;
          this.relationalModel.relationalType = 'ACTIVITY'
          this.relationalModel.relationalFileType = 'ARCHIVO';
          this.relationalModel.relationalObjetive = "adjunto actividad"
          this.relationalModel.relationalRestrictions = new Array();
          this.save = true;
          this.isCreateCLient = false;
          this.messageCtrl.getInfoMessageCreate();
          this.passEntry.emit('ok');
          this.activityModel = result.genericObject;
        } else {
          this.messageCtrl.getInfoMessageError();
        }
        this.saving = false;
      },
      error => {
        this.messageCtrl.getInfoMessageError();
        this.saving = false;
      }
    );
  }

  close() {
    this.passEntry.emit('close');
  }

  getTypeActivity() {
    const path = 'empresa/tipo-actividad/listar-tipo-actividad';
    this.serviceCtrl.getModel(path).subscribe(
      result => {
        console.log(result);
        if (result.code === 200) {
          this.listTypes = result.answerList;
        }
      },
      error => {
        this.messageCtrl.getInfoMessageDelete();
      }
    )
  }

  validateSelectType(type) {
    if (type !== 'none') {
      return true;
    } else {
      return false;
    }
  }

  getClients() {
    const pathListClientsUrl = 'cliente/ver-por-empresa';
    this.listClients = new Array();
    this.serviceCtrl.getModel(pathListClientsUrl).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          const listClientsAux = genericResponse.answerList;
          listClientsAux.forEach(client => {
            this.listClients.push(client);
          });
        } else {
          this.messageCtrl.getInfoMessagePersonalized('warning', 'No se pudieron listar los clientes asociados a su empresa',
            'Problema consultando los clientes asociados a su empresa');
        }
      },
      error => {
        this.messageCtrl.getInfoMessageError();
        console.error('Error al cargar los clientes asociados a su empresa ' + JSON.stringify(error))
      }
    );
  }

  formatterClient = (object: { nameOrBusinessName: string }) => object.nameOrBusinessName;



  searchByNameClient = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focusClientIn$),
      merge(this.clickClient$.pipe(filter(() => !this.instanceClient.isPopupOpen()))),
      map(search => (search === '' ? this.listClients
        : this.listClients.filter(client => client.nameOrBusinessName.toLowerCase().indexOf(search.toLowerCase()) > -1)).slice(0, 10))
    );

  formatterUser = (object: { completeName: string }) => object.completeName

  searchByNameUser = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      merge(this.focusUserIn$),
      merge(this.clickUser$.pipe(filter(() => !this.instanceUser.isPopupOpen()))),
      map(search => (search === '' ? this.listUsers
        : this.listUsers.filter(user => user.completeName.toLowerCase().indexOf(search.toLowerCase()) > -1)).slice(0, 10))
    );


  lookClient() {
    const modal = this.modalService.open(ManageClientsComponent, {
      windowClass: '',
      size: 'lg',
      backdrop: 'static'
    });

    const array = this.activityModel.relationalEntityId.birthDate.split('+');
    const date = formatDate(new Date(array[0]), 'yyyy-MM-dd', 'en');
    this.activityModel.relationalEntityId.birthDate = date;

    this.modalInforClientModel.isCreate = false;
    this.modalInforClientModel.isUpdate = false;
    this.modalInforClientModel.isView = true;
    modal.componentInstance.modelInfoClient = this.modalInforClientModel;
    modal.componentInstance.clientModel = { ...this.activityModel.relationalEntityId };
    /*modal.componentInstance.passEntry.subscribe(update => {
      if (update === 'ok') {
      }
    });*/
  }

  clientSelect() {
    if (!this.flagClient) {
      this.flagClient = true;
    } else {
      this.flagClient = false;
    }
  }

  createClient() {
    this.flagClient = false;
    const modal = this.modalService.open(ManageClientsComponent, {
      windowClass: '',
      size: 'lg',
      backdrop: 'static'
    });
    this.modalInforClientModel.isCreate = true;
    this.modalInforClientModel.isUpdate = false;
    this.modalInforClientModel.isView = false;
    modal.componentInstance.modelInfoClient = this.modalInforClientModel;
    modal.componentInstance.passEntry.subscribe(retorno => {
      if (retorno === 'ok') {
        this.getClients();
      } else if (retorno === 'close') {
        modal.dismiss();
      }
    });
  }

  fetchCompany() {
    const url = 'empresa/compania/ver-compania?id=' + this.tokenServiceCtrl.getCompanyId();
    this.serviceCtrl.getModel(url).toPromise().then((response: GenericResponseModel) => {
      if (response.code === 200) {
        this.company = response.genericObject;
      } else {
        this.company.assignManagerActivities = false;
      }
    })
  }

  fetchSupportConfig() {
    if (!this.isSupport || this.tokenServiceCtrl.getAuthorities() !== 'ROLE_ADMIN') {
      return;
    }
    const url = 'empresa/tipo-actividad/obtener-tipo-actividad-soporte';
    this.serviceCtrl.getModel(url).toPromise().then((response: GenericResponseModel) => {
      console.log(response);
      if (response.code === 200) {
        this.supportConfig = response.genericObject;
        this.activityModel.activityTypeId = this.supportConfig.type;
      }
    })
      .catch(error => {
        console.log(error);
        this.messageCtrl.getInfoMessagePersonalized(
          'error',
          '',
          'No se pudo cargar la información de soporte'
        );
      })
  }

  handleTypeChange() {
    const typeId = this.activityModel.activityTypeId.id;
    const typeName = this.getTypeById(typeId);
    if (typeName === 'Soporte Seguros App') {
      this.isSupport = true;
      if (!this.supportConfig) {
        this.fetchSupportConfig();
      }
    } else {
      this.isSupport = false;
    }
  }

  getTypeById(id) {
    for (let i = 0; i < this.listTypes.length; i++) {
      if (`${this.listTypes[i].id}` === `${id}`) {
        return this.listTypes[i].name;
      }
    }
    return null;
  }
}