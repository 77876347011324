import { InfoMessagesService } from 'app/shared/messages/info-messages.service';
import { ModalInfo } from 'models/entities/modal-info';
import { ManageClientsComponent } from 'app/clients/manage-clients/manage-clients.component';
import { AdminPrincipalRuPoliciesComponent } from 'app/policies/admin-principal-ru-policies/admin-principal-ru-policies.component';
import { GenericResponseModel } from 'models/utilities/generic.response.model';
import { CrudServiceService } from './../backend/cruds/crud-service.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, AfterViewChecked, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TokenStorageService } from '../storage-services/token-storage.service';
import { Router } from '@angular/router';
import { ChangePasswordComponent } from 'app/usercomponents/change-password/change-password.component';
import { NotificationModel } from 'models/entities/notification-model';
import {CreateActivityComponent} from "../../activities/create-activity/create-activity.component";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements AfterViewChecked, OnInit {

  currentLang = 'en';
  toggleClass = 'ft-maximize';
  placement = 'bottom-right'
  public isCollapsed = true;
  public activo = false;
  public nombreUsuario = '';
  public correoUsuario: String;
  public notifications: Array<NotificationModel> = new Array();

  isSeller = false;

  constructor(public translate: TranslateService,
    private crudServicesCtrl: CrudServiceService,
    private messageService: InfoMessagesService,
    private router: Router,
    public token: TokenStorageService,
    private modalService: NgbModal
  ) {
    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|es|pt|de/) ? browserLang : 'en');
  }

  ngOnInit() {
    if (this.token.getAuthorities() === 'ROLE_SELLER') {
      this.isSeller = true;
    }
    this.islogin();
    this.correoUsuario = this.token.getUsername();
    this.nombreUsuario = this.token.getCompleteName();
  }

  readAll() {
    this.crudServicesCtrl.putModelParams('notificacion/notificar-apertura-todos', {}, {}).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          this.notifications = new Array();
        }
      }, (error) => {
        console.error(error);
      }
    )
  }

  async readNotification(notification: NotificationModel) {
    this.crudServicesCtrl.putModelParams('notificacion/notificar-apertura', {}, {
      notificationId: notification.id
    }).subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {
          const index = this.notifications.indexOf(notification, 0);
          if (index > -1) {
            this.notifications.splice(index, 1);
          }
          this.goTo(notification.path);
        }
      }, (error) => {
        console.error(error);
      }
    )
  }

  goTo(path) {
    this.router.navigateByUrl(path);
  }

  createPolicy(){
    this.router.navigateByUrl('polizas/create-poliza');
  }

  createClient(){
    const modal = this.modalService.open(ManageClientsComponent, {
      windowClass: '', size: 'lg', backdrop: 'static'
    });
    const modalInformationToSend: ModalInfo = new ModalInfo();
    modalInformationToSend.isView = false;
    modalInformationToSend.isUpdate = false;
    modalInformationToSend.isCreate = true;
    modal.componentInstance.modelInfoClient = modalInformationToSend;
    modal.componentInstance.passEntry.subscribe(retorno => {
      if (retorno === 'ok') {
        this.messageService.getInfoMessageCreate();
      } else if (retorno === 'close') {
        modal.dismiss();
      
      }
    });
  }

  consultNotifications() {
    this.crudServicesCtrl.getModel('notificacion/obtener-notificaciones').subscribe(
      (genericResponse: GenericResponseModel) => {
        if (genericResponse.code === 200) {;
          console.log('Se obtuvieron notificaciones')
          this.notifications = genericResponse.answerList;
        }
      }, (error) => {
        console.error(error);
      }
    )
    // setTimeout(() => {
    //   this.consultNotifications();
    // }, 10000);
  }

  ngAfterViewChecked() {
    //setTimeout(() => {
      //  var wrapperDiv = document.getElementsByClassName("wrapper")[0];
        //var dir = wrapperDiv.getAttribute("dir");
        //if (dir === 'rtl') {
          //  this.placement = 'bottom-left';
        //}
        //else if (dir === 'ltr') {
          //  this.placement = 'bottom-right';
        //}
    //}, 3000);
  }

  logOut() {
    this.token.signOut();
    this.router.navigate(['pages/auth']);
  }

  changePassword() {
    const modalRef = this.modalService.open(ChangePasswordComponent, {
      windowClass: 'modal', size: 'lg'
    });
  }

  redirectToLogin() {
    this.router.navigate(['pages/auth']);
  }


  ChangeLanguage(language: string) {
    this.translate.use(language);
  }

  ToggleClass() {
    if (this.toggleClass === 'ft-maximize') {
      this.toggleClass = 'ft-minimize';
    } else {
      this.toggleClass = 'ft-maximize'
    }
  }

  islogin() {
    if (this.token.getToken()) {
      this.activo = false;
      if (this.token.getAuthorities() === 'ROLE_SUPER') {
        this.consultNotifications();
      }
    } else {
      this.activo = true;
    }
  }

  closeWindow() {
    this.modalService.dismissAll();
  }

  openTicketModal() {
    if (this.token.getAuthorities() !== 'ROLE_ADMIN') {
      return;
    }
    const modal = this.modalService.open(CreateActivityComponent,  {
      windowClass: 'modal', size: 'lg', backdrop: 'static'
    });
    modal.componentInstance.isSupport = true;
    modal.componentInstance.disableTypes = true;
    modal.componentInstance.passEntry.subscribe(result => {
      modal.dismiss();
    });
  }
}
