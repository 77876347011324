import { PlansModel } from './../../../models/entities/plans-model';
import { FormBuilder } from '@angular/forms';
import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { NgForm, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-update-plan',
  templateUrl: './update-plan.component.html',
  styleUrls: ['./update-plan.component.scss']
})
export class UpdatePlanComponent implements OnInit {
  form: any = {};
  plan: FormGroup;
  @Input() public planInfoUpdate = new PlansModel();
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @ViewChild('f') updatePlanForm: NgForm;

  constructor(private modalService: NgbModal, private planForm: FormBuilder) { }

  ngOnInit() {
    this.plan = this.planForm.group({
      id: this.planInfoUpdate.id,
      name: this.planInfoUpdate.name,
      description: this.planInfoUpdate.description,
      value: this.planInfoUpdate.value
    });
  }

  passBack() {
    const formValue = this.plan.value;
    this.passEntry.emit(formValue);
  }

  closeWindow() {
    this.modalService.dismissAll();
  }


}
